import React from "react";

import {
    Row,
    Col,
    Button,
    FormGroup,
    Input,
} from "reactstrap";
// core components

export default function MPEditLocation(props) {
    return (
        <>
            <div className="card">
                <div className="card-header text-left title-2">
                    Edit Location
                 </div>
                <div className="card-body">
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Input type="text" className="form-control" placeholder="Location Name" />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Input type="text" className="form-control" placeholder="Address 1*" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Input type="text" className="form-control" placeholder="Address 2" />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Input type="text" className="form-control" placeholder="Address 3" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Input type="text" className="form-control" placeholder="Unit Number" />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Input type="text" className="form-control" placeholder="Postal Code" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <select className="form-control"
                                    style={{
                                        textAlignLast: "center"
                                    }}>
                                    <option value="1">Apartment</option>
                                    <option value="2">Landed</option>
                                </select>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Input type="text" className="form-control" placeholder="Property Size" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Input type="text" className="form-control" placeholder="Number of bedrooms" />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Input type="text" className="form-control" placeholder="Number of bathrooms" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <select className="form-control"
                                    style={{
                                        textAlignLast: "center"
                                    }}>
                                    <option value="1">Bhajanlal</option>
                                    <option value="2">Miss Puja</option>
                                </select>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Input type="text" className="form-control" placeholder="Special Instruction" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Input type="text" className="form-control" placeholder="Default" />
                            </FormGroup>
                        </Col>

                    </Row>
                </div>
                <div className="card-footer">
                    <Row>
                        <Col md="12" className="text-cenetr">
                            <FormGroup>
                                <Button className="btn btn-round btn-info"
                                    onClick={e => {
                                        e.preventDefault();
                                        props.Function.setActive("3");
                                    }}
                                >UPDATE LOCATION</Button>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}
