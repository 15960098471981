export const storeData = (key, value) => {
    if (typeof (Storage) !== "undefined")
        localStorage.setItem(key, JSON.stringify(value))
}

export const getData = (key) => {
    if (typeof (Storage) !== "undefined")
        return JSON.parse(localStorage.getItem(key) || null)
    else
        return null
}


export const removeData = (key) => {
    if (typeof (Storage) !== "undefined")
        return localStorage.removeItem(key)
}