import React, { useEffect, useState, useContext } from "react";

// reactstrap components
import {
  Container, Row, Col, NavItem, NavLink, Modal, ModalBody,

} from "reactstrap";
// import { Link } from "react-router-dom";

import { GetAPI } from "../../API/APICalling";
import { GlobalContext } from '../../App.js';
import { Link } from 'react-router-dom';

function OurServices() {
  const [modal, setModal] = React.useState(false);
  const globalContext = useContext(GlobalContext);
  const [data, setData] = useState([]);
  const [popUpData, setPopUpData] = React.useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    const FnGetService = async () => {
      globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
      var result = await GetAPI(`home/ourservices`);
      if (result) {
        globalContext.loaderDispatch({ type: 'STOP_LOADING' });
        if (result.error) {
          globalContext.notifications('error', result.error);
        }
        else if (result.data) {
          setData(result.data)
        }
      }
    }
    FnGetService();
  }, []);



  return (
    <>
      <div className="adjust-navbar-breadcrumb">
        <div className="breadcrumb-outer">
          <Container className="breadcrumb-body">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">
                  Home
                </a>
              </li>
              <li className="breadcrumb-item active"> <i className="fa fa-angle-right pr-5" aria-hidden="true"></i>Service</li>
            </ol>
          </Container>
        </div>
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-left" md="9">
              <div>
                <h2 className="text-center big-heading">
                  Let Us Do The Work For You
                </h2>
              </div>
              <hr className="hr-large"></hr>
              <div>
                <p className="description text-center pb-30">
                  Choose us becouse of our reputation for exellence. We can save you time, so you can focus on
                  what matters to you and enjoy the most important thing in life.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto text-left" md="11">
              <Row>
                {data &&
                  data.length !== 0 &&
                  data.map((item, index) => (
                    <Col md="4" key={index}>
                      <div className="info-our info-our-hover">
                        <div className="icon icon-success icon-circle">
                          <img
                            alt={item.svcname}
                            src={item.imageurl}
                            className="ourservices-img"
                          ></img>
                        </div>
                        <div className="details">
                          <h4 className="title-2">{item.svcname}</h4>
                          <p className="description">
                            {item.svcdesc}<br />
                            <span className="btn-link btn btn-info p-0">
                              <b
                                onClick={e => {
                                  e.preventDefault();
                                  setModal(true);
                                  setPopUpData(item.subservices);
                                }}
                              >READ MORE</b>
                            </span>
                          </p>
                        </div>
                      </div>
                    </Col>
                  ))
                }

                {/* <Col md="4">
                  <div className="text-center">
                    <div className="icon">
                      <img
                        alt="2"
                        src={require("assets/img/service-img-3.jpg")}
                        className="ourservice-img"
                      ></img>
                    </div>
                    <h4 className="title-2">facilities</h4>
                    <p className="description">
                      In fact, many people say that moving is one of the most streesful and exhausting life experience.<br />
                      <span className="btn-link btn btn-info p-0">
                        <b
                          onClick={e => {
                            e.preventDefault();
                            setModal(true);
                          }}
                        >READ MORE</b>
                      </span>
                    </p>
                  </div>
                </Col>
                <Col md="4">
                  <div className="text-center">
                    <div className="icon">
                      <img
                        alt="3"
                        src={require("assets/img/service-img-4.jpg")}
                        className="ourservice-img"
                      ></img>
                    </div>
                    <h4 className="title-2">Cleaning</h4>
                    <p className="description">
                      Cleaning Company offers a program designed to service residents of apartments and condominiums.<br />
                      <span className="btn-link btn btn-info p-0">
                        <b
                          onClick={e => {
                            e.preventDefault();
                            setModal(true);
                          }}
                        >READ MORE</b>
                      </span>
                    </p>
                  </div>
                </Col> */}
              </Row>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-left" md="11">
              <div className="ml-auto mr-auto text-center schedule-cleaning">
                <div className="text-center">
                  <div>Schedule a Cleaning Service Today</div>
                  <div
                    style={{
                      fontWeight: "600"
                    }}
                  >
                    <i className="phone-icon text-white"></i>
                    +44 20 8168 9818
                    </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        {/* <Container fluid className="mt-30 mb-30"
          style={{
            backgroundImage: "url(" + require("assets/img/bg-gray-additional.jpg") + ")",
            backgroundRepeat: "round"
          }}
        >
          <Row>
            <Col className="ml-auto mr-auto text-left" md="9">
              <div className="big-heading text-center">
                Addtional Services
              </div>
              <hr className="hr-large"></hr>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto text-left" md="8">
              <Row>
                <Col xl="3" lg="6" md="6" sm="6" xs="6" className="p-0">
                  <div className="links">
                    <ul className="stacked-links">
                      <NavItem>
                        <NavLink
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                          }}
                        >
                          <i className="fa fa-check pr-15 text-info"></i>
                          TV/Accessories
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                          }}
                        >
                          <i className="fa fa-check pr-15 text-info"></i>
                                                                Shelves
                                                    </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                          }}
                        >
                          <i className="fa fa-check pr-15 text-info"></i>
                                                         Picture Frames
                                                     </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                          }}
                        >
                          <i className="fa fa-check pr-15 text-info"></i>
                                                        Tbales
                                                     </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                          }}
                        >
                          <i className="fa fa-check pr-15 text-info"></i>
                                                       Chairs
                                                     </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                          }}
                        >
                          <i className="fa fa-check pr-15 text-info"></i>
                                                       lamp Lighting
                          </NavLink>
                      </NavItem>
                    </ul>
                  </div>
                </Col>
                <Col xl="3" lg="6" md="6" sm="6" xs="6" className="p-0">
                  <div className="links">
                    <ul className="stacked-links">
                      <NavItem>
                        <NavLink
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                          }}
                        >
                          <i className="fa fa-check pr-15 text-info"></i>
                          My Praticulars
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                          }}
                        >
                          <i className="fa fa-check pr-15 text-info"></i>
                                                                 My Contacts
                                                    </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                          }}
                        >
                          <i className="fa fa-check pr-15 text-info"></i>
                                                          My Locations
                                                     </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                          }}
                        >
                          <i className="fa fa-check pr-15 text-info"></i>
                                                        My Credit Card
                                                     </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                          }}
                        >
                          <i className="fa fa-check pr-15 text-info"></i>
                                                         Update Password
                                                     </NavLink>
                      </NavItem>
                    </ul>
                  </div>
                </Col>
                <Col xl="3" lg="6" md="6" sm="6" xs="6" className="p-0">
                  <div className="links">
                    <ul className="stacked-links">
                      <NavItem>
                        <NavLink
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                          }}
                        >
                          <i className="fa fa-check pr-15 text-info"></i>
                          My Praticulars
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                          }}
                        >
                          <i className="fa fa-check pr-15 text-info"></i>
                                                                 My Contacts
                                                    </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                          }}
                        >
                          <i className="fa fa-check pr-15 text-info"></i>
                                                          My Locations
                                                     </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                          }}
                        >
                          <i className="fa fa-check pr-15 text-info"></i>
                                                        My Credit Card
                                                     </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                          }}
                        >
                          <i className="fa fa-check pr-15 text-info"></i>
                                                         Update Password
                                                     </NavLink>
                      </NavItem>
                    </ul>
                  </div>
                </Col>
                <Col xl="3" lg="6" md="6" sm="6" xs="6" className="p-0">
                  <div className="links">
                    <ul className="stacked-links">
                      <NavItem>
                        <NavLink
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                          }}
                        >
                          <i className="fa fa-check pr-15 text-info"></i>
                          My Praticulars
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                          }}
                        >
                          <i className="fa fa-check pr-15 text-info"></i>
                                                                 My Contacts
                                                    </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                          }}
                        >
                          <i className="fa fa-check pr-15 text-info"></i>
                                                          My Locations
                                                     </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                          }}
                        >
                          <i className="fa fa-check pr-15 text-info"></i>
                                                        My Credit Card
                                                     </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                          }}
                        >
                          <i className="fa fa-check pr-15 text-info"></i>
                                                         Update Password
                         </NavLink>
                      </NavItem>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      */}
      </div>
      {modal ? (
        <Modal size="lg" isOpen={modal}
          toggle={() => setModal(!modal)}
          centered={true}
        >
          <div className="menu-items-modal">
            <ModalBody className="text-center">
              <div className="service-menu-items">
                {popUpData &&
                  <React.Fragment>
                    {
                      popUpData.length !== 0 &&
                      <React.Fragment>
                        {
                          popUpData.map((item, index) => (
                            // <Link key={index}
                            //   className="text-decoration-none"
                            //   to={item.prodpageid ? `/${item.prodpageid}` : `#`}
                            // >

                            <Link
                              key={index}
                              className="text-decoration-none"
                              to={{
                                pathname: item.prodpageid ? `/${item.prodpageid}` : `#`,
                                state: { 'title': item.subsvcname }
                              }}>
                              <Col xl="6" lg="6" md="12" sm="12" xs="12" className="service-items">
                                <div className="item-img">
                                  <img
                                    alt={item.subsvcname}
                                    src={item.imageurl}
                                  ></img>
                                </div>
                                <div className="item-desc">
                                  <div className="text-left">
                                    <div>
                                      <div className="title-3">{item.subsvcname}</div>
                                      <div>
                                        {item.subsvcdesc}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Link>
                          ))
                        }
                      </React.Fragment>
                    }
                  </React.Fragment>
                }

                {/* <Col xl="6" lg="6" md="12" sm="12" xs="12" className="service-items">
                  <div className="item-img">
                    <a href="/our-services/home-cleaning">
                      <img
                        alt="..."
                        src={require("assets/img/service-circle-sample1.png")}
                      ></img>
                    </a>
                  </div>
                  <div className="item-desc">
                    <div className="text-left">
                      <div>
                        <div className="title-3">Deep Cleaning</div>
                        <div>
                          Sertain occasions demand that you seek professional help for cleaning your house.
                             </div>
                      </div>
                    </div>
                  </div>
                </Col> */}
              </div>
            </ModalBody>
          </div>
        </Modal>
      ) : null
      }
    </>
  );
}

export default OurServices;
