import React, { useEffect, useState, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { DEFAULTMETA } from './constant';
import { GlobalContext } from './App.js';
// const data = [
//     {
//         url: "/about-us",
//         title: "About us",
//         description: "Whissh is your trusted home service and cleaning service provider.",
//         keywords: "Cleaning services, cleaning"
//     },
//     {
//         url: "/rate",
//         title: "Our Rate",
//         description: "Whissh is your trusted home service and cleaning service provider.",
//         keywords: "Cleaning services, cleaning"
//     },
//     {
//         url: "/how-it-works",
//         title: "how-it-works",
//         description: "Whissh is your trusted home service and cleaning service provider.",
//         keywords: "Cleaning services, cleaning"
//     },
//     {
//         url: "/package-listing",
//         title: "/package listing",
//         description: "Whissh is your trusted home service and cleaning service provider.",
//         keywords: "Cleaning services, cleaning"
//     },
//     {
//         url: "/my-account/my-booking",
//         title: "Booking List",
//         description: "Whissh is your trusted home service and cleaning service provider.",
//         keywords: "Cleaning services, cleaning"
//     },
//     {
//         url: "/my-booking-details/active",
//         title: "Active service Booking details",
//         description: "Whissh is your trusted home service and cleaning service provider.",
//         keywords: "Cleaning services, cleaning"
//     }
// ]
export default React.memo(function Metadata() {
    const globalContext = useContext(GlobalContext);
    const history = useHistory();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [keywords, setKeywords] = useState('');

    useEffect(() => {
        if (globalContext.commonState.metadata.length > 0) {
            console.log(history.location.pathname);
            var curpath = history.location.pathname;
            if (curpath == "/")
            {
                curpath = "/home";
            }
            console.log(curpath);
            var result = globalContext.commonState.metadata.filter(item =>
                curpath.includes(item.url) && item
            );
            if (result && result.length > 0) {
                setTitle(result[0].title);
                setDescription(result[0].description);
                setKeywords(result[0].keywords);
            }
            else {
                setTitle(DEFAULTMETA.title);
                setDescription(DEFAULTMETA.description);
                setKeywords(DEFAULTMETA.keywords);
            }
        }

    }, [history.location.pathname, globalContext.commonState.metadata]);

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="keywords" content={keywords} />
                <meta name="description" content={description} />
            </Helmet>
        </>
    )
});