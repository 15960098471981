import React, { useState, useEffect, useContext } from "react";
import { FormGroup, Input, Row, Col, InputGroupAddon, InputGroupText, InputGroup } from "reactstrap";
import { Link, useHistory } from "react-router-dom"
import { GlobalContext } from '../../App';
import { ServiceBookingContext } from '../../views/ServiceBooking';
// import { FBInitialState, FBReducer } from "reducer/bookingReducer";
import { GetAPI, PostAPI } from "../../API/APICalling";
import { getData } from "../../utils/localStorage";
import { FnCreateProductList } from "../../utils/ObjectListFormat";
import ModalPackageAddOns from "./ModalPackageAddOns";

function FinalizeBooking(props) {
    const currency = '£';
    const globalContext = useContext(GlobalContext);
    const sbContext = useContext(ServiceBookingContext);
    const [show, setshow] = useState("1");
    //const [state, dispatch] = useReducer(FBReducer, FBInitialState);
    const history = useHistory();
    let arnum, email;
    // const changeName = () => {
    //     let text = ""
    //     text = show === true ? "fa-minus-circle" : "fa-plus-circle";
    //     return text;
    // }
    // const showHide = () => {
    //     setshow(!show)
    // }
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        FncheckRefNum();
    }, [])

    const FncheckRefNum = async () => {
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }
        else {
            // if (!globalContext.commonState.cartrefnum) {
            globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
            var result = await GetAPI(`booking/cart/summary/${session.arnum}?email=${session.email}`);
            globalContext.loaderDispatch({ type: 'STOP_LOADING' });
            if (result) {
                if (result.error) {
                    globalContext.notifications('error', result.error);
                }
                else if (result.data) {
                    //globalContext.authDispatch({ type: 'SET_CART', payload: result.data });
                    if (result.data.refnum !== '') {
                        FnGetCartList(result.data.refnum);
                        globalContext.commonDispatch({ type: 'SET_CART', payload: result.data });
                    }
                }
            }
            // }
            // else {
            //     FnGetCartList(globalContext.commonState.cartrefnum);
            // }
        }
    }

    const FnGetCartList = async (refnum) => {
        globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
        var result = await GetAPI(`/booking/cart/details/${refnum}`);
        if (result) {
            globalContext.loaderDispatch({ type: 'STOP_LOADING' });
            if (result.error) {
                sbContext.BookingDispatch({ type: 'ERROR' });
                globalContext.notifications('error', result.error);
            }
            else if (result.data) {
                // console.log(result.data)

                sbContext.BookingDispatch({ type: 'CART_SUCCESS', payload: result.data });
                //sbContext.BookingDispatch({ type: 'FINAL_AMOUNT' });
            }
        }
    };

    const FnRemoveProduct = async (product) => {
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again');
            history.push("/");
        }
        else {
            arnum = session.arnum;
            email = session.email;
        }
        globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
        var result = await PostAPI(`booking/cart/${arnum}?email=${email}`, FnCreateProductList([product], 'remove'));
        if (result) {
            globalContext.loaderDispatch({ type: 'STOP_LOADING' });
            if (result.error) {
                globalContext.notifications('error', result.error);
            }
            else {
                FnGetCartList(globalContext.commonState.cartrefnum);
                // sbContext.BookingDispatch({ type: 'REMOVE_PRODUCT', guid: product.guid });
            }
        }
    };
    // const FnApplyPromoCode = async (guid, matnum, promocode, qty, refnum) => {
    const FnApplyPromoCode = async (promocode) => {
        const session = getData('session');
        // if (!session || !guid || !matnum || !promocode || qty < 1 || !refnum) {
        //     return false;
        // }

        if (!session || !globalContext.commonState.cartrefnum || sbContext.BookingState.subtotal <= 0) {
            return false;
        }
        else {
            arnum = session.arnum;
        }
        globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
        var result = await PostAPI(`booking/promo/${arnum}`,
            {
                arnum: session.arnum,
                // matnum: matnum,
                // guid: guid,
                // qty: parseInt(qty || 1),
                refnum: globalContext.commonState.cartrefnum,
                promoCode: promocode,
                trandate: new Date()
            }
        );
        if (result) {
            globalContext.loaderDispatch({ type: 'STOP_LOADING' });
            if (result.error) {
                globalContext.notifications('error', result.error);
            }
            else if (result.data) {
                FnGetCartList(globalContext.commonState.cartrefnum);
                //sbContext.BookingDispatch({ type: 'UPDATE_COUPON_AMOUNT', disctype: result.data.disctype, discamt: result.data.discamt, guid: guid });
            }
        }
    };

    const FnOnSubmitFinalize = async () => {
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again');
            history.push("/");
        }
        else {
            arnum = session.arnum;
            email = session.email;
        }
        globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
        var result = await PostAPI(`booking/cart/${arnum}?email=${email}&promocode=${sbContext.BookingState.promoCode || ''}`, FnCreateProductList(sbContext.BookingState.cartList), 'addupdate');
        if (result) {
            globalContext.loaderDispatch({ type: 'STOP_LOADING' });
            if (result.error) {
                globalContext.notifications('error', result.error);
            }
            else {
                sbContext.ServiceDispatch({ type: 'CHECKOUT_PAYMENT' });
            }
        }
    };

    const FnRemoveAddOn = async (item) => {
        // const session = getData('session');
        // if (!session) {
        //     globalContext.notifications('error', 'Your session has been expired, Please login again');
        //     history.push("/");
        // }
        // else {
        //     arnum = session.arnum;
        //     email = session.email;
        // }
        globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
        var result = await PostAPI(`booking/cart/addons/remove/${globalContext.commonState.cartrefnum}`, item);
        if (result) {
            globalContext.loaderDispatch({ type: 'STOP_LOADING' });
            if (result.error) {
                globalContext.notifications('error', result.error);
            }
            else {
                //FnGetCartList(globalContext.commonState.cartrefnum);
                // alert('removed');
                FnGetCartList(globalContext.commonState.cartrefnum);
                //sbContext.BookingDispatch({ type: 'REMOVE_ADD_ONS', guid: item.guid, pguid: item.pguid, price: item.price, qty: item.qty });
            }
        }
    };

    // const getRowSpan = (item) => {
    //     return parseInt(item.length || 0) + 1;
    // }

    return (
        <>
            <Row>
                <Col className="ml-auto mr-auto pt-10 pb-30" xl="9" lg="12" md="12">
                    <div className="card">
                        <div className="card-header text-left title-2">
                            Finalise Service Booking
                            </div>
                        <React.Fragment>
                            <div className="card-body p-0-xs">
                                <div>
                                    <Row>
                                        <Col md="12">
                                            {window.innerWidth > 768 ?
                                                <React.Fragment>
                                                    {sbContext.BookingState.cartList &&
                                                        <React.Fragment>
                                                            {sbContext.BookingState.cartList.length < 1 ?
                                                                <div className="text-center empty-screen">Cart Is Empty</div> :
                                                                <React.Fragment>
                                                                    <p className="title-3">Booking Summary</p>
                                                                    <table className="table order-details-table n-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                                <th style={{ width: "15%" }}>Service</th>
                                                                                <th style={{ width: "40%" }}>Description</th>
                                                                                <th style={{ width: "10%" }}>Qty</th>
                                                                                <th style={{ width: "15%" }}>Price</th>
                                                                                <th style={{ width: "20%" }}>Total</th>
                                                                            </tr>
                                                                        </thead>
                                                                        {sbContext.BookingState.cartList.map((item, index) => (
                                                                            <tbody key={index}>
                                                                                <tr>
                                                                                    <td className="text-center" rowSpan={parseInt(item.addonList.length || 0) + 1}>
                                                                                        <img
                                                                                            alt="..."
                                                                                            className="product-img"
                                                                                            src={item.imagefile}
                                                                                        ></img>
                                                                                        {item.hasAddon &&
                                                                                            <React.Fragment>
                                                                                                {item.addonList.length > 0 ?
                                                                                                    <button
                                                                                                        className={"btn btn-round btn-info"}
                                                                                                        onClick={e => {
                                                                                                            e.preventDefault();
                                                                                                            {
                                                                                                                item.isMultijob ?
                                                                                                                    sbContext.ServiceDispatch({ type: 'MLT_PACKAGE_ADD_ONS', payload: item, callback: FncheckRefNum })
                                                                                                                    :
                                                                                                                    sbContext.ServiceDispatch({ type: 'PACKAGE_ADD_ONS', payload: item, callback: FncheckRefNum })
                                                                                                            }
                                                                                                            //sbContext.ServiceDispatch({type: 'MLT_PACKAGE_ADD_ONS', payload: item });
                                                                                                            //<ModalPackageAddOns PackageDetails={item} />
                                                                                                        }}>
                                                                                                        MODIFY ADD-ON
                                                                                                                 </button>
                                                                                                    :
                                                                                                    <button
                                                                                                        className={"btn btn-round btn-info"}
                                                                                                        onClick={e => {
                                                                                                            e.preventDefault();
                                                                                                            {
                                                                                                                item.isMultijob ?
                                                                                                                    sbContext.ServiceDispatch({ type: 'MLT_PACKAGE_ADD_ONS', payload: item, callback: FncheckRefNum })
                                                                                                                    :
                                                                                                                    sbContext.ServiceDispatch({ type: 'PACKAGE_ADD_ONS', payload: item, callback: FncheckRefNum })
                                                                                                            }
                                                                                                            //sbContext.ServiceDispatch({ type: 'MLT_PACKAGE_ADD_ONS', payload: item });
                                                                                                        }}>
                                                                                                        ADD-ON
                                                                                                                 </button>
                                                                                                }
                                                                                            </React.Fragment>
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        <Link to="#"
                                                                                            className={"btn-link btn-info product-name"}
                                                                                            onClick={e => {
                                                                                                e.preventDefault();
                                                                                                sbContext.ServiceDispatch({ type: 'PACKAGE_DETAILS', payload: item, callback: FncheckRefNum });
                                                                                            }}>
                                                                                            {item.matname}
                                                                                        </Link>
                                                                                        <button className="remove-addon" aria-label="Close" title="Remove service"
                                                                                            onClick={e => {
                                                                                                e.preventDefault();
                                                                                                FnRemoveProduct(item);
                                                                                            }}>
                                                                                            <span>×</span>
                                                                                        </button>
                                                                                        <p className="product-details">
                                                                                            {/* Order No: {item.refnum}<br /> */}
                                                                                            <Link to="#"
                                                                                                onClick={e => {
                                                                                                    e.preventDefault();
                                                                                                    sbContext.ServiceDispatch({ type: 'PACKAGE_DETAILS', payload: item, callback: FncheckRefNum });
                                                                                                }}>
                                                                                                Click to view item detail
                                                                                        </Link>



                                                                                            <br />
                                                                                            {/* Frequency: {item.duration} */}
                                                                                        </p>
                                                                                    </td>
                                                                                    {/* <td>
                                                                                                    {
                                                                                                        item.addon &&
                                                                                                        item.addon.split("||").map((item, index) => (
                                                                                                            <div key={index} className="pb-10">
                                                                                                                {item}
                                                                                                                <button className="close"  aria-label="Close"
                                                                                                                    onClick={e => {
                                                                                                                        e.preventDefault();
                                                                                                                        //FnRemoveProduct(item);
                                                                                                                    }}>
                                                                                                                    <span>×</span>
                                                                                                                </button>
                                                                                                                <br />
                                                                                                            </div>
                                                                                                        ))
                                                                                                    }
                                                                                                </td> */}
                                                                                    <td>
                                                                                        {!item.isMultijob &&
                                                                                            <Input type="number" className="product-price-text"
                                                                                                value={item.qty}
                                                                                                readOnly={true}
                                                                                                onChange={e =>
                                                                                                    sbContext.BookingDispatch({
                                                                                                        type: 'UPDATE_QTY',
                                                                                                        guid: item.guid,
                                                                                                        field: 'qty',
                                                                                                        value: e.currentTarget.value,
                                                                                                    })
                                                                                                }
                                                                                            ></Input>}
                                                                                    </td>
                                                                                    <td className="text-right">
                                                                                        {!item.isMultijob &&
                                                                                            <b>{currency} &nbsp;<span>{(parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0)).toFixed(2)}</span></b>
                                                                                        }
                                                                                    </td>
                                                                                    <td className="text-right">
                                                                                        {!item.isMultijob &&
                                                                                            <b>
                                                                                                {currency} &nbsp;<span>{((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0)) * parseInt(item.qty || 1)).toFixed(2)}</span>
                                                                                            </b>
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                                {item.addonList && item.addonList.map((item, index) => (
                                                                                    <tr key={index}>
                                                                                        <td>
                                                                                            {item.desc}
                                                                                            <button className="remove-addon" aria-label="Close" title="Remove add-on"
                                                                                                onClick={e => {
                                                                                                    e.preventDefault();
                                                                                                    FnRemoveAddOn(item);
                                                                                                }}>
                                                                                                <span>×</span>
                                                                                            </button>
                                                                                        </td>
                                                                                        <td>
                                                                                            <Input type="number" className="product-price-text"
                                                                                                readOnly={true}
                                                                                                value={item.qty}
                                                                                            // onChange={e =>
                                                                                            //     sbContext.BookingDispatch({
                                                                                            //         type: 'UPDATE_QTY',
                                                                                            //         guid: item.guid,
                                                                                            //         field: 'qty',
                                                                                            //         value: e.currentTarget.value,
                                                                                            //     })
                                                                                            // }
                                                                                            ></Input>
                                                                                        </td>
                                                                                        <td className="text-right">
                                                                                            <b>{currency} &nbsp;<span>{(parseFloat(item.price || 0)).toFixed(2)}</span></b>
                                                                                        </td>
                                                                                        <td className="text-right">
                                                                                            <b>
                                                                                            {currency} &nbsp;<span>{(parseFloat(item.totalamt)).toFixed(2)}</span>
                                                                                            </b>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}

                                                                                {item.tnc && (
                                                                                    <tr>
                                                                                        <td colSpan="5">
                                                                                            <div className="text-left">
                                                                                                <span className="description" style={{ whiteSpace: "pre-line" }}>{item.tnc}</span>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                )}
                                                                                <tr>
                                                                                    <td colSpan="3" rowSpan="2">
                                                                                        {/* <Row>
                                                                                            <Col md="6">
                                                                                                {item.addon ?
                                                                                                    <button
                                                                                                        className={"btn btn-round btn-info"}
                                                                                                        onClick={e => {
                                                                                                            e.preventDefault();
                                                                                                            sbContext.ServiceDispatch({ type: 'PACKAGE_ADD_ONS', payload: item });
                                                                                                        }}>
                                                                                                        MODIFY ADD-ON
                                                                                                                 </button>
                                                                                                    :
                                                                                                    <button
                                                                                                        className={"btn btn-round btn-info"}
                                                                                                        onClick={e => {
                                                                                                            e.preventDefault();
                                                                                                            sbContext.ServiceDispatch({ type: 'PACKAGE_ADD_ONS', payload: item });
                                                                                                        }}>
                                                                                                        ADD-ON
                                                                                                                 </button>
                                                                                                }
                                                                                            </Col>
                                                                                        </Row> */}
                                                                                        {/*<Row>
                                                                                            <Col md="6">
                                                                                                <p className="title-3 mb-0">  Do you have a voucher?</p>
                                                                                                <FormGroup>
                                                                                                    
                                                                                                    <InputGroup>
                                                                                                        <Input type="text" placeholder="Enter & apply code here"
                                                                                                            value={item.promocode}
                                                                                                            disabled={item.discamt === 0 ? false : true}
                                                                                                            onChange={e =>
                                                                                                                sbContext.BookingDispatch({
                                                                                                                    type: 'APPLY_COUPON',
                                                                                                                    field: 'promocode',
                                                                                                                    guid: item.guid,
                                                                                                                    value: e.currentTarget.value,
                                                                                                                })
                                                                                                            }
                                                                                                        />
                                                                                                        <InputGroupAddon addonType="append">
                                                                                                            {item.discamt === 0 ?
                                                                                                                <button
                                                                                                                    className={"btn btn-default m-0"}
                                                                                                                    onClick={e => {
                                                                                                                        e.preventDefault();
                                                                                                                        FnApplyPromoCode(item.guid, item.matnum, item.promocode, item.qty, item.refnum);
                                                                                                                    }}>
                                                                                                                    APPLY
                                                                                                                        </button>
                                                                                                                :
                                                                                                                <button
                                                                                                                    className={"btn btn-default m-0"}
                                                                                                                    onClick={e =>
                                                                                                                        sbContext.BookingDispatch({
                                                                                                                            type: 'REMOVE_COUPON',
                                                                                                                            guid: item.guid,
                                                                                                                        })
                                                                                                                    }>
                                                                                                                    ROMOVE
                                                                                                                         </button>
                                                                                                            }
                                                                                                        </InputGroupAddon>
                                                                                                    </InputGroup>
                                                                                                </FormGroup>
                                                                                            </Col> */}
                                                                                        {/* <Col md="6" className="pt-15">
                                                                                                {item.addonList.length > 0 ?
                                                                                                    <button
                                                                                                        className={"btn btn-round btn-info"}
                                                                                                        onClick={e => {
                                                                                                            e.preventDefault();
                                                                                                            {
                                                                                                                item.isMultijob ?
                                                                                                                    sbContext.ServiceDispatch({ type: 'MLT_PACKAGE_ADD_ONS', payload: item, callback: FncheckRefNum })
                                                                                                                    :
                                                                                                                    sbContext.ServiceDispatch({ type: 'PACKAGE_ADD_ONS', payload: item, callback: FncheckRefNum })
                                                                                                            }
                                                                                                            //sbContext.ServiceDispatch({type: 'MLT_PACKAGE_ADD_ONS', payload: item });
                                                                                                            //<ModalPackageAddOns PackageDetails={item} />
                                                                                                        }}>
                                                                                                        MODIFY ADD-ON
                                                                                                                 </button>
                                                                                                    :
                                                                                                    <button
                                                                                                        className={"btn btn-round btn-info"}
                                                                                                        onClick={e => {
                                                                                                            e.preventDefault();
                                                                                                            {
                                                                                                                item.isMultijob ?
                                                                                                                    sbContext.ServiceDispatch({ type: 'MLT_PACKAGE_ADD_ONS', payload: item, callback: FncheckRefNum })
                                                                                                                    :
                                                                                                                    sbContext.ServiceDispatch({ type: 'PACKAGE_ADD_ONS', payload: item, callback: FncheckRefNum })
                                                                                                            }
                                                                                                            //sbContext.ServiceDispatch({ type: 'MLT_PACKAGE_ADD_ONS', payload: item });
                                                                                                        }}>
                                                                                                        ADD-ON
                                                                                                                 </button>
                                                                                                }
                                                                                            </Col> */}

                                                                                        {/* <Col md="2">
                                                                                                        <FormGroup>
                                                                                                            {item.discamt === 0 ?
                                                                                                                <button
                                                                                                                    className={"btn btn-round btn-default m-0"}
                                                                                                                    onClick={e => {
                                                                                                                        e.preventDefault();
                                                                                                                        FnApplyPromoCode(item.guid, item.matnum, item.promocode, item.qty, item.refnum);
                                                                                                                    }}>
                                                                                                                    APPLY
                                                                                                            </button>
                                                                                                                :
                                                                                                                <button
                                                                                                                    className={"btn btn-round btn-default m-0"}
                                                                                                                    onClick={e =>
                                                                                                                        sbContext.BookingDispatch({
                                                                                                                            type: 'REMOVE_COUPON',
                                                                                                                            guid: item.guid,
                                                                                                                        })
                                                                                                                    }>
                                                                                                                    ROMOVE
                                                                                                             </button>
                                                                                                            }
                                                                                                        </FormGroup>
                                                                                                    </Col> 
                                                                                        </Row>*/}
                                                                                    </td>
                                                                                    <td className="title-3 pr-0">
                                                                                        {/* <span
                                                                                                        className={"btn-plus-minus"}
                                                                                                        onClick={e => {
                                                                                                            e.preventDefault();
                                                                                                            showHide();
                                                                                                        }}>
                                                                                                        <i className={"fa " + changeName()}></i>
                                                                                                    </span> */}
                                                                                        <span>
                                                                                            SUB TOTAL
                                                                                            </span>
                                                                                    </td>
                                                                                    {/* <td className="title-3 pt-20 pl-0 text-right">S$ &nbsp;<span>{((((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0) + parseFloat(item.optamt || 0)) * parseInt(item.qty || 0)) - parseFloat(item.discamt))).toFixed(2)}</span></td> */}
                                                                                    <td className="title-3 pt-20 pl-0 text-right">{currency} &nbsp;<span>{((((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0)) * parseInt(item.qty || 0)) - parseFloat(item.discamt)) + parseFloat(item.optamt || 0)).toFixed(2)}</span></td>
                                                                                </tr>

                                                                                {
                                                                                    show ? (
                                                                                        <tr>
                                                                                            <td>Location Fee</td>
                                                                                            <td className="text-right">{currency} &nbsp;<span>{((parseFloat(item.totalLocamt || 0)) * parseInt(item.qty || 1)).toFixed(2)}</span></td>
                                                                                        </tr>
                                                                                    ) : null}
                                                                                {/* {
                                                                                    show ? (
                                                                                        <tr>
                                                                                            <td>Service Charge</td>
                                                                                            <td className="text-right">S$ &nbsp;<span>{(0).toFixed(2)}</span></td>
                                                                                        </tr>
                                                                                    ) : null} */}

                                                                                {/* {show ? (
                                                                                    <tr>
                                                                                        <td>Add-Ons</td>
                                                                                        //<td className="text-right">S$ &nbsp;<span>{((parseFloat(item.optamt || 0)) * parseInt(item.qty || 1)).toFixed(2)}</span></td>
                                                                                        <td className="text-right">S$ &nbsp;<span>{((parseFloat(item.optamt || 0))).toFixed(2)}</span></td>
                                                                                    </tr>
                                                                                ) : null} */}

                                                                                {/* {show && item.discamt > 0 ? (
                                                                                    <tr>
                                                                                        <td>Discount <span className="text-success">[{item.disctype} OFF]</span></td>
                                                                                        <td className="text-right">S$ &nbsp;<span>{(parseFloat(item.discamt)).toFixed(2)}</span></td>
                                                                                    </tr>
                                                                                ) : null} */}
                                                                            </tbody>
                                                                        ))
                                                                        }
                                                                    </table>
                                                                </React.Fragment>
                                                            }
                                                        </React.Fragment>
                                                    }
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    {sbContext.BookingState.cartList &&
                                                        <React.Fragment>
                                                            {
                                                                sbContext.BookingState.cartList.length < 1 ?
                                                                    <div className="text-center empty-screen">Cart Is Empty</div> :
                                                                    <React.Fragment>
                                                                        {
                                                                            sbContext.BookingState.cartList.map((item, index) => (
                                                                                <div key={index}>
                                                                                    <div className="card mb-10">
                                                                                        <div className="card-body">
                                                                                            {/* <button type="button" className="close delete-product">
                                                                                                <span>×</span>
                                                                                            </button> */}
                                                                                            <button className="close delete-product"
                                                                                                onClick={e => {
                                                                                                    e.preventDefault();
                                                                                                    // sbContext.BookingDispatch({ type: 'REMOVE_PRODUCT', guid: item.guid });
                                                                                                    FnRemoveProduct(item);
                                                                                                }}>
                                                                                                <span>×</span>
                                                                                            </button>

                                                                                            <Row>
                                                                                                <Col lg="3" md="3" sm="3" xs="3" className="pr-0 pl-0 pt-5 text-center">
                                                                                                    <img className="product-img"
                                                                                                        alt="..."
                                                                                                        src={item.imagefile}
                                                                                                    ></img>
                                                                                                </Col>
                                                                                                <Col md="9" sm="9" xs="9" className="pl-5">
                                                                                                    <Link to="#"
                                                                                                        className={"btn-link btn-info product-name"}
                                                                                                        onClick={e => {
                                                                                                            e.preventDefault();
                                                                                                            sbContext.ServiceDispatch({ type: 'PACKAGE_DETAILS', payload: item, callback: FncheckRefNum });
                                                                                                        }}>
                                                                                                        {item.matname}
                                                                                                    </Link>
                                                                                                    {/* <div className="product-name">
                                                                                                        {item.matname}
                                                                                                    </div> */}



                                                                                                    <p className="product-details">
                                                                                                        {/* Order No: {item.refnum}<br /> */}
                                                                                                        <Link to="#"
                                                                                                            // className={"btn-link btn-info product-name"}
                                                                                                            onClick={e => {
                                                                                                                e.preventDefault();
                                                                                                                sbContext.ServiceDispatch({ type: 'PACKAGE_DETAILS', payload: item, callback: FncheckRefNum });
                                                                                                            }}>
                                                                                                            Click to view item detail<br />
                                                                                                        </Link>
                                                                                                        {!item.isMultijob &&
                                                                                                            <b>{currency} &nbsp;<span>{(parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0)).toFixed(2)}</span></b>
                                                                                                        }
                                                                                                    </p>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                        {!item.isMultijob &&
                                                                                            <div style={{ padding: "1.25rem 1.25rem 0.75rem", borderTop: "1px solid rgba(0, 0, 0, .125)" }}>
                                                                                                <Row>
                                                                                                    <Col xl="6" lg="6" md="6" sm="6" xs="6">
                                                                                                        <FormGroup>
                                                                                                            <Input type="number" placeholder="Quantity here"
                                                                                                                className="text-center"
                                                                                                                value={item.qty}
                                                                                                                readOnly={true}
                                                                                                                onChange={e =>
                                                                                                                    sbContext.BookingDispatch({
                                                                                                                        type: 'UPDATE_QTY',
                                                                                                                        guid: item.guid,
                                                                                                                        field: 'qty',
                                                                                                                        value: e.currentTarget.value,
                                                                                                                    })
                                                                                                                }
                                                                                                            ></Input>
                                                                                                        </FormGroup>
                                                                                                    </Col>
                                                                                                    <Col xl="6" lg="6" md="6" sm="6" xs="6" className="pt-5">
                                                                                                        <div className="title-2">
                                                                                                            <b>{currency} &nbsp;<span>{((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0)) * parseInt(item.qty || 1)).toFixed(2)}</span></b>
                                                                                                        </div>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </div>
                                                                                        }
                                                                                        {item.hasAddon &&
                                                                                            <div style={{ padding: "0.5rem 1.25rem 0.75rem", borderTop: "1px solid rgba(0, 0, 0, .125)" }}>
                                                                                                <Row>
                                                                                                    <Col md="12" className="text-left pt-5">
                                                                                                        <span className="title-3">Add-ons</span>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                                <Row className="mt-10">
                                                                                                    <Col md="12">
                                                                                                        {item.addonList && item.addonList.map((item, index) => (
                                                                                                            <React.Fragment key={index}>
                                                                                                                <Row className="mt-5">
                                                                                                                    <Col xs="10">
                                                                                                                        {item.desc}&nbsp;({currency} &nbsp;<span>{(parseFloat(item.price || 0)).toFixed(2)}</span>)
                                                                                                                </Col>
                                                                                                                    <Col xs="2">
                                                                                                                        <button className="remove-addon" aria-label="Close" title="Remove add-on"
                                                                                                                            onClick={e => {
                                                                                                                                e.preventDefault();
                                                                                                                                FnRemoveAddOn(item);
                                                                                                                            }}>
                                                                                                                            <span>×</span>
                                                                                                                        </button>
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                                <Row className="mt-5">
                                                                                                                    <Col xs="5">
                                                                                                                        {/* <Input type="number" className="text-center"
                                                                                                                        readOnly={true}
                                                                                                                        value={item.qty}
                                                                                                                    ></Input> */}
                                                                                                                    Quantity: {item.qty}
                                                                                                                    </Col>
                                                                                                                    <Col xs="7" className="text-right">
                                                                                                                        Total: &nbsp; {currency} &nbsp;<span>{(parseFloat(item.price || 0) * (parseFloat(item.qty || 0))).toFixed(2)}</span>
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                                <hr></hr>
                                                                                                            </React.Fragment>
                                                                                                        ))}
                                                                                                    </Col>
                                                                                                </Row>
                                                                                                <Row>
                                                                                                    <Col className="text-right" xs="12">
                                                                                                        <React.Fragment>
                                                                                                            {item.addonList.length > 0 ?
                                                                                                                <React.Fragment>
                                                                                                                    <button
                                                                                                                        className={"btn btn-round btn-block btn-info m-0"}
                                                                                                                        onClick={e => {
                                                                                                                            e.preventDefault();
                                                                                                                            {
                                                                                                                                item.isMultijob ?
                                                                                                                                    sbContext.ServiceDispatch({ type: 'MLT_PACKAGE_ADD_ONS', payload: item, callback: FncheckRefNum })
                                                                                                                                    :
                                                                                                                                    sbContext.ServiceDispatch({ type: 'PACKAGE_ADD_ONS', payload: item, callback: FncheckRefNum })
                                                                                                                            }
                                                                                                                        }}>
                                                                                                                        MODIFY ADD-ON
                                                                                                        </button>
                                                                                                                </React.Fragment>
                                                                                                                :
                                                                                                                <button
                                                                                                                    className={"btn btn-round btn-block btn-info m-0"}
                                                                                                                    onClick={e => {
                                                                                                                        e.preventDefault();
                                                                                                                        {
                                                                                                                            item.isMultijob ?
                                                                                                                                sbContext.ServiceDispatch({ type: 'MLT_PACKAGE_ADD_ONS', payload: item, callback: FncheckRefNum })
                                                                                                                                :
                                                                                                                                sbContext.ServiceDispatch({ type: 'PACKAGE_ADD_ONS', payload: item, callback: FncheckRefNum })
                                                                                                                        }
                                                                                                                    }}>
                                                                                                                    ADD ADD-ON
                                                                                                     </button>
                                                                                                            }
                                                                                                        </React.Fragment>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </div>
                                                                                        }


                                                                                        {item.tnc && (
                                                                                            <div style={{ padding: "1.25rem 1.25rem 0.75rem", borderTop: "1px solid rgba(0, 0, 0, .125)" }}>
                                                                                                <Row>
                                                                                                    <Col md="12">
                                                                                                        <div className="text-left">
                                                                                                            <span className="description" style={{ whiteSpace: "pre-line" }}>{item.tnc}</span>
                                                                                                        </div>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </div>
                                                                                        )}


                                                                                        {/* <div style={{ padding: "1.25rem 1.25rem 0.75rem", borderTop: "1px solid rgba(0, 0, 0, .125)" }}>
                                                                                            <Row>
                                                                                                <Col md="12">
                                                                                                    <p className="title-3 mb-0">  Do you have a voucher?</p>
                                                                                                    <FormGroup>
                                                                                                       
                                                                                                        <InputGroup>
                                                                                                            <Input type="text" placeholder="Enter & apply code here"
                                                                                                                value={item.promocode}
                                                                                                                disabled={item.discamt === 0 ? false : true}
                                                                                                                onChange={e =>
                                                                                                                    sbContext.BookingDispatch({
                                                                                                                        type: 'APPLY_COUPON',
                                                                                                                        field: 'promocode',
                                                                                                                        guid: item.guid,
                                                                                                                        value: e.currentTarget.value,
                                                                                                                    })
                                                                                                                }
                                                                                                            />
                                                                                                            <InputGroupAddon addonType="append">
                                                                                                                {item.discamt === 0 ?
                                                                                                                    <button
                                                                                                                        className={"btn btn-default m-0"}
                                                                                                                        onClick={e => {
                                                                                                                            e.preventDefault();
                                                                                                                            FnApplyPromoCode(item.guid, item.matnum, item.promocode, item.qty, item.refnum);
                                                                                                                        }}>
                                                                                                                        APPLY
                                                                                                                        </button>
                                                                                                                    :
                                                                                                                    <button
                                                                                                                        className={"btn btn-default m-0"}
                                                                                                                        onClick={e =>
                                                                                                                            sbContext.BookingDispatch({
                                                                                                                                type: 'REMOVE_COUPON',
                                                                                                                                guid: item.guid,
                                                                                                                            })
                                                                                                                        }>
                                                                                                                        ROMOVE
                                                                                                                         </button>
                                                                                                                }
                                                                                                            </InputGroupAddon>
                                                                                                        </InputGroup>
                                                                                                    </FormGroup>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div> */}
                                                                                        <div style={{ padding: "1.25rem 1.25rem 0rem", borderTop: "1px solid rgba(0, 0, 0, .125)" }}>
                                                                                            <Row>
                                                                                                <table className="table order-details-table n-bordered">
                                                                                                    <thead>
                                                                                                        <tr>
                                                                                                            <th colSpan="2"><p className="title-2">Booking Summary</p></th>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <th className="title-3">
                                                                                                                <span>SUB TOTAL</span>
                                                                                                            </th>
                                                                                                            {/* <th className="title-2 text-right">S$ &nbsp;<span>{((((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0) + parseFloat(item.optamt || 0)) * parseInt(item.qty || 0)) - parseFloat(item.discamt || 0))).toFixed(2)}</span></th> */}
                                                                                                            <th className="title-2 text-right">{currency} &nbsp;<span>{((((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0)) * parseInt(item.qty || 0)) - parseFloat(item.discamt)) + parseFloat(item.optamt || 0)).toFixed(2)}</span></th>
                                                                                                        </tr>
                                                                                                        {show ? (
                                                                                                            <tr>
                                                                                                                <td>Location Fee</td>
                                                                                                                <td className="text-right">{currency} &nbsp;<span>{((parseFloat(item.totalLocamt || 0)) * parseInt(item.qty || 1)).toFixed(2)}</span></td>
                                                                                                            </tr>
                                                                                                        ) : null}

                                                                                                        {/* {show ? (
                                                                                                            <tr>
                                                                                                                <td>Service Charge</td>
                                                                                                                <td className="text-right">S$ &nbsp;<span>{(0).toFixed(2)}</span></td>
                                                                                                            </tr>
                                                                                                        ) : null} */}

                                                                                                        {/* {show ? (
                                                                                                            <tr>
                                                                                                                <td>Add-Ons</td>
                                                                                                               //  <td className="text-right">S$ &nbsp;<span>{((parseFloat(item.optamt || 0)) * parseInt(item.qty || 1)).toFixed(2)}</span></td>  
                                                                                                                <td className="text-right">S$ &nbsp;<span>{((parseFloat(item.optamt || 0))).toFixed(2)}</span></td>
                                                                                                            </tr>
                                                                                                        ) : null} */}
                                                                                                        {/* {show && item.discamt > 0 ? (
                                                                                                            <tr>
                                                                                                                <td>Discount <span className="text-success">[{item.disctype} OFF]</span></td>
                                                                                                                <td className="text-right">S$ &nbsp;<span>{(parseFloat(item.discamt || 0)).toFixed(2)}</span></td>
                                                                                                            </tr>
                                                                                                        ) : null} */}
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </Row>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </React.Fragment>
                                                            }
                                                        </React.Fragment>
                                                    }
                                                </React.Fragment>
                                            }
                                        </Col>
                                    </Row>
                                    {sbContext.BookingState.total > 0 &&
                                        <Row>
                                            <Col xl="4" lg="4" md="12" sm="12" xs="12">
                                                <Row className="pl-10 pr-10">
                                                    <Col xl="12" lg="12" md="12" sm="12" xs="12" className="mt-5 text-left">
                                                        <p className="title-3 mb-0">  Do you have a voucher?</p>
                                                        <FormGroup>
                                                            <InputGroup>
                                                                <Input type="text" placeholder="Enter & apply code here"
                                                                    value={sbContext.BookingState.promoCode}
                                                                    disabled={sbContext.BookingState.discAmt === 0 ? false : true}
                                                                    onChange={e =>
                                                                        sbContext.BookingDispatch({
                                                                            type: 'APPLY_COUPON',
                                                                            //field: 'promocode',
                                                                            //guid: item.guid,
                                                                            promocode: e.currentTarget.value,
                                                                        })
                                                                    }
                                                                />
                                                                <InputGroupAddon addonType="append">
                                                                    {sbContext.BookingState.discAmt === 0 ?
                                                                        <button
                                                                            className={"btn btn-default m-0"}
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                //FnApplyPromoCode(item.guid, item.matnum, item.promocode, item.qty, item.refnum);
                                                                                FnApplyPromoCode(sbContext.BookingState.promoCode);
                                                                            }}>
                                                                            APPLY
                                                               </button>
                                                                        :
                                                                        <button
                                                                            className={"btn btn-default m-0"}
                                                                            onClick={e =>
                                                                                sbContext.BookingDispatch({
                                                                                    type: 'REMOVE_COUPON',
                                                                                    //guid: item.guid,
                                                                                })
                                                                            }
                                                                        >
                                                                            REMOVE
                                                             </button>
                                                                    }
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm="12" xs="12" className="d-lg-none d-xl-none d-sm-block d-md-block">
                                                <hr></hr>
                                            </Col>

                                            <Col xl="8" lg="8" md="12" sm="12" xs="12">
                                                <Row className="pl-10 pr-10">
                                                    <Col xl="9" lg="9" md="8" sm="7" xs="7" className="mt-5 text-right font-weight-normal">
                                                        &nbsp; &nbsp;Sub Total {currency}
                                                 </Col>
                                                    <Col xl="3" lg="3" md="4" sm="5" xs="5" className="mt-5 text-right font-weight-bold">
                                                        {parseFloat(sbContext.BookingState.subtotal || 0).toFixed(2)}
                                                    </Col>
                                                    {sbContext.BookingState.discAmt > 0 &&
                                                        <React.Fragment>
                                                            <Col xl="9" lg="9" md="8" sm="7" xs="7" className="mt-5 text-right font-weight-normal">
                                                                &nbsp; &nbsp;Discount {currency}
                                                        {/* <span className="text-success">[{item.promoCode} OFF]</span> */}
                                                            </Col>
                                                            <Col xl="3" lg="3" md="4" sm="5" xs="5" className="mt-5 text-right font-weight-bold">
                                                                {parseFloat(sbContext.BookingState.discAmt || 0).toFixed(2)}
                                                            </Col>
                                                        </React.Fragment>
                                                    }

                                                    <Col xl="9" lg="9" md="8" sm="7" xs="7" className="mt-5 text-right font-weight-normal">
                                                        &nbsp; &nbsp;Service charge {currency}
                                                            </Col>
                                                    <Col xl="3" lg="3" md="4" sm="5" xs="5" className="mt-5 text-right font-weight-bold">
                                                        {parseFloat(sbContext.BookingState.serviceFee || 0).toFixed(2)}
                                                    </Col>
                                                    <Col xl="9" lg="9" md="8" sm="7" xs="7" className="mt-5 text-right font-weight-normal">
                                                        &nbsp; &nbsp;Total (incl. Service charge) {currency}
                                                            </Col>
                                                    <Col xl="3" lg="3" md="4" sm="5" xs="5" className="mt-5 text-right font-weight-bold">
                                                        {parseFloat(parseFloat(sbContext.BookingState.subtotal || 0).toFixed(2) - parseFloat(sbContext.BookingState.discAmt || 0).toFixed(2)).toFixed(2)}
                                                    </Col>
                                                    <Col xl="9" lg="9" md="8" sm="7" xs="7" className="mt-5 text-right font-weight-normal">
                                                        &nbsp; &nbsp;VAT {currency}
                                                            </Col>
                                                    <Col xl="3" lg="3" md="4" sm="5" xs="5" className="mt-5 text-right font-weight-bold">
                                                        {parseFloat(sbContext.BookingState.tax || 0).toFixed(2)}
                                                    </Col>
                                                </Row>
                                                <Row className="pl-10 pr-10">
                                                    <Col xl="6" lg="6" md="6" sm="12" xs="12" className="offset-xl-6 offset-lg-6 offset-md-6">
                                                        <hr></hr>
                                                    </Col>
                                                </Row>
                                                <Row className="pl-10 pr-10">
                                                    <Col xl="9" lg="9" md="8" sm="7" xs="7" className="text-right">
                                                <span className="product-grand-total"> Grand Total {currency}</span>  <br />
                                                        {/* <span className="description" style={{ fontSize: "0.80em" }}>incl. VAT where applicable</span> */}
                                                    </Col>
                                                    <Col xl="3" lg="3" md="4" sm="5" xs="5" className="text-right product-grand-total">
                                                        <span className="product-grand-amount">{parseFloat(sbContext.BookingState.total || 0).toFixed(2)}</span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            </div>
                            <div className="card-footer text-center pl-0 pr-0">
                                <p className="description">Disclaimer: We may need to review charges based on accuracy of your service location and property size, and starting condition of your property. </p>
                                {/* <button
                                    className={"btn btn-round btn-default"}
                                    onClick={e => {
                                        e.preventDefault();
                                        sbContext.ServiceDispatch({ type: 'BOOKING_INFORMATION' });

                                    }}>
                                    PREVIOUS
                           </button> */}
                                {sbContext.BookingState.total > 0 &&
                                    <button
                                        className={"btn btn-round btn-info"}
                                        onClick={e => {
                                            e.preventDefault();
                                            FnOnSubmitFinalize();
                                        }}>
                                        CONFIRM & CHECKOUT
                              </button>
                                }
                            </div>
                        </React.Fragment>

                    </div>
                </Col>
            </Row >
        </>
    );
}

export default FinalizeBooking;