import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { BookingDetailsContext } from "../../views/MyAccount/MyBookingDetails";
import { Link } from "react-router-dom";
import { Button, } from "reactstrap";
import { DateFormat } from "../../utils/common";
import { GlobalContext } from '../../App';
import { getData } from "../../utils/localStorage";
import { PostAPI } from "../../API/APICalling";


export default function MBActiveDetails(props) {
    const globalContext = useContext(GlobalContext);
    const bdContext = useContext(BookingDetailsContext);
    const history = useHistory();
    // useEffect(() => {
    //     window.scrollTo({
    //         top: 0,
    //         behavior: 'smooth'
    //     });
    // }, []);


    const SkipDate = async () => {
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }
        else {
            if (!bdContext.BDState.BookingDetails[0].skiptodate) {
                globalContext.notifications('error', 'Please choose date.');
                return false
            }
            // if (!bdContext.BDState.BookingDetails.shiftnum) {
            //     globalContext.notifications('error', 'Please choose shift.');
            //     return false
            // }
            if (bdContext.BDState.BookingDetails[0].refnum) {
                globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
                var result = await PostAPI(`mybooking/details/changedate/${bdContext.BDState.BookingDetails[0].refnum}`,
                    {
                        refnum: bdContext.BDState.BookingDetails[0].refnum,
                        requestDate: bdContext.BDState.BookingDetails[0].skiptodate
                    }
                );
                if (result) {
                    globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                    if (result.error) {
                        bdContext.BDDispatch({ type: 'ERROR' });
                        globalContext.notifications('error', result.error);
                    }
                    else {
                        globalContext.notifications('success', 'Date time changed successfully.');
                        bdContext.BDDispatch({ type: 'CHANGE_DATE_SUCCESS' });
                        bdContext.BDDispatch({ type: 'CLOSE_MODAL' });
                    }
                }
            }
        }

    }

    return (
        <>
            <div className="card">
                {bdContext.BDState.BookingDetails &&
                    <React.Fragment>
                        {
                            bdContext.BDState.BookingDetails.length < 1 ?
                                <div className="text-center empty-screen">No Records Found</div>
                                :
                                <React.Fragment>
                                    <div className="card-body">
                                        <div>
                                            {bdContext.BDState.BookingDetails &&
                                                <React.Fragment>
                                                    {
                                                        bdContext.BDState.BookingDetails.map((item, index) => (
                                                            <React.Fragment key={index}>
                                                                <div className="text-center">
                                                                    <img
                                                                        alt="..."
                                                                        className="rounded-circle"
                                                                        src={item.crewimg || require("../../assets/img/user-default.png")}
                                                                        style={{ maxHeight: "125px" }}
                                                                    ></img>
                                                                </div>
                                                                <div className="text-center title-2">{item.crewname} </div>
                                                                {item.crewname.trim().toLowerCase() !== "not assigned" &&
                                                                    <React.Fragment>
                                                                        <div className="text-center customer-rating">{item.rating}</div>
                                                                        <div className="text-center title-3">Rating</div>
                                                                    </React.Fragment>
                                                                }
                                                            </React.Fragment>
                                                        ))
                                                    }
                                                </React.Fragment>
                                            }
                                            {/* <div className="text-center">
                                                <span className="btn-link btn btn-info p-0"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        bdContext.BDDispatch({ type: 'COMPONENT', ComponentType: 'CREW_LOCATION' });
                                                    }}
                                                ><b>VIEW CREW LOCATION</b></span>
                                                     |<span className="btn-link btn btn-info p-0"><b>CHAT WITH OUR CREW</b></span>
                                            </div> */}
                                        </div>
                                        <div className="table-responsive table-list-view" id="myBookingDetailsTable">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th className="text-left">Booking Status</th>
                                                        <th className="text-left">Modified By</th>
                                                        <th className="text-left">Reason for changes</th>
                                                        <th className="text-left">Modifield On</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        bdContext.BDState.BookingDetails.map((item, index) => (
                                                            <tr key={index}>
                                                                <td className={item.status === "Open" ? "pending text-left" : "confirmed text-left"}>{item.status}</td>
                                                                <td className="text-left">{item.modifiedby}</td>
                                                                <td className="text-left">{item.reason}</td>
                                                                <td className="text-left contactName">{DateFormat(item.modifiedon, 'DD MMM YYYY h:mm a')}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="card-footer text-center">
                                        <Button
                                            to="/my-account/my-booking" tag={Link}
                                            className={"btn btn-round btn-default"}
                                        >BACK
                                         </Button>

                                        {
                                            bdContext.BDState.BookingDetails[0].changebtn && (
                                                bdContext.BDState.BookingDetails[0].changeable ?
                                                    <Button
                                                        className={"btn btn-round btn-info"}
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            bdContext.BDDispatch({ type: 'MODAL', modalType: 'CHANGE_SERVICE_DATE', modalSize: 'xl' });
                                                        }}>CHANGE SERVICE DATE
                                                </Button>
                                                    :
                                                    <Button className={"btn btn-round btn-info"} disabled>CHANGE SERVICE DATE</Button>
                                            )

                                        }

                                        {bdContext.BDState.BookingDetails[0].skipbtn && (
                                            bdContext.BDState.BookingDetails[0].changeable ?
                                                <Button
                                                    className={"btn btn-round btn-info"}
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        // bdContext.BDDispatch({ type: 'MODAL', modalType: 'CHANGE_SERVICE_DATE', modalSize: 'xl' });
                                                        SkipDate();
                                                    }}>Skip to {DateFormat(bdContext.BDState.BookingDetails[0].skiptodate, 'DD MMM YYYY')}
                                                </Button>
                                                :
                                                <Button className={"btn btn-round btn-info"} disabled>Skip to Date</Button>
                                        )
                                        }
                                        {bdContext.BDState.BookingDetails[0].notes && (
                                            <div>
                                                Note: {bdContext.BDState.BookingDetails[0].notes}
                                            </div>
                                        )
                                        }
                                    </div>
                                </React.Fragment>
                        }
                    </React.Fragment>
                }
            </div>

        </>
    )
}