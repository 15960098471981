import React, { useEffect, useState, useContext } from "react";
// import { Link } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { GetAPI } from "../../API/APICalling";
import { GlobalContext } from '../../App.js';

function TermsOfService() {
    const globalContext = useContext(GlobalContext);
    const [data, setData] = useState('');
    useEffect(() => {
        window.scrollTo({
            top: 0,
            //behavior: 'smooth'
        });
        const FnGetCMSContent = async () => {
            globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
            var result = await GetAPI(`cms/termsofuse`);
            if (result) {
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                if (result.error) {
                    globalContext.notifications('error', result.error);
                }
                else if (result.data) {
                    setData(result.data.content.body || '')
                }
            }
        }
        FnGetCMSContent();
    }, []);



    return (
        <>
            <div className="adjust-navbar">
                <Container>

                    <Row dangerouslySetInnerHTML={{ __html: data }}></Row>

                    {/* <Row>

                        <Col className="ml-auto mr-auto text-left" md="10">
                            <h2 className="title-2">Whissh Terms of Service</h2>
                            <p className="title-3">SERVICES AGREEMENT</p>
                            <p className="description">
                                You have received a quotation from Whissh ("Quotation") relating to your engagement or use of certain services as detailed in the Quotation (“Services”) and by accepting the Quotation or otherwise engaging, accepting or using the Services, you are deemed to have entered into, or otherwise agreed to the terms and conditions of this Services Agreement (as from time to time amended, supplemented or modified, this “Agreement”).
                            </p>
                            <p className="description">
                                This Agreement is between the Client named on the Quotation ("you") and [1 Plus (Asia) Private Limited] or one of [1 Plus (Asia) Private Limited]’s affiliates or subsidiaries (collectively, “Whissh”), as the concierge for provision of the Services.
                            </p>
                            <p className="description">
                                This Agreement details the terms and conditions which apply between you and Whissh relating to your use of the Services.  The Service Providers are also subject to separate agreements with Whissh.
                            </p>
                            <p className="description">
                                Whissh reserve the right, at its sole discretion, to change, modify, add or remove a portion of The Agreement, in whole or in part, at any time, and such modification shall be effective immediately upon posting of the modified terms and conditions. You agree to review the Agreement periodically to be aware of such modification and your continued access to or use of the Website or Mobile Application shall be deemed your conclusive acceptance of the modified terms and conditions.
                            </p>
                            <p className="description">
                                You acknowledge that you have read the terms and conditions of use and that you accept the terms thereof. You agree these terms and conditions of use carefully before using the Website or the Mobile Application. If you do not agree to these terms and conditions of use, you may not access or otherwise, use this Website or Mobile Application.
                            </p>

                            <p className="title-3">
                                SERVICES
                            </p>
                            <p className="description">
                                The Services to be provided under this Agreement may include but is not limited to cleaning services, air-con servicing and/or maintenance services, laundry services, plumbing services.   Unless otherwise agreed, all Services will be provided on-site at the premises named on the Quotation (“Premises”).<br />
                                Whissh is not the provider of the Services, but is duly authorized to arrange for the Services from the suppliers or providers of such Services which may from time to time include certain of Whissh’s affiliates or subsidiaries (collectively, the “Service Providers”  and each “Service Provider”).<br />
                                You authorise Whissh to select such Service Providers to provide the Services as it may determine at its sole discretion, and Whissh reserves the right to replace,   substitute or terminate the Service Providers at any time.<br />
                                For the avoidance of doubt, Whissh is not an agent of the Service Providers nor you in the provision of the Services by the Service Providers and neither the Service Providers and their respective employees, sub-contractors, agents shall be deemed or construed as the joint venture partners, employees, sub-contractors, agents of Whissh in connection with the provision of the Services.

</p>
                            <p className="title-3">
                                FEES AND CHARGES
                            </p>
                            <p className="description">
                                Pursuant to this Agreement, you have authorized Whissh to collect and remit various fees and charges that will be due and owing by you in connection with your use of the Services, including, without limitation, all fees, charges and taxes (including any applicable goods and services tax) payable with respect to or in connection with your use of Services provided by Whissh and/or  Service Providers (collectively the “Service Fees”) .
                                <br />
                                You agree and acknowledge that  the Service Fees will include the fees payable to Whissh with respect to their role as concierge as well as the fees payable to the Service Providers, and you agree to pay the Fees to Whissh without any deduction, counterclaim or set off, including but not limited to any deduction, counterclaim or set off for any alleged loss or damage to your Premises which is alleged to be caused or otherwise attributable to any Service Providers.  For the avoidance of doubt, notwithstanding that Whissh will issue invoice(s) for the entire sum of the Service Fees,  only the  fees payable to Whissh with respect to their role as concierge shall be attributed to or considered as Whissh’s income.
                            </p>



                            <p className="title-3">
                                CANCELLATION AND TERMINATION
                            </p>
                            <p className="description">
                                Any cancellation or re-scheduling by you is subject to the prior consent of Whissh, the availability of the Service Providers to provide Services on an alternative date(s) and any other prevailing rules or terms and conditions governing cancellations and re-scheduling.  Cancellation or re-scheduling made less than 48 hours will be deemed delivered service.  No refund will be considered.
                                <br />
                                In the event that any Service Providers or any of the Services becomes unavailable, Whissh will notify you and use reasonable endeavours to re-schedule Services or find a replacement or substitute Service Provider failing which you are entitled to cancel the booking, and the amount of fees and charges paid by you (if any) will be refunded to you (pro-rated, if applicable) to take into account any Services already rendered). Save as aforesaid, neither Whissh nor any Service Providers will be liable to you for any further amounts in respect of any cancellation or re-scheduling  of Services by us.
                                Any failure by you to comply with this Agreement, including any failure to allow the Service Providers to supply Services, will permit the Whissh to terminate this Agreement without liability (and without prejudice to any other rights and remedies pursuant to this Agreement).
                            </p>


                            <p className="title-3">
                                PROPERTY CONDITION
                            </p>
                            <p className="description">
                                As the owner or occupant of the Premises, you have primary responsibility for ensuring that the Premises is safe for anyone that Whissh and/or the Service Providers procure to enter, or allow in or around the Premises for purposes of provision of the Services.
                                <br />
                                You must notify Whissh of any claim for loss or damage to your Premises, or any of the contents, fixtures or fittings therein within the same day as the completion of the provision of the Services, failing which, you shall be deemed to have represented that there is no such loss or damage or to have waived any such claims (even if applicable)
                            </p>


                            <p className="title-3">
                                LIABILITY AND CLAIMS
                            </p>
                            <p className="description">
                                While Whissh will use reasonable endeavours to procure and ensure quality Services, it makes no representation or warranty, and assumes  no responsibility or liability for the satisfactory outcome of the Services including any loss or damage that may be caused by the Service Providers and their respective employees, sub-contractors, agents or otherwise suffered or incurred in the course of the provision of the Services.
                                <br />
                                Any claims made by you under this Agreement, including claims against the Service Providers (if applicable), must be made initially through Whissh. Whissh has the authority to negotiate and settle claims on behalf of itself and the Service Providers. Whissh will be liable for any damages to the Client property up to a maximum of $200 or 50% of the cost price, whichever is lower; damage reported after 24 hours after the cleaning will not be attended.
                                Neither Whissh nor the Service Providers will have any liability for any business, financial or economic loss or for any consequential, incidental, special or indirect losses such as lost reputation, lost bargain, lost profit, loss of anticipated savings or lost opportunity arising as a result of or in connection this Agreement, the provision of Services by the Service Providers  (whether such loss arises as a result of negligence, breach of contract, tort or otherwise by Whissh, the Service Providers or any agent or third party providing services on behalf of Whissh or the Service Providers).
                                Subject to any exceptions set forth in applicable law, the liability of Whissh and the Service Providers for any and all losses or damage suffered by you and/or the Premises (including, without limitation for property damage, personal injury and death and whether such claim arises out of breach of contract, tort, negligence or otherwise) will be limited to the cost of obtaining a replacement Service or the amount of the Service Fees and charges payable by you, whichever is the higher amount.

                            </p>

                            <p className="description">
                                You agree to defend, indemnify and save harmless Whissh, each of the Service Providers and their respective directors, officers, employees, contractors, heirs administrators, representatives, successors and assigns (collectively, the “Indemnified Parties”) from and against any and all liability, claims, loss, property damage or expenses (including legal fees and expenses on a full indemnity basis) (i) arising by reason of any injury, death or damages sustained by anyone that Whissh and/or the Service Providers procure to enter, or allow in or around the Premises for purposes of provision of the Services, unless such injury death or damage is caused by the gross negligence or intentional act or omission, or by any such persons and (ii) suffered or incurred by the Indemnified Parties or any of the Indemnified Parties arising out of or relating to a breach or alleged breach by you of this Agreement, including without limitation this paragraph 4.

                            </p>
                            <p className="title-3">
                                SERVICE TERMS
                            </p>
                            <p className="description">
                                You will notify us if there are any changes to your particulars.<br />
                                Whissh reserves the rights to terminate all service immediately without the notice if the Client is found directly or indirectly involved in any form of activities which may cause harm/damage to Whissh or Whissh’s employees. The Client will still be liable for the outstanding debt to the company.<br />
                                Whissh will be liable for any damages to the Client property up to a maximum of $200 or 50% of the cost price, which is lower; damage reported after 24 hours after the cleaning will not be attended.
                                You are not allowed to solicit or negotiate any deal with any of the company’s employee without the company’s prior consent or involvement.<br />
                                Upon termination of service agreement between Whissh and the Client, Whissh’s employees are not allowed to provide anymore services for the Client for a period of six (6) months from the date of termination of contract.<br />
                                No additional services are to be provided to the Client by Whissh’s employees except for those stated in the package chosen. If so, Whissh can impose additional charges for these services to the Client.<br />
                                Service Crew are not to engage in dangerous activities such as climbing up high ladder, climbing outside the window to wipe the window grilles, etc.<br />
                                Service Crew’s allocation and availability are strictly subjected to Whissh.  We comply strictly to non-discrimination policy.   Any replacement of Service Crew, there may or may not have a replacement fee.<br />
                                Kindly inform us of any termination of services by giving us a two (2) weeks’ notice, otherwise penalty charges equivalent to your recurring package will be charge.<br />
                                No additional service is to be provided to the Client by Whissh’s employees other than prior stated and agreed.

                            </p>
                            <p className="title-3">
                                CLIENT OBLIGATIONS
                            </p>
                            <p className="description">
                                To safeguard your own property and to prevent any misunderstandings, please kindly store your valuables away in a safe place when Service Crew is around.<br />
                                To do all the things necessary to enable Service Crew to efficiently perform the service you have booked in accordance with the booking request.<br />
                                Provide the necessary equipment unless you have separately purchased add-on products and equipment.<br />
                                To do all the things necessary to provide a safe environment for the Service Crew<br />
                                You are responsible for notifying Whissh of any damages caused by the Service Crew within 4 hours.<br />
                                When booking a service, you are obliged to provide details of name, address telephone, email address and the payment details. Upon the completion of the registration, an email confirmation will be send to your nominated email address.<br />
                                The Client must ensure that there is running water and electricity at the premises where the service take place.<br />
                                Whissh have been insured with a $250K public liability insurance to cater for incident or accident should there be any mishap happened in your premises when the Service Crew is in your premises. Damaged reported after Service Crew left the premises after 4 (four) hours will not be attended.<br />
                                The Client is not allowed to solicit or negotiate any deal with any of the company’s employee without Whissh’s prior consent or involvement.
                            </p>

                            <p className="title-3">
                                WARRANTY OF SERVICES
                            </p>
                            <p className="description">
                                All our services will serve their intended functions. The use of the Website and Mobile Application is at your own risk. Everything from the Website and Mobile Application are provided to you on an “as is” and “as available” basis without warranty or condition of any kind.<br />
                                All warranty condition is stated in the services information provided. Unless the manufacturer has provided non. None of Whissh’s officers, employees, contributors, third party content providers or licensors make any express or implied representation or warranty of the Website or Mobile Application.

                            </p>

                            <p className="title-3">
                                PRICES
                            </p>
                            <p className="description">
                                All our prices are in Nett. No discounts will be given unless stated otherwise. (e.g. promo codes)
                            </p>

                            <p className="title-3">
                                PAYMENT PROCESSING
                            </p>
                            <p className="description">
                                All charges are due immediately and payment will be facilitated by Whissh using the preferred payment method specified at the time of the payment, after which Whissh will send you a receipt by email.<br />
                                Whissh have taken all practical steps from both a technical and systems perspective to ensure that all of your information is well protected. A secure payment gateway is used to process all transactions and credit card details. Whissh does not give any warranty or make any representation regarding the strength or effectiveness of the secure payment gateway and is not responsible for events arising from unauthorized access to your information.<br />
                                Whissh ensure accuracy in our billing as all our transactions are automatically processed.

                            </p>

                            <p className="title-3">
                                CONFIRMATION OF SERVICES/ORDERS
                            </p>
                            <p className="description">
                                We will refund the amount paid if the order is cancelled due to the unavailability of services or our inability to deliver the services within the agreed time frame.<b />
                                Whissh will send an electronic acknowledgment to you within twenty-four (24) hours upon successful submission of the electronic order to your nominated email account. If the service ordered are unavailable, a message will be sent to the Client for further action.<b />
                            </p>
                            <p className="title-3">
                                EXCHANGE AND REFUNDS
                            </p>
                            <p className="description">
                                All our services are non-exchangeable and non-refundable.
                            </p>

                            <p className="title-3">
                                FEEDBACK
                            </p>
                            <p className="description">
                                We welcome your feedback. Should you feel dissatisfied with any of our services, please contact us at: <br />
                            Tel                : (+65) 6221 8626  <br />
                            Email            : contact@whissh.com.sg
                            </p>

                            <p className="title-3">
                                GENERAL
                            </p>
                            <p className="description">
                                You agree that you have the full power and authority to enter into this Agreement.<br />
                                You will not be entitled to withhold by way of set-off, deduction, or counterclaim any amounts which you owe to Whissh against any amounts that may be owed to you.<br />
                                Whissh will be entitled to assign or sub-contract its obligations under this Agreement. You may not assign this Agreement.<br />
                                Whissh will not be liable to you or be deemed to be in breach of this Agreement by reason of any delay in performing, or any failure to perform, any of its obligations, if the delay or failure was due to any cause beyond its reasonable control such as severe weather, power or other utility cut-off, burglary, natural disaster, strikes, governmental action, terrorism, Acts of God, terrorism war and civil unrest or other similar events of “force majeure”.<br />
                                This Agreement represents the entire agreement between you and Whissh and supersedes any previous marketing information, representations or agreements of any kind or nature between the parties relating to the subject matter hereof and whether recorded in writing, or otherwise.<br />
                                The parties agree that the terms of this Agreement are fair and reasonable in all the circumstances. However, if any provision of these terms is held not to be valid by a court of competent jurisdiction but would be valid if part of the wording were deleted, then such provision shall apply with such deletions as may be necessary to make it valid. If any of the provisions in these terms are held not to be valid the remaining provisions of these terms shall remain in full force and effect.<br />
                                Whissh may change any terms in this Agreement from time to time without your consent, provided that if such change would materially and adversely affect your rights or obligations as set out herein and as at the date of such change, there are still Services remaining to be performed, you may elect to terminate engagement or use of our Services, and the amount of fees and charges paid by you (if any) will be refunded to you (pro-rated, if applicable) to take into account any Services already rendered). Save as aforesaid, neither Whissh nor any Service Providers will be liable to you for any further amounts in respect of any such termination.<br />
                                If you breach the terms and conditions of this Agreement and Whissh takes no action or fails to take action on a timely basis, the foregoing shall not be deemed a waiver by Whissh of its rights under this Agreement and Whissh will be entitled to take action and enforce its rights and remedies for such breach.<br />
                                Notwithstanding that they are not party to this Agreement, the Service Providers and the Indemnified Parties are third party beneficiaries of this Agreement and may rely upon this Agreement and enforce this Agreement to the extent necessary to enforce their respective rights under this Agreement. Save as aforesaid, a person who is not a party to this Agreement has no rights under the Contracts (Rights of  Third Parties)  Rights) Act (Cap 53B) to enforce any term of this Agreement.<br />
                                This Agreement shall be governed by and construed in accordance with the laws of Singapore. You hereby irrevocably submit to the non-exclusive jurisdiction of Singapore, for any and all disputes between you and Whissh and/or the Service Providers arising out of this Agreement and/or the provision of Services.
                            </p>


                            <hr></hr>
                        </Col>
                    </Row> */}
                </Container>

            </div>
        </>
    );
}

export default TermsOfService;
