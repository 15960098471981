import React, { useEffect, useState, useContext } from "react";
// reactstrap components
// import { Link } from "react-router-dom";

import { Container, Row, Col } from "reactstrap";

function Soft404() {
    return (
        <>
            <div className="adjust-navbar">
            <Container>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="10">
                                <Row className="pb-15">
                                    <Col md="8" className="ml-auto mr-auto ">
                                        <div className="text-center">
                                            <img
                                                alt="..."
                                                src={require("../assets/img/icon-login.png")}
                                            ></img>
                                        </div>
                                                <div className="text-center">
                                                    <h2 className="big-heading p-5">Page Not Found.</h2>
                                                    {/* <b className="title-3 p-10">The page you </b><br /> */}
                                                </div>
                                                <hr className="hr-large"></hr>
                                    </Col>
                                </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Soft404;
