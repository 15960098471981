import React, { useEffect, useState, useContext } from "react";

// reactstrap components
import {
  // Card,
  Container,
  Row,
  Col
} from "reactstrap";

import { GetAPI } from "../../API/APICalling";
import { GlobalContext } from '../../App.js';


function EasyStepSection() {

  const globalContext = useContext(GlobalContext);
  const [data, setData] = useState('');
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    const FnGetCMSContent = async () => {
      globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
      var result = await GetAPI(`cms/howitworks`);
      if (result) {
        globalContext.loaderDispatch({ type: 'STOP_LOADING' });
        if (result.error) {
          //globalContext.notifications('error', result.error);
        }
        else if (result.data) {
          setData(result.data.content)
        }
      }
    }
    FnGetCMSContent();
  }, []);


  return (
    <>

      <div className="cd-section" id="EasyStep">
        <div dangerouslySetInnerHTML={{ __html: data.steps }}></div>
        <div dangerouslySetInnerHTML={{ __html: data.image }}></div>
      </div>

      {/* <div className="cd-section" id="EasyStep">
        <div className="EasyStep-3">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <div className="big-heading">How It Works In 3 Easy Steps!</div>
                <hr className="hr-large"></hr>
                <p className="description">
                  A Seamless process for on-demand home service
                </p>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div className="info info-hover">
                  <div className="icon icon-success icon-circle">
                    <img
                      alt="1"
                      src={require("../../assets/img/icon-how-1.png")}
                    ></img>
                  </div>
                  <div className="details">
                    <div className="title">Book easily online</div>
                    <p className="description">
                      Book online with instatnt confirmation & we'll match you with a trusted, experienced house cleaner.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info info-hover">
                  <div className="icon icon-primary icon-circle">
                    <img
                      alt="2"
                      src={require("../../assets/img/icon-how-2.png")}
                    ></img>
                  </div>
                  <div className="details">
                    <div className="title">Confirm and pay securly</div>
                    <p className="description">
                      Select date, time and pay securly online by credit card.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info info-hover">
                  <div className="icon icon-info icon-circle">
                    <img
                      alt="3"
                      src={require("../../assets/img/icon-how-3.png")}
                    ></img>
                  </div>
                  <div className="details">
                    <div className="title">Get a 5 star cleaner</div>
                    <p className="description">
                      Your trusted service crew will arrive at your home on time. Evry cleaner is friendly and reliable.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="EasyStep-section features-8 section-image">
          <img
            alt="..."
            className="rounded"
            src={require("../../assets/img/3easy.PNG")}
          ></img>
        </div>
      </div>
    
     */}
    </>
  );
}

export default EasyStepSection;
