import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { GetAPI } from "../../API/APICalling";
import { GlobalContext } from '../../App.js';
import { Link } from 'react-router-dom';

function AboutUs() {
  const globalContext = useContext(GlobalContext);
  const [data, setData] = useState('');

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    const FnGetCMSContent = async () => {
      globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
      var result = await GetAPI(`cms/aboutus`);
      if (result) {
        globalContext.loaderDispatch({ type: 'STOP_LOADING' });
        if (result.error) {
          globalContext.notifications('error', result.error);
        }
        else if (result.data) {
          setData(result.data.content)
        }
      }
    }
    FnGetCMSContent();
  }, []);

  return (
    <>
      <div className="adjust-navbar-breadcrumb">
        <div className="breadcrumb-outer">
          <Container className="breadcrumb-body">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/" tag={Link}>          
                  Home
                </Link>               
              </li>
              <li className="breadcrumb-item active">
                <i className="fa fa-angle-right pr-5" aria-hidden="true"></i>
                About
              </li>
            </ol>
          </Container>
        </div>
        <Container fluid>
          <div dangerouslySetInnerHTML={{ __html: data.about }}></div>
          {globalContext.loaderState.isLoading === false && (
            <div>
              <Row className="pt-30">
                <div className="contact-section text-center">
                  <div className="title col-md-8 ml-auto mr-auto">
                    <Col md="8" className="left">
                      If you're not happy, just let us know and
                      <br /> we'll make it right
                    </Col>
                    <Col md="4" className="right text-md-left">
                      <Link
                        to="/contact-us"
                        style={{
                          backgroundColor: "#ffffff",
                          color: "#000000",
                          fontSize: "16px",
                        }}
                        type="button"
                        className="btn-round btn btn-default"
                      >
                        <span className="phone-icon"></span>CONTACT US
                      </Link>
                    </Col>
                  </div>
                </div>
              </Row>
            </div>
          )}
          <div dangerouslySetInnerHTML={{ __html: data.values }}></div>
          <div dangerouslySetInnerHTML={{ __html: data.history }}></div>

          {/* <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <Row className="pt-15">
                <Col md="8" className="ml-auto mr-auto ">
                  <div className="text-center big-heading">
                    About Whissh
                  </div>
                  <hr className="hr-small"></hr>
                </Col>
              </Row>
              <Row className="about-us-heading">
                <Col md="6" sm="12" xs="12">
                  <img
                    alt="..."
                    className="pull-right"
                    src={require("../../assets/img/img-sample-about.jpg")}
                  ></img>
                </Col>
                <Col md="4" sm="12" xs="12">
                  <div className="text-left pt-20">
                    <h5 className="title-2">Who are we?</h5>
                    <p className="description">
                      Whissh is a one-stop home service provider. Backed by a global investment initiative, we strive to transform and improve the quality of home living.
                        <br /> <br />
                      Our wide array of services includes home cleaning, office cleaning, laundry, plumbing, aircon servicing, electrical maintenance and other related services.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Container>
                  <Row>
                    <Col md="10" className="ml-auto mr-auto">
                      <Row>
                        <Col md="6" className="pt-20 pb-10 ml-auto mr-auto text-left">
                          <div>
                            <h5 className="title-2">Our Vision</h5>
                          </div>
                          <div className="vision-mision">
                            <div className="left">
                              <img
                                alt="..."
                                src={require("../../assets/img/icon-vision.png")}
                              ></img>
                            </div>
                            <div className="right description">
                              Whissh strive to be recognized as the leading one-stop home service provider. We are constantly working to establish ourselves as the trusted brand in Singapore as well as in Asia. We seek to build a strong relationship with our clients, service crew, employees, and key stakeholders. We are an employer of choice, fostering a culture that values responsibility, professionalism, diligence, quality of work, and service-driven.
                            </div>
                          </div>

                        </Col>
                        <Col md="6" className="pt-20 pb-10 ml-auto mr-auto text-left">
                          <div>
                            <h5 className="title-2">Our Mission</h5>
                          </div>
                          <div className="vision-mision">
                            <div className="left">
                              <img
                                alt="..."
                                src={require("../../assets/img/icon-mission.png")}
                              ></img>
                            </div>
                            <div className="right description">
                              By working with selected collaborators and our well-trained service crews, Whissh is committed to providing the highest quality of home services so that our clients will have a peace of mind, and the leisure time to spend on what matters to them. We will constantly innovate ourselves and achieve the highest standards in our industry.
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </Row>
            </Col>
          </Row>
          <Row className="pt-30">
            <div className="contact-section text-center">
              <div className="title col-md-8 ml-auto mr-auto">
                <Col md="8" className="left">
                  If you're not happy, just let us know and,<br /> we'll make it right
              </Col>
                <Col md="4" className="right text-md-left">
                  <Button
                    style={{
                      backgroundColor: "#ffffff",
                      color: "#000000",
                      fontSize: "16px"
                    }}
                    type="button" className="btn-round btn btn-default">
                    <span className="phone-icon"></span>JUST CONTACT US
                </Button>
                </Col>
              </div>
            </div>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <Row className="pt-30">
                <Col md="8" className="ml-auto mr-auto ">
                  <div className="text-center big-heading">
                    Our Values
                  </div>
                  <hr className="hr-large"></hr>
                </Col>
              </Row>
              <Row className="p-20">
                <Col md="8" className="ml-auto mr-auto ">
                  <p className="description">
                    Our goal is you satisfaction. We want you to experience the best, while we do the rest.
                  </p>
                </Col>
              </Row>
              <Row className="our-values-heading">
                <Col md="6" sm="12" xs="12">
                  <img
                    alt="..."
                    className="pull-right"
                    src={require("../../assets/img/img-sample-vision.jpg")}
                  ></img>
                </Col>
                <Col md="6" sm="12" xs="12">
                  <div className="text-left pt-20">
                    <p className="description">
                      <b><span className="right-icon"></span>Professional:</b>We complete every task to the best of our ability.</p>
                    <p className="description">
                      <b><span className="right-icon"></span>Respect:</b>We understand and value each other’s perspectives, wherewithal and origins.</p>
                    <p className="description">
                      <b><span className="right-icon"></span>Diligence:</b>We are mindful in our work and attentive to details.</p>
                    <p className="description">
                      <b><span className="right-icon"></span>Quality of work:</b>We constantly seek to provide the highest quality of work to delight our clients.</p>
                    <p className="description">
                      <b><span className="right-icon"></span>Service-oriented:</b>We anticipate, recognise and exceed our clients’ needs.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <Row className="pt-30">
                <Col md="8" className="ml-auto mr-auto ">
                  <div className="text-center big-heading">
                    Our History
                  </div>
                  <hr className="hr-large"></hr>
                </Col>
              </Row>
              <Row>
                <Col md="12" className="ml-auto mr-auto ">
                  <h4 className="title-2">
                    A fast growing one-step provider of premier home services.
                  </h4>
                  <p className="description">
                    Coparishing property management, concierge, cleaning and
                    facelities including laundary, air-conditionig
                    and smart living solutions.
                  </p>
                  <hr></hr>
                  <h5 className="title-3">
                    Whissh was started in 2016 to embrace well-heeled and discerning individuals.
                  </h5>
                  <hr></hr>
                </Col>
              </Row>
              <Row className="our-values-heading">
                <Col md="4" sm="12" xs="12">
                  <div className="text-left">
                    <p className="description">
                      As a cleaning company, we provide house cleaning service
                      with best value and quality based on customer reviews.
                      Our house cleaning services include weekly flat clean,
                      end of tenancy cleaning / apartment move out cleaning services,
                      move in house cleaning, and other house deep cleaning services
                      and professional house cleaning services such as after-builders
                      clean and spring clean.
                      </p>
                  </div>
                </Col>
                <Col md="4" sm="12" xs="12">
                  <div className="text-left">
                    <p className="description">
                      Since our inception, we have erformed over 50,000 client jobs with deep reliability and high satisfaction that
                      our clients constantly enjoy. A one-stop provider of premier home services, our clients include owners
                      and tenants of super prime properties in Sentosa Cove of Singapore, which is among the world’s most
                      prestigious oceanfront properties. We have served expatriate families from
                      over 25 countries and counting.
                      </p>
                  </div>
                </Col>
                <Col md="4" sm="12" xs="12">
                  <div className="text-left">
                    <p className="description">
                      To serve sustainable living in gateway cities around the world, we are currently expanding
                      to London and Tokyo. We want to transform and improve the quality of home living through digitally
                      enabled services, enabling the hassle-free lifestyle of globally mobile persons
                      and value enhancement of build-to-rent properties.
                      </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        */}
        </Container>
      </div>
    </>
  );
}

export default AboutUs;
