import { api } from "../constant";
export const GetAPI = async (url) => {
    return api.get(url)
        .then(res => {
            const response = res.data;
            if (response != null) {
                if (response.meta.responseCode === 'S') {
                    return { data: response.data, error: '', success: '' }
                }
                else {
                    console.log(response.meta.message);
                    return { data: null, error: response.meta.message, success: '' }
                }
            }
            else {
                return { data: null, error: 'Oops ! Something went wrong.', success: '' }
            }
        })
        .catch(error => {
            console.log(error);
            return { data: null, error: 'Oops ! Something went wrong.', success: '' }
        });
};

export const PostAPI = async (url, object) => {
    return api.post(url, object)
        .then(res => {
            const response = res.data;
            if (response != null) {
                if (response.meta.responseCode === 'S') {
                    return { data: response.data, error: '', success: response.meta.message }
                }
                else {
                    console.log(response.meta.message);
                    return { data: null, error: response.meta.message, success: '' }
                }
            }   
            else {
                return { data: null, error: 'Oops ! Something went wrong.', success: '' }
            }
        })
        .catch(error => {
            console.log(error);
            return { data: null, error: 'Oops ! Something went wrong.', success: '' }
        });
};



export const DeleteAPI = async (url) => {
    return api.delete(url)
        .then(res => {
            const response = res.data;
            if (response != null) {
                if (response.meta.responseCode === 'S') {
                    return { data: response.data, error: '', success: response.meta.message }
                }
                else {
                    console.log(response.meta.message);
                    return { data: null, error: response.meta.message, success: '' }
                }
            }   
            else {
                return { data: null, error: 'Oops ! Something went wrong.', success: '' }
            }
        })
        .catch(error => {
            console.log(error);
            return { data: null, error: 'Oops ! Something went wrong.', success: '' }
        });
};