import { PUSH_PUBLIC_KEY } from "constant";
/**
 * checks if Push notification and service workers are supported by your browser
 */
function isPushNotificationSupported() {
  return "serviceWorker" in navigator && "PushManager" in window;
}

/**
 * asks user consent to receive push notifications and returns the response of the user, one of granted, default, denied
 */
async function askUserPermission() {
  return await Notification.requestPermission();
}
/**
 * shows a notification
 */
function sendNotification() {
  const img = "/images/jason-leung-HM6TMmevbZQ-unsplash.jpg";
  const text = "Take a look at this brand new t-shirt!";
  const title = "New Product Available";
  const options = {
    body: text,
    icon: "/images/jason-leung-HM6TMmevbZQ-unsplash.jpg",
    vibrate: [200, 100, 200],
    tag: "new-product",
    image: img,
    badge: "https://spyna.it/icons/android-icon-192x192.png",
    actions: [{ action: "Detail", title: "View", icon: "https://via.placeholder.com/128/ff0000" }]
  };
  navigator.serviceWorker.ready.then(function (serviceWorker) {
    serviceWorker.showNotification(title, options);
  });
}

/**
 *
 */
function registersw() {
  navigator.serviceWorker.register("sw.js?v=1")
    .then(function (swReg) {
      // console.log('Service Worker is registered', swReg);
      console.log('Push notification service Worker is registered');
      //swRegistration = swReg;
      //initializeUI();
      swReg.addEventListener('updatefound', () => {
        console.log("worker stage changed")
        const newworker = swReg.installing;
        console.log(newworker.state);
        //alert(newworker.state);
        newworker.addEventListener('statechange', () => {
          console.log(newworker.state);
          //alert(newworker.state);
        });
      })
    })
    .catch(function (error) {
      console.error('Service Worker Error', error);
    });
}

/**
 *
 * using the registered service worker creates a push notification subscription and returns it
 *
 */
async function createNotificationSubscription() {
  //wait for service worker installation to be ready
  const serviceWorker = await navigator.serviceWorker.ready;
  // subscribe and return the subscription
  return await serviceWorker.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: PUSH_PUBLIC_KEY
  });
}

/**
 * returns the subscription if present or nothing
 */
function getUserSubscription() {
  //wait for service worker installation to be ready, and then
  return navigator.serviceWorker.ready
    .then(function (serviceWorker) {
      return serviceWorker.pushManager.getSubscription();
    })
    .then(function (pushSubscription) {
      return pushSubscription;
    });
}


const getExixtingSubscription = async () => {
  const existingSubscription = await getUserSubscription();
  if (existingSubscription === null) {
    createNotificationSubscription();
  }
};

function registerNotificationWorker() {
  if (isPushNotificationSupported()) {
    console.log('Service Worker and Push is supported');
    registersw();
    if (Notification.permission != "granted") {
      askUserPermission().then(consent => {
        if (consent !== "granted") {
          console.log("You denied the consent to receive notifications");
        }
        else {
          createNotificationSubscription();
        }
      });
    }
    else
      getExixtingSubscription();
  } else {
    console.warn('Push messaging is not supported');
  }
}


export {
  isPushNotificationSupported,
  askUserPermission,
  registersw,
  sendNotification,
  createNotificationSubscription,
  getUserSubscription,
  registerNotificationWorker
};