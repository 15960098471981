import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col, Button, Modal } from "reactstrap";
import MyAccountHeader from "../../components/MyAccount/MyAccountHeader";
import { GlobalContext } from '../../App';
import { getData } from "../../utils/localStorage";
import { GetAPI } from "../../API/APICalling";
import { DateFormat } from "../../utils/common";
import TopUpModal from "../../components/MyAccount/TopUpModal";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLIC_KEY } from "../../constant";
// import { SubmitButton, ErrorMessage } from 'components/CheckOutPayment/CheckoutProperty';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
//loadStripe.setLoadParameters({ advancedFraudSignals: false })
//const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

export default function MyCredits() {
    const [modal, setModal] = useState(false);
    const globalContext = useContext(GlobalContext);
    const [creditList, setCreditList] = useState([]);
    const [creditSumamry, setCreditSumamry] = useState([]);
    const history = useHistory();
    const [stripePromise, setstripePromise] = useState(null);

    useEffect(() => {
        setstripePromise(loadStripe(STRIPE_PUBLIC_KEY));
        FnGetCreditSummary();
        FnGetCreditList();
    }, []);

    const FnGetCreditList = async () => {
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }
        else {
            globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
            var result = await GetAPI(`mycredit/${session.arnum}`);
            if (result) {
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                if (result.error) {
                    globalContext.notifications('error', result.error);
                }
                else if (result.data) {
                    setCreditList(result.data)
                }
            }
        }
    };

    const FnGetCreditSummary = async () => {
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }
        else {
            globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
            var result = await GetAPI(`mycredit/summary/${session.arnum}`);
            if (result) {
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                if (result.error) {
                    globalContext.notifications('error', result.error);
                }
                else if (result.data) {
                    setCreditSumamry(result.data)
                }
            }
        }

    }
    return (
        <>
            <div className="adjust-navbar-breadcrumb">
                <div className="breadcrumb-outer">
                    <Container className="breadcrumb-body">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link
                                    to="/">
                                    Home
                                    </Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link
                                    to="/my-account/my-profile">
                                    <i className="fa fa-angle-right pr-5" aria-hidden="true"></i>
                                    My Account
                                    </Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <i className="fa fa-angle-right pr-5" aria-hidden="true"></i>
                                My Credits
                            </li>
                        </ol>
                    </Container>
                </div>
                <div className="myaccount-header">
                    <MyAccountHeader />
                </div>
                <Container fluid id="myAccount">
                    <Row>
                        <Col className="ml-auto mr-auto text-left" xl="10" lg="12" md="12">
                            <Row className="pt-30">
                                <Col xl="10" lg="12" md="12" className="ml-auto mr-auto">
                                    <h2 className="big-heading">My Credits</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl="10" lg="12" md="12" className="ml-auto mr-auto">
                                    <Row className="mt-15">
                                        <Col md="12" className="ml-auto mr-auto">
                                            {Object.entries(creditSumamry).length > 0 &&
                                                <div className="card" style={{ backgroundColor: "#f2f2f2a1" }}>
                                                    <div className="card-body">
                                                        <Row>
                                                            <Col lg="3" md="12" sm="12" xs="12">
                                                                <div className="pt-5 pb-5">
                                                                    <div
                                                                        style={{ float: "left" }}
                                                                    >
                                                                        <img
                                                                            alt="..."
                                                                            src={require("../../assets/img/icon-sm-blue-credits.png")}
                                                                        ></img>
                                                                    </div>
                                                                    <div>
                                                                        Available Credits:<br />
                                                                        <span className="title-1">{parseFloat(creditSumamry.total).toFixed(2)}</span>
                                                                    </div>
                                                                    <div>
                                                                        <Button className="btn btn-round btn-info"
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                setModal(true);
                                                                            }}
                                                                        >
                                                                            <i className="now-ui-icons shopping_cart-simple pr-10"></i>
                                                                        TOP-UP CREDITS</Button>

                                                                    </div>

                                                                </div>
                                                            </Col>


                                                            <Col lg="4" md="12" sm="12" xs="12">
                                                                <Row>
                                                                    <Col xs="6" sm="6" md="6">Expiring Credits:</Col>
                                                                    <Col xs="6" sm="6" md="6">Expiry Date:</Col>
                                                                </Row>
                                                                {creditSumamry.expiryList.length > 0 &&

                                                                    creditSumamry.expiryList.map((item, index) => (
                                                                        <Row key={index}>
                                                                            <Col xs="6" sm="6" md="6"> <span className="credit-data">{parseFloat(item.qty).toFixed(2)}</span></Col>
                                                                            <Col xs="6" sm="6" md="6"><span className="credit-data">{DateFormat(item.date, 'DD-MMM-YYYY')}</span></Col>
                                                                        </Row>
                                                                    ))


                                                                }


                                                                {/* <div className="pt-5 pb-5">
                                                                    Expiring Credits:<br />
                                                                    <span className="credit-data">2,500</span> <br />
                                                                    <span className="credit-data">1,000</span>
                                                                </div>
                                                                <div className="pt-5 pb-5">
                                                                    Expiry Date:<br />
                                                                    <span className="credit-data"> 31/06/2020</span> <br />
                                                                    <span className="credit-data">31/12/2020</span>
                                                                </div> */}
                                                            </Col>

                                                            <Col lg="2" md="6" sm="6" xs="6">
                                                                <div className="pt-5 pb-5">
                                                                    Total Used:<br />
                                                                    <span className="credit-data">{parseFloat(creditSumamry.used).toFixed(2)}</span>
                                                                </div>
                                                            </Col>

                                                            <Col lg="3" md="6" sm="6" xs="6">
                                                                <div className="pt-5 pb-5">
                                                                    Total Acquired:<br />
                                                                    <span className="credit-data">{parseFloat(creditSumamry.acquired).toFixed(2)}</span>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            }
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="table-responsive table-list-view" id="myCreditsTable">
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-center">Ticket No.</th>
                                                                    <th className="text-left">Service Type</th>
                                                                    <th className="text-center">Transaction Date</th>
                                                                    <th className="text-left">Credit Used(-)/Acquired(+)</th>
                                                                    <th className="text-center">Expiry Date</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {creditList &&
                                                                    <React.Fragment>
                                                                        {
                                                                            creditList.length < 1 ?
                                                                                <tr>
                                                                                    <td colSpan="5" className="text-center empty-screen">No Records Found</td></tr>
                                                                                :
                                                                                <React.Fragment>
                                                                                    {
                                                                                        creditList.map((item, index) => (
                                                                                            <tr key={index}>
                                                                                                <td className="text-center">{item.ticket}</td>
                                                                                                <td className="text-left">{item.trantype}</td>
                                                                                                <td className="text-center">{DateFormat(item.trandate, 'DD-MMM-YYYY')}</td>
                                                                                                <td className={item.qty > 0 ? "text-center confirmed" : "text-center cancelled"}>{item.qty}</td>
                                                                                                <td className="text-center">{DateFormat(item.expirydate, 'DD-MMM-YYYY')}</td>
                                                                                            </tr>
                                                                                        ))
                                                                                    }
                                                                                </React.Fragment>
                                                                        }
                                                                    </React.Fragment>
                                                                }
                                                                {/* <tr>
                                                                    <td className="text-center"> 0123456789</td>
                                                                    <td className="text-left">Weekly cleaning</td>
                                                                    <td className="text-left">01/02/2020</td>
                                                                    <td className="text-left cancelled">500</td>
                                                                    <td className="text-left">1000</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-center"> 0123456789</td>
                                                                    <td className="text-left">Weekly cleaning</td>
                                                                    <td className="text-left">01/02/2020</td>
                                                                    <td className="text-left cancelled">500</td>
                                                                    <td className="text-left">1000</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-center"> 0123456789</td>
                                                                    <td className="text-left">Weekly cleaning</td>
                                                                    <td className="text-left">01/02/2020</td>
                                                                    <td className="text-left cancelled">500</td>
                                                                    <td className="text-left">1000</td>
                                                                </tr> */}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>

            {modal ? (
                <Modal size="md" isOpen={modal}
                    className="transparent-modal"
                >
                    <Elements stripe={stripePromise}>
                        <TopUpModal Function={{ setModal: setModal, CallBackFunction1: FnGetCreditSummary, CallBackFunction2: FnGetCreditList }} />
                    </Elements>
                </Modal>
            ) : null
            }
        </>
    );
}
