
export const SignUpInitialState = {
    isLoading: false,
    postalCode: '',
    firstname: '',
    lastname: '',
    phoneNumber: '',
    email: '',
    password: '',
    confirmPassword: '',
    successMsg: '',
    title: ''
}

export const SignUpReducer = (state, action) => {
    switch (action.type) {
        case 'FIELD': {
            return {
                ...state,
                [action.field]: action.value
            }
        }
        case 'SIGNUP': {
            return {
                ...state,
                isLoading: true,
                postalCode: '',
                firstname: '',
                lastname: '',
                phoneNumber: '',
                email: '',
                password: '',
                confirmPassword: '',
                successMsg: '',
                title: ''
            }
        }
        case 'SUCCESS': {
            const sc = {
                ...state,
                isLoading: false,
                postalCode: '',
                firstname: '',
                lastname: '',
                phoneNumber: '',
                email: '',
                password: '',
                confirmPassword: '',
                successMsg: action.successMsg,
                title: ''
            };
            console.log(sc)
            return sc;
        }
        case 'ERROR': {
            return {
                ...state,
                // isLoading: false,
                // postalCode: '',
                // firstname: '',
                // lastname: '',
                // phoneNumber: '',
                // email: '',
                // password: '',
                // confirmPassword: '',
                // successMsg: ''
            }
        }
        default:
            return {
                ...state,
                isLoading: false,
                postalCode: '',
                firstname: '',
                lastname: '',
                phoneNumber: '',
                email: '',
                password: '',
                confirmPassword: '',
                successMsg: '',
                title: ''
            }
    }
    //return state;
}

export const LoginInitialState = {
    // isLoading: false,
    isLoggedIn: false,
    username: '',
    password: '',
    //userdata: {}
}

export const LoginReducer = (state, action) => {
    switch (action.type) {
        case 'FIELD': {
            return {
                ...state,
                [action.field]: action.value
            }
        }
        case 'LOGIN': {
            return {
                ...state,
                //isLoading: true,
                isLoggedIn: false,
                // username: '',
                // password: '',
                // userdata: {}
            }
        }
        case 'SUCCESS': {
            const sc = {
                ...state,
                //isLoading: false,
                isLoggedIn: true,
                username: '',
                password: '',
                //userdata: action.payload
            };
            console.log(sc)
            return sc;
        }
        case 'ERROR': {
            return {
                ...state,
                //isLoading: false,
                isLoggedIn: true,
                username: '',
                password: '',
                //userdata: {}
            }
        }
        default:
            return {
                ...state,
                isLoading: false,
                isLoggedIn: false,
                username: '',
                password: '',
                //userdata: {}
            }
    }
}


export const ForgotPassInitialState = {
    isLoading: false,
    otpSent: false,
    email: '',
    password: '',
    confirmPassword: '',
    otp: ''
}

export const ForgotPassReducer = (state, action) => {
    switch (action.type) {
        case 'FIELD': {
            return {
                ...state,
                [action.field]: action.value
            }
        }
        case 'START': {
            return {
                ...state,
                isLoading: true,
                otpSent: false,
                email: '',
                password: '',
                confirmPassword: '',
                otp: ''
            }
        }
        case 'VALIDATE_USER_SUCCESS': {
            const sc = {
                ...state,
                isLoading: false,
                otpSent: true,
                password: '',
                otp: action.otp,
                email: action.email,
            };
            //console.log(sc)
            return sc;
        }
        case 'UPDATE_PASS_START': {
            return {
                ...state,
                isLoading: true,
                otpSent: true
            }
        }
        case 'UPDATE_PASS_SUCCESS': {
            return {
                ...state,
                isLoading: false,
                otpSent: true,
                email: '',
                password: '',
                otp: ''
            };
        }
        case 'ERROR': {
            return {
                ...state,
                isLoading: false,
                otpSent: false,
                email: '',
                password: '',
                otp: ''
            }
        }
        default:
            return {
                ...state,
                isLoading: false,
                otpSent: false,
                email: '',
                password: '',
                otp: ''
            }
    }
}