import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Button
} from "reactstrap";
// core components
// import OtherCustomerServicePackage from "components/PackageList/OtherCustomerServicePackage.js"


function ThankYou() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <>
            <div id="PaymentSuccessfull" className="adjust-navbar">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="10">
                                <Row className="pb-15">
                                    <Col md="8" className="ml-auto mr-auto ">
                                        <div className="text-center">
                                            <img
                                                alt="..."
                                                src={require("../assets/img/icon-login.png")}
                                            ></img>
                                        </div>
                                                <div className="text-center">
                                                    <h2 className="big-heading p-5">Thank You!</h2>
                                                    <b className="title-3 p-10">We will contact you soon.</b><br />
                                                </div>
                                                <hr className="hr-large"></hr>
                                    </Col>
                                </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default ThankYou;
