import React, { Component, useEffect } from 'react';
import { getData, storeData } from './localStorage';
import { GOOGLE_TRANSLATION, LANGUAGE } from '../constant';

export default React.memo(function GoogleTranslate() {
  console.log('GoogleTranslate')
  //console.log(props)
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: LANGUAGE.default,
        includedLanguages: getData('language'),
        autoDisplay: false,
      },
      "google_translate_element"
    )
  };

  useEffect(() => {
    window.googleTranslateElementInit = googleTranslateElementInit;
    setTimeout(() => {
      var addScript = document.createElement('script');
      addScript.async = true;
      addScript.setAttribute('src', GOOGLE_TRANSLATION);
      document.body.appendChild(addScript);
    }, 1000);
  }, []);

  return (
    // <script type='text/javascript' src='//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit' />
    <div id="google_translate_element" className="google-hide"></div>
  );
});

