import React, { useContext } from "react";
import { Link } from 'react-router-dom';
import { Row } from "reactstrap"
import Carousel from 'react-multi-carousel';
import { GlobalContext } from '../../App.js';
function TrustedbyPro() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const images = [
    {
      alt: 'partner',
      imagefile: 'https://ukwhisshcontent.s3.eu-west-2.amazonaws.com/Capstone-logo.jpeg'
    },
    {
      alt: 'partner',
      imagefile: 'https://ukwhisshcontent.s3.eu-west-2.amazonaws.com/Hylax-logo.jpg'
    },
    {
      alt: 'partner',
      imagefile: 'https://ukwhisshcontent.s3.eu-west-2.amazonaws.com/NEC-logo.png'
    },
    {
      alt: 'partner',
      imagefile: 'https://ukwhisshcontent.s3.eu-west-2.amazonaws.com/Percy-logo.jpeg'
    },
    {
      alt: 'partner',
      imagefile: 'https://ukwhisshcontent.s3.eu-west-2.amazonaws.com/Rasa-logo.jpeg'
    },
    {
      alt: 'partner',
      imagefile: 'https://ukwhisshcontent.s3.eu-west-2.amazonaws.com/Sylvia-logo.jpeg'
    },
    {
      alt: 'partner',
      imagefile: 'https://ukwhisshcontent.s3.eu-west-2.amazonaws.com/Thirdman-logo.jpeg'
    },
    {
      alt: 'partner',
      imagefile: 'https://ukwhisshcontent.s3.eu-west-2.amazonaws.com/UC-logo.jpeg'
    }
  ]
  const globalContext = useContext(GlobalContext);
  return (
    <>
      <div className="cd-section" id="customer">
        <div>
          <div class="container">
            <div class="row">
              <div class="ml-auto mr-auto text-center">
                <div class="big-heading">Trusted by Professionals in</div>
                <hr class="hr-large"></hr>

              </div>
            </div>
            <Row>
              <div className="ml-auto mr-auto"
                style={{ width: "100%" }}>
                <Carousel
                  swipeable={true}
                  draggable={false}
                  showDots={false}
                  renderDotsOutside={true}
                  responsive={responsive}
                  ssr={true} // means to render carousel on server-side.
                  infinite={false}
                  arrows={true}
                  autoPlay={false}
                  autoPlaySpeed={1000}
                  keyBoardControl={false}
                  customTransition="transform .3s ease-in-out"
                  transitionDuration={500}
                  containerClass="carousel-container"
                  // removeArrowOnDeviceType={["mobile"]}
                  //  deviceType={"desktop"}
                  dotListClass="custom-dot-list-style"
                // itemClass="carousel-item-padding-40-px"
                >
                  {images.map((item, index) => (
                    <div key={index} className="overlay-container">
                      <img
                        alt={item.alt}
                        src={item.imagefile}
                      ></img>
                    </div>))}
                </Carousel>
              </div>
            </Row>
          </div></div>
        <br></br>
        <br></br>

      </div>
    </>
  );
}

export default TrustedbyPro;
