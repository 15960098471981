import React, { useEffect, useState, useContext } from "react";
// reactstrap components
import { Container, Button, Row, Col } from "reactstrap";
//https://www.npmjs.com/package/react-multi-carousel

import Carousel from 'react-multi-carousel';
import '../../assets/css/react-multi-carousel.css';
import { Link } from "react-router-dom";
import { GetAPI } from "../../API/APICalling";
import { GlobalContext } from '../../App.js';
import { ReadRate } from "../../utils/common";
import { useHistory } from 'react-router-dom';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const items = [
  {
    title: 'General Ceaning',
    amount: '30',
    desc: 'One Time'
  }, {
    title: 'Recurring Ceaning',
    amount: '23',
    desc: 'Weekly'
  }, {
    title: 'General Ceaning',
    amount: '35',
    desc: 'Spring Ceaning'
  }, {
    title: 'Deep Ceaning',
    amount: '45',
    desc: 'Move in/ Out Cleaning'
  }, {
    title: 'General Ceaning',
    amount: '30',
    desc: 'One Time'
  }, {
    title: 'General Ceaning',
    amount: '35',
    desc: 'Spring Ceaning'
  }, {
    title: 'General Ceaning',
    amount: '35',
    desc: 'Spring Ceaning'
  },
];

function RateCard() {
  const globalContext = useContext(GlobalContext);
  const [rateItems, setRateItems] = useState('');
  const [header, setHeader] = useState('');
  const history = useHistory();
  const onSubmit = async (pathname) => {
    console.log(pathname);
    history.push(pathname);
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    const FnGetCMSContent = async () => {
      globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
      var result = await GetAPI(`cms/rate`);
      if (result) {
        globalContext.loaderDispatch({ type: 'STOP_LOADING' });
        if (result.error) {
          globalContext.notifications('error', result.error);
        }
        else if (result.data) {
          setHeader(result.data.content.header);
          setRateItems(result.data.content.list);
          // setRateItems(result.data.content.list.map((item) => (
          //   <div key={item.toString()}>
          //     <div className="other-services-card text-center">
          //       <div className="card-header text-center title-3">
          //         {item.name}
          //       </div>
          //       <p className="m-0 p-20">
          //         {ReadRate(item.price).beforerate}<br></br>
          //         <span className="dollor">£</span>
          //         <span className="price">{ReadRate(item.price).rate}</span><br></br>
          //         {ReadRate(item.price).afterrate}
          //       </p>
          //       <hr></hr>
          //       <p>
          //         {item.freq}
          //       </p>
          //       <hr className="mb-0"></hr>
          //       <Button type="button" className="btn-round btn btn-info"
          //         onClick={e => {
          //           e.preventDefault();
          //           globalContext.headerDispatch({ type: "SUB_MENU", title: item.subsvcnum, subsvcnum: item.subsvcnum });
          //         }}>
          //         <span className="bookNow">Book Now</span>
          //       </Button>
          //     </div>
          //   </div>
          // )));
        }
      }
    }
    FnGetCMSContent();
  }, []);




  return (
    <>
      <div className="adjust-navbar-breadcrumb">
        <div className="breadcrumb-outer">
          <Container className="breadcrumb-body">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/" tag={Link}>
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active">
                {" "}
                <i className="fa fa-angle-right pr-5" aria-hidden="true"></i>
                Rates
              </li>
            </ol>
          </Container>
        </div>
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-left" md="10">
              {/* <div>
                <h2 className="text-center big-heading">
                  Let Us Do The Work For You
                </h2>
              </div>
              <hr className="hr-large"></hr>
              <div>
                <p className="title-2 text-center">Our Rates</p>
              </div>
              <div>
                <p className="description text-center pb-30">
                  *Rates may vary, subject to the size of your home, number of
                  hours and number of service crew required.
                </p>
              </div> */}

              <div dangerouslySetInnerHTML={{ __html: header }}></div>
              {rateItems && rateItems.length > 0 &&
                rateItems.map((item, index) => (
                  <React.Fragment key={index}>
                    <p className="title-2 text-center">{item.title}</p>
                    {item.content && item.content.length &&

                      <div key={index} className="ml-auto mr-auto" style={{ width: "100%" }}>
                        <Carousel
                          swipeable={true}
                          draggable={true}
                          showDots={false}
                          responsive={responsive}
                          ssr={true} // means to render carousel on server-side.
                          infinite={false}
                          arrows={true}
                          autoPlay={false}
                          autoPlaySpeed={1000}
                          keyBoardControl={true}
                          customTransition="all .5"
                          transitionDuration={500}
                          containerClass="carousel-container"
                          // removeArrowOnDeviceType={["tablet"]}
                          // deviceType={"mobile"}
                          dotListClass="custom-dot-list-style"
                        // itemClass="carousel-item-padding-40-px"
                        >
                          {
                            item.content.map((item2, index2) => (
                              <div key={item.toString()}>
                                <div className="other-services-card text-center">
                                  <div className="card-header text-center title-3" style={{ minHeight: "75px" }}>
                                    {item2.name}
                                  </div>

                                  <p className="m-0 p-20">
                                    {/* From <br></br>
                  <span className="dollor">£</span>
                  <span className="price">30</span><br></br>
                            Per Hour */}
                                    {ReadRate(item2.price).beforerate}<br></br>
                                    <span className="dollor">£</span>
                                    <span className="price">{ReadRate(item2.price).rate}</span><br></br>
                                    {ReadRate(item2.price).afterrate}
                                  </p>
                                  {/* <hr></hr>
                                <p>
                                  {item2.freq}
                                  </p>*/}
                                <hr className="mb-0"></hr>
                                <Button type="button" className="btn-round btn btn-info"
                                  onClick={e => {
                                    e.preventDefault();
                                    onSubmit(item2.btnlink);
                                  }}>
                                  <span className="bookNow">Book Now</span>
                                </Button>
                                </div>
                              </div>
                            ))
                          }
                        </Carousel>
                      </div>
                    }
                  </React.Fragment>
                ))
              }

            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default RateCard;
