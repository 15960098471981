import React, { useContext, useReducer, useEffect, useState } from 'react';
import { Button, ModalBody, ModalHeader, Form, Row, Col } from 'reactstrap';
import ReCAPTCHA from "react-recaptcha";
// import API from 'utils/api';
import { GlobalContext } from '../../App.js';
// import Loader from './Loader';
import { SignUpInitialState, SignUpReducer } from '../../reducer/LoginReducer';
import SocialLogin from './SocialLogin';
import { ValidateEmail } from '../../utils/common';
import { PostAPI } from '../../API/APICalling';
import { GOOGLE_RECAPTCHA_SITE_KEY, ENABLELOGIN } from "../../constant";

function ModalSignUp(props) {
    const globalContext = useContext(GlobalContext);

    const [signUpState, signUpDispatch] = useReducer(SignUpReducer, SignUpInitialState)
    const { isLoading, postalCode, firstname, lastname, phoneNumber, email, password, confirmPassword, successMsg, title } = signUpState;
    const [captchaVerified, setCaptchaVerified] = useState(false);

    useEffect(() => {
        document.querySelector('.modal').scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        signUpDispatch({ type: 'FIELD', field: 'title', value: "Mr" });
    }, []);

    // specifying your onload callback function
    const reCaptchLoaded = async => {
        console.log('Done!!!!');
    };

    // specifying verify callback function
    const verifyCallback = async (response) => {
        if (response)
            setCaptchaVerified(true);
    };

    const reCaptchExpired = async (response) => {
        setCaptchaVerified(false);
    };
    const FnSignUp = async () => {

        if (!title) {
            globalContext.notifications('error', 'Salutation is required.');
            return false
        }
        if (!postalCode) {
            globalContext.notifications('error', 'Postal code is required.');
            return false
        }
        if (!firstname) {
            globalContext.notifications('error', 'First name is required.');
            return false
        }
        if (!lastname) {
            globalContext.notifications('error', 'Last name is required.');
            return false
        }
        if (!phoneNumber) {
            globalContext.notifications('error', 'Phone number is required.');
            return false
        }
        if (!email) {
            globalContext.notifications('error', 'Email-Id is required.');
            return false
        }
        if (!ValidateEmail(email)) {
            globalContext.notifications('error', 'You have entered an invalid email address.');
            return false
        }

        if (!password) {
            globalContext.notifications('error', 'Password is required.');
            return false
        }

        if (password !== confirmPassword) {
            globalContext.notifications('error', 'Password and confirm password must be same.');
            return false
        }

        if (!captchaVerified) {
            globalContext.notifications('error', 'Please verify captcha.');
            return false;
        }
        // if (!email || !lastname || !firstname || !postalCode || !phoneNumber || !confirmPassword) {
        //     globalContext.notifications('error', 'Please fill all the details.');
        //     return false
        // }

        // signUpDispatch({ type: 'SIGNUP' });
        globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
        var result = await PostAPI(`user/basicsignup`,
            {
                email: email,
                lastname: lastname,
                firstname: firstname,
                postalcode: postalCode,
                mobile: phoneNumber,
                encryptedPWD: confirmPassword,
                title: title
            }
        );
        if (result) {
            globalContext.loaderDispatch({ type: 'STOP_LOADING' });
            if (result.error) {
                signUpDispatch({ type: 'ERROR' });
                globalContext.notifications('error', result.error);
            }
            else {
                signUpDispatch({ type: 'SUCCESS', successMsg: 'We\'ve sent a verification link to your email address. Please verify your email address.' });
                globalContext.notifications('success', 'Registration successfully completed.');
            }
        }


        // API.post(`user/basicsignup`,
        //     {
        //         email: email,
        //         lastname: lastname,
        //         firstname: firstname,
        //         postalcode: postalCode,
        //         mobile: phoneNumber,
        //         encryptedPWD: confirmPassword
        //     }
        // ).then(res => {
        //     const response = res.data;
        //     if (response != null) {
        //         if (response.meta.responseCode === 'S') {
        //             signUpDispatch({ type: 'SUCCESS', successMsg: 'We sent you one email verification link. Please verify your email address.' });
        //             globalContext.notifications('success', 'Registration successfully completed.');
        //         }
        //         else {
        //             signUpDispatch({ type: 'ERROR' });
        //             globalContext.notifications('error', response.meta.message);
        //         }
        //     }
        //     else {
        //         signUpDispatch({ type: 'ERROR' });
        //         globalContext.notifications('error', 'Oops ! Something went wrong.');
        //     }
        // }).catch(error => {
        //     signUpDispatch({ type: 'ERROR' });
        //     globalContext.notifications('error', 'Oops ! Something went wrong.');
        //     console.log(error);
        // });
    };

    return (
        <>
            {/* {isLoading ? <Loader /> : null} */}
            <div className="login-modal">
                <ModalHeader toggle={() => globalContext.headerDispatch({ type: 'CLOSE' })}>
                    <div className="p-5 text-left">
                        <b className="title-2">Service is available at your location</b>
                    </div>
                </ModalHeader>
                <hr style={{ width: "100%" }} className="mt-0"></hr>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md="12">
                                <b className="title-3">Fill up the other details.</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <div className="form-group">
                                    <select className="form-control"
                                        value={title || ''}
                                        onChange={e => {
                                            signUpDispatch({
                                                type: 'FIELD',
                                                field: 'title',
                                                value: e.currentTarget.value,
                                            })
                                        }}>
                                        <option value="Mr">Mr </option>
                                        <option value="Mrs">Mrs</option>
                                        <option value="Ms">Ms</option>
                                        <option value="Mdm">Mdm</option>
                                        <option value="Dr">Dr</option>
                                    </select>
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Postal Code*"
                                        maxLength={10}
                                        value={postalCode}
                                        onChange={e =>
                                            signUpDispatch({
                                                type: 'FIELD',
                                                field: 'postalCode',
                                                value: e.currentTarget.value,
                                            })
                                        } />
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="6">
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="First Name*"
                                        maxLength={20}
                                        value={firstname}
                                        onChange={e =>
                                            signUpDispatch({
                                                type: 'FIELD',
                                                field: 'firstname',
                                                value: e.currentTarget.value,
                                            })
                                        }
                                    />
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Last Name*"
                                        maxLength={20}
                                        value={lastname}
                                        onChange={e =>
                                            signUpDispatch({
                                                type: 'FIELD',
                                                field: 'lastname',
                                                value: e.currentTarget.value,
                                            })
                                        }
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="6">
                                <div className="form-group">
                                    <input type="number" className="form-control" placeholder="Phone number*"
                                        maxLength={15}
                                        value={phoneNumber}
                                        onChange={e =>
                                            signUpDispatch({
                                                type: 'FIELD',
                                                field: 'phoneNumber',
                                                value: e.currentTarget.value,
                                            })
                                        }
                                    />
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="E-mail*"
                                        maxLength={50}
                                        value={email}
                                        onChange={e =>
                                            signUpDispatch({
                                                type: 'FIELD',
                                                field: 'email',
                                                value: e.currentTarget.value,
                                            })
                                        } />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <div className="form-group">
                                    <input type="password" className="form-control" placeholder="Password*"
                                        maxLength={20}
                                        value={password}
                                        onChange={e =>
                                            signUpDispatch({
                                                type: 'FIELD',
                                                field: 'password',
                                                value: e.currentTarget.value,
                                            })
                                        } />
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="form-group">
                                    <input type="password" className="form-control" placeholder="Confirm Password*"
                                        maxLength={20}
                                        value={confirmPassword}
                                        onChange={e =>
                                            signUpDispatch({
                                                type: 'FIELD',
                                                field: 'confirmPassword',
                                                value: e.currentTarget.value,
                                            })
                                        } />
                                </div>
                            </Col>
                        </Row>

                        <hr></hr>
                        <div className="form-group text-center">
                            <p className="text-center description">By signing up you have agreed to our Terms and Conditions and you have read our Privacy Policy.</p>
                        </div>
                        <hr></hr>
                        <div className="form-group text-center">
                            <p className="text-center description">We will send a verification link to your email address after you submit.</p>
                        </div>
                        {successMsg && (
                            <div className="form-group text-center title-2">
                                <p className="btn btn-link btn-success">{successMsg}</p>
                            </div>)
                        }
                        <ReCAPTCHA
                            elementID="#signup-recaptcha"
                            sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
                            render="explicit"
                            verifyCallback={verifyCallback}
                            onloadCallback={reCaptchLoaded}
                            expiredCallback={reCaptchExpired}
                        />
                        <div className="form-group text-center">
                            {/* <Button type="button" className="btn-round btn btn-info" onClick={() => globalContext.headerDispatch({ type: 'CHOOSELOCATION_NEWUSER' })}>
                                SIGN UP</Button> */}

                            <Button disabled={successMsg ? true : false} type="button" className="btn-round btn btn-info" onClick={() => FnSignUp()}>
                                SIGN UP
                            </Button>

                            <Button type="button" className="btn-round btn btn-default"
                                onClick={() => globalContext.headerDispatch({ type: 'CLOSE' })}
                            >
                                CANCEL</Button>

                        </div>
                        {(ENABLELOGIN.facebook || ENABLELOGIN.google) &&
                            <React.Fragment>
                                <p className="text-center">
                                    Or
                                        </p>
                                <Row>
                                    <Col md="6" className="ml-auto mr-auto ">
                                        <SocialLogin />
                                    </Col>
                                </Row>
                            </React.Fragment>
                        }
                    </Form>
                </ModalBody>
            </div>
        </>
    );

}

export default ModalSignUp;