import React from "react";
// reactstrap components
import { Row, Col } from "reactstrap";
import { useClearCache } from 'react-clear-cache';

function Footer() {
    const { isLatestVersion, emptyCacheStorage } = useClearCache({ duration: 10000, auto: false });
    return (
        <>
            {!isLatestVersion ? (
                console.log('update'),
                // <p style={{ backgroundColor: "#eee" }}>
                //     <a
                //         href="#"
                //         onClick={e => {
                //             e.preventDefault();
                //             emptyCacheStorage();
                //         }}
                //     >
                //         Update version
                // </a>
                // </p>
                <div className="version-footer">
                    <Row>
                        <Col md="12">
                            <div className="text-center">
                                <div>
                                    <i className="fas fa-info pr-10"></i>
                                    <span className="pr-10">
                                        Latest version available click to update
                                    </span>
                                    <a to="#" className="btn-round btn btn-default"
                                        onClick={e => {
                                            e.preventDefault();
                                            emptyCacheStorage();
                                        }}>
                                        Update version
                                </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            ) :
                console.log('not update')
            }
        </>
    );
}

export default Footer;
