import React, { useEffect, useState, useContext } from "react";
import { Container } from "reactstrap";
import BookNow from "../../components/Home/BookNow.js"
import { GetAPI } from "../../API/APICalling";
import { GlobalContext } from '../../App.js';
import { Link } from "react-router-dom";

function HowItWorks() {
  const globalContext = useContext(GlobalContext);
  const [data, setData] = useState('');
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    const FnGetCMSContent = async () => {
      globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
      var result = await GetAPI(`cms/howitworks`);
      if (result) {
        globalContext.loaderDispatch({ type: 'STOP_LOADING' });
        if (result.error) {
          globalContext.notifications('error', result.error);
        }
        else if (result.data) {
          setData(result.data.content)
        }
      }
    }
    FnGetCMSContent();
  }, []);

  return (
    <>
      <div className="adjust-navbar-breadcrumb">
        <div className="breadcrumb-outer">
          <Container className="breadcrumb-body">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/" tag={Link}>
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active">
                {" "}
                <i className="fa fa-angle-right pr-5" aria-hidden="true"></i>How
                It Works
              </li>
            </ol>
          </Container>
        </div>
        {!globalContext.loaderState.isLoading && (
          <React.Fragment>
            <div className="cd-section" id="EasyStep">
              <div dangerouslySetInnerHTML={{ __html: data.steps }}></div>
              <div dangerouslySetInnerHTML={{ __html: data.image }}></div>
            </div>
            <BookNow />
          </React.Fragment>
        )}
      </div>
    </>
  );
}

export default HowItWorks;
