import React, { useState, useContext, useEffect } from "react";

// reactstrap components
import {
  //Card,
  Container,
  Row,
  Col
} from "reactstrap";
import { GetAPI } from "../../API/APICalling";
import { GlobalContext } from '../../App.js';

// core components

export default function CostomerSection() {
  const globalContext = useContext(GlobalContext);
  const [data, setData] = useState({});

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    const FnGetCMSContent = async () => {
      globalContext.loaderDispatch({ type: 'START_LOADING', payload: false });
      var result = await GetAPI(`cms/home-body-2`);
      if (result) {
        globalContext.loaderDispatch({ type: 'STOP_LOADING' });
        if (result.error) {
          //globalContext.notifications('error', result.error);
        }
        else if (result.data) {
          setData(result.data.content)
        }
      }
    }
    FnGetCMSContent();
  }, []);

  return (
    <>
      {data &&
        <div className="cd-section" id="customer" dangerouslySetInnerHTML={{ __html: data.body }}></div>

        // <div className="cd-section" id="customer">
        //   <div
        //     style={{
        //       backgroundImage: "url(" + require("../../assets/img/bg-gray-cpg.jpg") + ")",
        //       padding: "1px 0 1px",
        //       backgroundRepeat: "round"
        //     }}
        //   >
        //     <Container>
        //       <Row>
        //         <div className="ml-auto mr-auto text-center"
        //           style={{ paddingBottom: "30px" }}>
        //           <div className="big-heading">Customer Protection Guarantee</div>
        //           <hr className="hr-large"></hr>
        //           <p className="description">
        //             Your Satisfection matters to us, so we take steps to ensure that your are happy with result. This is why you can have confidence in choosing us!
        //         </p>
        //         </div>
        //       </Row>
        //       <Row>
        //         <Col md="4">
        //           <div className="info info-hover text-md-right text-xs-left pb-10">
        //             <div>
        //               <p className="title-2">Quality Assurance</p>
        //               <p className="description">
        //                 Our review system ensure you won't be matched with the service crews you had previously poorly rated.
        //           </p>
        //             </div>
        //             <div>
        //               <p className="title-2">Highly Trained</p>
        //               <p className="description">
        //                 All service crews have gone through hours of training by certified industry experts.
        //           </p>
        //             </div>
        //           </div>
        //         </Col>
        //         <Col md="4">
        //           <div className="text-center">
        //             <img
        //               alt="2"
        //               src={require("../../assets/img/icon-thebest.png")}
        //               style={{ height: "150px" }}
        //             ></img>
        //           </div>
        //         </Col>
        //         <Col md="4">
        //           <div className="info info-hover text-md-left text-xs-left">
        //             <div>
        //               <p className="title-2">Insured up to $250,000</p>
        //               <p className="description">
        //                 For accidents,either property or accidents, either property or personal injuiry to the service crews.
        //           </p>
        //             </div>
        //             <div>
        //               <p className="title-2">100% Screening</p>
        //               <p className="description">
        //                 Background and health checks conducted on all service crews.
        //           </p>
        //             </div>
        //           </div>
        //         </Col>
        //       </Row>
        //     </Container>
        //   </div>
        // </div>


      }
    </>
  );
}

