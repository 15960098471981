import React from 'react';
// import { Redirect, Link} from "react-router-dom";
import { ModalBody, Row, Col, Container } from 'reactstrap';

function ModalProgress() {
    // const [modalProgress, setModalProgress] = React.useState(false);
    // const OpenProgressModal = (modal) => {
    //     console.log("OpenProgressModal called");
    //     setModalProgress(!modal);
    // }



    return (
        <div id="ModalProgress">
            <ModalBody className="pt-50" style={{ backgroundColor: "#D6E2EC" }}>
                <Container>
                    <Row>
                        <Col md="8" className="ml-auto mr-auto">
                            <img
                                alt="..."
                                src={require("../../assets/img/icon-loading-widget.gif")}
                            ></img>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="8" className="ml-auto mr-auto">
                            <div className="text-center">
                                <p className="title-3">We are finding the right service for you</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="8" className="ml-auto mr-auto">
                            <div className="text-center">
                                <p className="">Please wait....</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </ModalBody>
        </div >
    );
}

export default ModalProgress;