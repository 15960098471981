import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, } from "react-router-dom";
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import {
  registerNotificationWorker
} from "utils/push-notifications";
// ReactDOM.render(
//   <BrowserRouter>
//       <Switch>
//         {/* <Route path="/index" render={props => <Index {...props} />} /> */}
//         {/* <Route
//           path="/nucleo-icons"
//           render={props => <NucleoIcons {...props} />}
//         />
//         <Route
//           path="/landing-page"
//           render={props => <LandingPage {...props} />}
//         />
//         <Route
//           path="/profile-page"
//           render={props => <ProfilePage {...props} />}
//         /> 
//          <Route path="/login-page" render={props => <LoginPage {...props} />} />
//         */}
//         <Route exact path='/index' component={Index} />
//          <Route exact path='/nucleo-icons' component={NucleoIcons} />
//          <Route exact path='/landing-page' component={LandingPage} />
//          <Route exact path='/profile-page' component={ProfilePage} />
//          <Route exact path='/login-page' component={ProfilePage} />
//         <Redirect to="/index" />
//         <Redirect from="/" to="/index" />
//     </Switch>
//   </BrowserRouter>,
//   document.getElementById("root")
// );



const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  rootElement);

registerNotificationWorker();
//unregister();
registerServiceWorker();