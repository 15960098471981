import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, NavItem, NavLink, InputGroup, InputGroupAddon, Input } from "reactstrap";
import { GlobalContext } from '../../App.js';
import { ValidateEmail } from '../../utils/common';
import { PostAPI } from '../../API/APICalling';
import { SOCIALLINKS, APPLINKS } from '../../constant';

function FooterCorporate() {
    const globalContext = useContext(GlobalContext);
    const [email, setEmail] = useState("");
    const [data, setData] = useState({});

    const [general1, setGeneral1] = useState([]);
    const [general2, setGeneral2] = useState([]);
    const [service1, setService1] = useState([]);
    const [service2, setService2] = useState([]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        if (globalContext.commonState.footerdata) {
            setData(globalContext.commonState.footerdata);
            if (globalContext.commonState.footerdata.general && globalContext.commonState.footerdata.general.length > 0) {
                const arraysize = globalContext.commonState.footerdata.general.length || 0;
                setGeneral1(globalContext.commonState.footerdata.general.slice(0, Math.ceil(arraysize / 2)));
                setGeneral2(globalContext.commonState.footerdata.general.slice(Math.ceil(arraysize / 2), arraysize));
            }

            if (globalContext.commonState.footerdata.our_Services && globalContext.commonState.footerdata.our_Services.length > 0) {
                const arraysize = globalContext.commonState.footerdata.our_Services.length || 0;
                setService1(globalContext.commonState.footerdata.our_Services.slice(0, Math.ceil(arraysize / 2)));
                setService2(globalContext.commonState.footerdata.our_Services.slice(Math.ceil(arraysize / 2), arraysize));
            }

        }
    }, [globalContext.commonState.footerdata]);

    const FnSubmit = async (e) => {
        e.preventDefault();
        if (!email) {
            globalContext.notifications('error', 'Email address is required.');
            return false;
        }
        if (!ValidateEmail(email)) {
            globalContext.notifications('error', 'You have entered an invalid email address.');
            return false
        }
        else {
            globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
            var result = await PostAPI(`home/newsletter`, { email: email });
            if (result) {
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                if (result.error) {
                    globalContext.notifications('error', result.error);
                }
                else {
                    setEmail("");
                    globalContext.notifications('success', 'Thanks for being part of the community');
                }
            }
        }
    }

    return (
        <>
            <div className="separator" />
            {general1.length > 0 &&
                <footer className="footer corporate-gray footer-big">
                    <Container>
                        <Row className="ml-auto mr-auto" >

                            <Col xl="8" lg="8" md="12" className="pb-10">
                                <Row>
                                    <Col md="6">
                                        <div className="links">
                                            <b className="title-2">General</b>
                                            <Row>
                                                <Col xs="6" className="pr-0">
                                                    <ul className="stacked-links">
                                                        {
                                                            general1 && general1.length > 0 &&
                                                            general1.map((item, index) => (
                                                                <NavItem key={index}>
                                                                    <NavLink to={item.link} tag={Link}>
                                                                        {item.title}
                                                                    </NavLink>
                                                                </NavItem>
                                                            ))
                                                        }
                                                    </ul>
                                                </Col>
                                                <Col xs="6" className="pl-0">
                                                    <ul className="stacked-links">
                                                        {
                                                            general2 && general2.length > 0 &&
                                                            general2.map((item, index) => (
                                                                <NavItem key={index}>
                                                                    <NavLink to={item.link} tag={Link}>
                                                                        {item.title}
                                                                    </NavLink>
                                                                </NavItem>
                                                            ))
                                                        }
                                                    </ul>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="links">
                                            <b className="title-2">Find us here</b>
                                            {
                                                data.find_us_here &&

                                                <ul className="stacked-links">
                                                    <NavItem style={{
                                                        lineHeight: "17px"
                                                    }}>
                                                        <NavLink href="#" onClick={e => e.preventDefault()} className="footer-details">
                                                            <span className="location-icon left"></span>
                                                            <span className="right"> {data.find_us_here.addr}</span>
                                                            {/* <span
                                                            style={{
                                                                paddingLeft: "25px"
                                                            }}>
                                                            Singapore 408734
                                            </span> */}
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <a href={`tel:${data.find_us_here.phone}`} className="footer-details">
                                                            <span className="phone-icon left"></span>
                                                            <span className="right"> {data.find_us_here.phone}</span>
                                                        </a>
                                                    </NavItem>
                                                    <NavItem>
                                                        <a href={`mailto:${data.find_us_here.email}`} className="footer-details">
                                                            <span className="email-icon left"></span>
                                                            <span className="right"> {data.find_us_here.email}</span>
                                                        </a>
                                                    </NavItem>
                                                    <NavItem style={{
                                                        lineHeight: "17px"
                                                    }}>
                                                        <NavLink href="#" onClick={e => e.preventDefault()} className="footer-details">
                                                            <span className="time-icon left"></span>
                                                            <span className="right">{data.find_us_here.hours}</span><br />
                                                            {/* <span
                                                            style={{
                                                                paddingLeft: "25px"
                                                            }}
                                                        >Daily</span> */}
                                                        </NavLink>
                                                    </NavItem>
                                                </ul>
                                            }
                                        </div>
                                        <a href="https://uk.trustpilot.com/review/whissh.co.uk"><img src="https://i.pinimg.com/originals/fd/53/5d/fd535d38b57db4189c1664049d8c5320.png" width="100" height="50"></img></a>
                                    </Col>
                                    <Col md="6"> <div className="links">
                                        <b className="title-2">Our Services</b>
                                        <Row>
                                            <Col xs="6">
                                                <ul className="stacked-links">

                                                    {
                                                        service1 && service1.length > 0 &&
                                                        service1.map((item, index) => (
                                                            <NavItem key={index}>
                                                                <NavLink to={item.link} tag={Link}>
                                                                    {item.title}
                                                                </NavLink>
                                                            </NavItem>
                                                        ))
                                                    }
                                                </ul>
                                            </Col>
                                            <Col xs="6">
                                                <ul className="stacked-links">
                                                    {
                                                        service2 && service2.length > 0 &&
                                                        service2.map((item, index) => (
                                                            <NavItem key={index}>
                                                                <NavLink to={item.link} tag={Link}>
                                                                    {item.title}
                                                                </NavLink>
                                                            </NavItem>
                                                        ))
                                                    }
                                                </ul>
                                            </Col>
                                        </Row>
                                    </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl="4" lg="4" md="12">
                                <div className="links">
                                    <b className="title-2">Subscribe to our newsletter for updates</b>
                                    <InputGroup>
                                        <Input type="text" placeholder="Enter email here"
                                            value={email}
                                            onChange={e =>
                                                setEmail(e.currentTarget.value)
                                            }
                                        />
                                        <InputGroupAddon addonType="append">
                                            <button
                                                className={"btn btn-info m-0"}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    FnSubmit(e);
                                                }}>
                                                Subscribe
                                            </button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </div>
                                <div className="links">
                                    <b className="title-2">Join us here too</b>
                                    <div className="social-area">
                                        <ul>
                                            <li className="btn">
                                                <a href={SOCIALLINKS.facebook} target="_blank" className="p-0" rel="noopener noreferrer">
                                                    <img
                                                        alt="facebook"
                                                        className="rounded"
                                                        src={require("../../assets/img/icon-sm-white-fb.png")}
                                                    ></img>
                                                </a>
                                            </li>
                                            {/* <li className="btn">
                                                <a href={SOCIALLINKS.google} target="_blank" className="p-0" rel="noopener noreferrer">
                                                    <img
                                                        alt="google plus"
                                                        className="rounded"
                                                        src={require("../../assets/img/icon-sm-white-go.png")}
                                                    ></img>
                                                </a>
                                            </li>
                                            <li className="btn">
                                                <a href={SOCIALLINKS.instagram} target="_blank" className="p-0" rel="noopener noreferrer">
                                                    <img
                                                        alt="instagram"
                                                        className="rounded"
                                                        src={require("../../assets/img/icon-sm-white-ig.png")}
                                                    ></img>
                                                </a>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>

                                <div className="links">
                                    {APPLINKS.android != '#' && <b className="title-2">Download Our App</b>}
                                    <ul>
                                        {APPLINKS.android != '#' &&
                                            <NavItem className="d-flex">
                                                <NavLink href={APPLINKS.android}>
                                                    <img
                                                        alt="..."
                                                        className="rounded"
                                                        src={require("../../assets/img/download-googleplay.png")}
                                                    ></img>
                                                </NavLink>
                                                <NavLink href={APPLINKS.android}>
                                                    <img
                                                        alt="..."
                                                        className="rounded"
                                                        src={require("../../assets/img/download-appstore.png")}
                                                    ></img>
                                                </NavLink>
                                            </NavItem>}
                                        <NavItem>
                                            <NavLink onClick={e => e.preventDefault()}>
                                                <img
                                                    alt="mastercard"
                                                    className="rounded"
                                                    src={require("../../assets/img/footer-icon-mastercard.png")}
                                                ></img>
                                                <img
                                                    alt="visa"
                                                    className="rounded"
                                                    src={require("../../assets/img/footer-icon-visa.png")}
                                                ></img>
                                                {/* <img
                                                    alt="amex"
                                                    className="rounded"
                                                    src={require("../../assets/img/footer-icon-amex.png")}
                                                ></img>
                                                <img
                                                    alt="paypal"
                                                    className="rounded"
                                                    src={require("../../assets/img/footer-icon-paypal.png")}
                                                ></img>
                                                <img
                                                    alt="comodo"
                                                    className="rounded"
                                                    src={require("../../assets/img/footer-icon-comodo.png")}
                                                ></img> */}
                                            </NavLink>
                                        </NavItem>
                                    </ul>
                                </div>

                            </Col>


                            {/* <Col xl="3" lg="3" md="6" sm="6" xs="12">
                            <div className="text-center">
                                <img
                                    alt="..."
                                    className="rounded the-best-img"
                                    src={require("../../assets/img/icon-thebest.png")}
                                ></img>
                            </div>
                            <div className="links">
                                <b className="title-2">Let Us Do The Work!</b>
                                <ul className="uppercase-links stacked-links">
                                   
                                    <NavItem style={{
                                        lineHeight: "unset"
                                    }}>
                                        <NavLink href="#" onClick={e => e.preventDefault()}>
                                            Whissh has attached bizSAFE level 3 of Workplace Safety and Helth Council of Singapore.
                                        </NavLink>
                                    </NavItem>
                                </ul>
                            </div>
                            <div className="links">
                                <b className="title-2">Download Our App</b>
                                <ul className="uppercase-links stacked-links">
                                    <NavItem>
                                        <NavLink href="#" onClick={e => e.preventDefault()}>
                                            <span> <img
                                                alt="..."
                                                className="rounded"
                                                src={require("../../assets/img/download-googleplay.png")}
                                            ></img></span>
                                            <span> <img
                                                alt="..."
                                                className="rounded"
                                                src={require("../../assets/img/download-appstore.png")}
                                            ></img></span>
                                        </NavLink>
                                    </NavItem>
                                </ul>
                            </div>
                        </Col>
                        */}

                            {/* <Col xl="3" lg="3" md="6" sm="6" xs="12">
                            <div className="text-center">
                                <img
                                    alt="..."
                                    className="rounded the-best-img"
                                    src={require("../../assets/img/icon-thebest.png")}
                                ></img>
                            </div>
                            <div className="links">
                                <b className="title-2">Let Us Do The Work!</b>
                                <ul className="uppercase-links stacked-links">
                                    
                                    <NavItem style={{
                                        lineHeight: "unset"
                                    }}>
                                        <NavLink href="#" onClick={e => e.preventDefault()}>
                                            Whissh has attached bizSAFE level 3 of Workplace Safety and Helth Council of Singapore.
                                        </NavLink>
                                    </NavItem>
                                </ul>
                            </div>
                            <div className="links">
                                <b className="title-2">Download Our App</b>
                                <ul className="uppercase-links stacked-links">
                                    <NavItem>
                                        <NavLink href="#" onClick={e => e.preventDefault()}>
                                            <span> <img
                                                alt="..."
                                                className="rounded"
                                                src={require("../../assets/img/download-googleplay.png")}
                                            ></img></span>
                                            <span> <img
                                                alt="..."
                                                className="rounded"
                                                src={require("../../assets/img/download-appstore.png")}
                                            ></img></span>
                                        </NavLink>
                                    </NavItem>
                                </ul>
                            </div>
                        </Col>
                        <Col xl="3" lg="3" md="6" sm="6" xs="12">
                            <div className="links">
                                <b className="title-2">General</b>
                                <ul className="stacked-links">

                                    <NavItem>
                                        <NavLink to="/" tag={Link}>
                                            Home
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink to="/contact-us" tag={Link}>
                                            Contact Us
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink to="/how-it-works" tag={Link}>
                                            How It Works
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink to="/faq" tag={Link}>
                                            FAQs
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink to="/about-us" tag={Link}>
                                            Abouts Us
                                        </NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink to="/rate" tag={Link}>
                                            Rates
                                        </NavLink>
                                    </NavItem>
                                   
                                    <NavItem>
                                        <NavLink to="/privacy-policy" tag={Link}
                                        >
                                            Privacy Policy
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink to="/terms-and-condition" tag={Link}>
                                            Terms & Conditions
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink to="/terms-service" tag={Link}>
                                            Terms of Use
                                        </NavLink>
                                    </NavItem><NavItem>
                                        <NavLink to="/service-crew" tag={Link}>
                                            Join Us
                                        </NavLink>
                                    </NavItem>
                                </ul>
                            </div>
                        </Col>
                        <Col xl="2" lg="2" md="6" sm="6" xs="12">
                            <div className="links">
                                <b className="title-2">Our Services</b>
                                <ul className="stacked-links">
                                    <NavItem>
                                        <NavLink href="#" onClick={e => e.preventDefault()}>
                                            <span className="right-icon"></span> Concierge
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="#" onClick={e => e.preventDefault()}>
                                            <span className="right-icon"></span>   Facilities
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="#" onClick={e => e.preventDefault()}>
                                            <span className="right-icon"></span>    Cleaning
                                        </NavLink>
                                    </NavItem>
                                </ul>
                            </div>
                        </Col>
                        <Col xl="4" lg="4" md="6" sm="6" xs="12">
                            <div className="links">
                                <b className="title-2">Find us here</b>
                                <ul className="stacked-links">
                                    <NavItem style={{
                                        lineHeight: "17px"
                                    }}>
                                        <NavLink href="#" onClick={e => e.preventDefault()}>
                                            <span className="location-icon"></span>
                                            <span> 62 Ubi Road 1, #10-17, Oxley Bizhub 2,</span> <br />
                                            <span
                                                style={{
                                                    paddingLeft: "25px"
                                                }}>
                                                Singapore 408734
                                            </span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="#" onClick={e => e.preventDefault()}>
                                            <span className="phone-icon"></span> +65 62218626
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="#" onClick={e => e.preventDefault()}>
                                            <span className="email-icon"></span> contact@whissh.com.sg
                                        </NavLink>
                                    </NavItem>
                                    <NavItem style={{
                                        lineHeight: "17px"
                                    }}>
                                        <NavLink href="#" onClick={e => e.preventDefault()}>
                                            <span className="time-icon"></span>
                                            <span>9.00 am - 9.00 pm</span><br />
                                            <span
                                                style={{
                                                    paddingLeft: "25px"
                                                }}
                                            >Daily</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="#" onClick={e => e.preventDefault()}>
                                            <img
                                                alt="mastercard"
                                                className="rounded"
                                                src={require("../../assets/img/footer-icon-mastercard.png")}
                                            ></img>
                                            <img
                                                alt="visa"
                                                className="rounded"
                                                src={require("../../assets/img/footer-icon-visa.png")}
                                            ></img>
                                            <img
                                                alt="amex"
                                                className="rounded"
                                                src={require("../../assets/img/footer-icon-amex.png")}
                                            ></img>
                                            <img
                                                alt="paypal"
                                                className="rounded"
                                                src={require("../../assets/img/footer-icon-paypal.png")}
                                            ></img>
                                            <img
                                                alt="comodo"
                                                className="rounded"
                                                src={require("../../assets/img/footer-icon-comodo.png")}
                                            ></img>
                                        </NavLink>
                                    </NavItem>
                                </ul>
                            </div>
                        </Col>
                     */}


                        </Row>
                    </Container>
                </footer>
            }
        </>
    );
}

export default React.memo(FooterCorporate);
