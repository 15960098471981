import React, { useReducer, useEffect, useContext, createContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col, Modal } from "reactstrap";

// core components
import MBActiveDetails from "../../components/MyAccount/MBActiveDetails";
import MBCrewLocation from "../../components/MyAccount/MBCrewLocation";
import ModalMBChangeDate from "../../components/MyAccount/ModalMBChangeDate";
import { GlobalContext } from '../../App';
import { MyBookingDetailsInitialState, MyBookingDetailsReducer } from "../../reducer/MyAccountReducer";
import { GetAPI } from "../../API/APICalling";
import MBServiceInfo from "../../components/MyAccount/MBServiceInfo";
import MBHistoryDetails from "../../components/MyAccount/MBHistoryDetails";
import ModalMBRefund from "../../components/MyAccount/ModalMBRefund";
import ModalMBSubmitFeedback from "../../components/MyAccount/ModalMBSubmitFeedback";

export const BookingDetailsContext = createContext();

export default function MyBookingDetails(props) {
    const globalContext = useContext(GlobalContext);
    const [state, dispatch] = useReducer(MyBookingDetailsReducer, MyBookingDetailsInitialState);
    const history = useHistory();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        if (props.match.params.type === 'active') {
            dispatch({ type: 'COMPONENT', ComponentType: 'BOOKING_ACTIVE_DETAILS' });
        }
        else {
            dispatch({ type: 'COMPONENT', ComponentType: 'BOOKING_HISTORY_DETAILS' });
        }

        if (props.match.params.refnum) {
            FnGetBookingDetails(props.match.params.refnum);
        }
        else {
            history.push('/');
        }
    }, []);

    const FnReturnComponent = (param) => {
        switch (param) {
            case 'CREW_LOCATION':
                return <MBCrewLocation />
            case 'BOOKING_ACTIVE_DETAILS':
                return <MBActiveDetails />
            case 'BOOKING_HISTORY_DETAILS':
                return <MBHistoryDetails />
            default:
                return null
        }
    }
    const FnReturnModalContent = (param) => {
        switch (param) {
            case 'CHANGE_SERVICE_DATE':
                return <ModalMBChangeDate />
            case 'REFUND_REQUEST':
                return <ModalMBRefund />
            case 'SUBMIT_FEEDBACK':
                return <ModalMBSubmitFeedback />
            default:
                return null
        }
    }

    const FnGetBookingDetails = async (refnum) => {
        globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
        var result = await GetAPI(`/mybooking/details/${refnum}`);
        if (result) {
            globalContext.loaderDispatch({ type: 'STOP_LOADING' });
            if (result.error) {
                dispatch({ type: 'ERROR' });
                globalContext.notifications('error', result.error);
            }
            else if (result.data) {
                dispatch({ type: 'SUCCESS', payload: result.data });
                // console.log(result.data)
            }
        }
    };

    return (
        <>
            <BookingDetailsContext.Provider value={{
                BDState: state,
                BDDispatch: dispatch,
            }}>
                <div className="adjust-navbar-breadcrumb">
                    <div className="breadcrumb-outer">
                        <Container className="breadcrumb-body">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/">
                                        Home
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="/my-account/my-profile">
                                        <i className="fa fa-angle-right pr-5" aria-hidden="true"></i>
                                    My Account
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="/my-account/my-booking">
                                        <i className="fa fa-angle-right pr-5" aria-hidden="true"></i>
                                    My Booking
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    <i className="fa fa-angle-right pr-5" aria-hidden="true"></i>Booking Details
                                </li>
                            </ol>
                        </Container>
                    </div>
                    <Container fluid id="myAccount">
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="10">
                                <Row className="pt-15 pb-10">
                                    <div className="ml-auto mr-auto">
                                        <h2 className="big-heading">{props.match.params.type === 'history' ? 'History Booking Details' : 'Active Booking Details'}   </h2>
                                        <hr className="hr-large"></hr>
                                    </div>
                                </Row>
                                <Row>
                                    <Col md="10" className="ml-auto mr-auto">
                                        <Row className="mt-30 mb-70">
                                            <Col xl="4" lg="12" md="12" sm="12" xs="12" className="ml-auto mr-auto text-left">
                                                <div className="card">
                                                    <div className="card-header text-left title-2">
                                                        Service Information
                                                    </div>
                                                    {state.BookingDetails &&
                                                        <MBServiceInfo data={state.BookingDetails} />
                                                    }

                                                </div>
                                            </Col>
                                            <Col xl="8" lg="12" md="12" sm="12" xs="12" className="ml-auto mr-auto">
                                                {FnReturnComponent(state.visibleComponentType)}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {state.modal ? (
                    <Modal size={state.modalSize} isOpen={state.modal}
                        className="transparent-modal"
                    // toggle={() => setModal(!state.modal)}
                    >
                        {FnReturnModalContent(state.visibleModalContentType)}
                    </Modal>
                ) : null
                }
            </BookingDetailsContext.Provider>
        </>
    );
}
