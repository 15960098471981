import React, { useContext, useEffect, forwardRef, useImperativeHandle } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from '../../App';
import { ServiceBookingContext } from '../../views/ServiceBooking';
import { CardField } from '../../components/CheckOutPayment/CheckoutProperty';
import { PostAPI } from '../../API/APICalling';
import { api } from "../../constant";
import { FnCountryCode } from '../../utils/CountryCode';

// export default function CheckoutForm() {
const My2c2p = window.My2c2p;

const CheckoutForm = forwardRef((props, ref) => {
    
    const globalContext = useContext(GlobalContext);
    const sbContext = useContext(ServiceBookingContext);
    const stripe = useStripe();
    const elements = useElements();
    const history = useHistory();
    // const [error, setError] = useState(null);
    //const [cardComplete, setCardComplete] = useState(false);
    // const [processing, setProcessing] = useState(false);
    //const [isSuccess, setIsSuccess] = useState(false);
    //const [paymentMethod, setPaymentMethod] = useState(null);
    // const [billingDetails, setBillingDetails] = useState({
    //     email: '',
    //     phone: '',
    //     name: '',
    // });

    useEffect(() => {
        if (sbContext.CheckoutState.processing) {
            globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
        }
        else {
            globalContext.loaderDispatch({ type: 'STOP_LOADING' });
        }
    }, [sbContext.CheckoutState.processing])


    const handleSubmit = async (event) => {
        if (sbContext.CheckoutState.paymentType === "link") {
            LinkPayment(event);
        }
        else if (sbContext.CheckoutState.paymentType === "card") { 
            cardpayment(event);
        }
        else if (sbContext.CheckoutState.paymentType === "2c2p") { 
            //console.log(sbContext)
            c2ppayment();            
        }
        else{
            // UK Take Payment
            // console.log(sbContext.CheckoutState)
            uktakepayments();
        }
    }
    const cardpayment = async (event) => {
        try {
            // event.preventDefault();
            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                return;
            }

            if (sbContext.CheckoutState.error) {
                elements.getElement('card').focus();
                return;
            }

            if (sbContext.CheckoutState.cardComplete) {
                sbContext.CheckoutDispatch({ type: 'PAYMENT_START' });
            }
            else
                return;

            //First sending Payable details to the userver.
            var result = await PostAPI(`booking/cart/confirm/${sbContext.CheckoutState.refnum}`,
                {
                    addr1: sbContext.CheckoutState.addr1,
                    addr2: sbContext.CheckoutState.addr2,
                    addr3: sbContext.CheckoutState.addr3,
                    creditused: parseFloat(sbContext.CheckoutState.creditredeem || 0),
                    email: sbContext.CheckoutState.email,
                    firstname: sbContext.CheckoutState.firstname,
                    lastname: sbContext.CheckoutState.lastname,
                    mobile: sbContext.CheckoutState.phoneNumber,
                    paidamt: (parseFloat(sbContext.CheckoutState.total || 0) - parseFloat(sbContext.CheckoutState.creditredeem || 0)),
                    postcode: sbContext.CheckoutState.postalCode,
                    paymentref: 'card',
                    title: sbContext.CheckoutState.title
                }
            );
            if (result) {
                if (result.error) {
                    globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                    globalContext.notifications('error', result.error);
                    sbContext.CheckoutDispatch({ type: 'PAYMENT_ERROR' });
                }
                else {
                    const billingDetails = {
                        email: sbContext.CheckoutState.email,
                        phone: sbContext.CheckoutState.phoneNumber,
                        name: sbContext.CheckoutState.firstname + ' ' + sbContext.CheckoutState.lastname,
                        address: {
                            country: FnCountryCode(sbContext.CheckoutState.addr3),
                            line1: sbContext.CheckoutState.unitno + ', ' + sbContext.CheckoutState.addr1,
                            line2: sbContext.CheckoutState.addr2,
                            postal_code: sbContext.CheckoutState.postalCode,
                        }
                    };

                    const payload = await stripe.createPaymentMethod({
                        type: 'card',
                        card: elements.getElement(CardElement),
                        billing_details: billingDetails
                    });

                    if (payload.error) {
                        sbContext.CheckoutDispatch({ type: 'PAYMENT_ERROR', payload: payload.error });
                    } else {
                        handlePaymentMethodResult(payload);
                    }
                }
            }
        }
        catch (e) {
            sbContext.CheckoutDispatch({ type: 'PAYMENT_ERROR' });
        }
    };

    const LinkPayment = async (event) => {

        globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
        //First sending Payable details to the userver.
        var result = await PostAPI(`booking/cart/confirm/${sbContext.CheckoutState.refnum}`,
            {
                addr1: sbContext.CheckoutState.addr1,
                addr2: sbContext.CheckoutState.addr2,
                addr3: sbContext.CheckoutState.addr3,
                creditused: parseFloat(sbContext.CheckoutState.creditredeem || 0),
                email: sbContext.CheckoutState.email,
                firstname: sbContext.CheckoutState.firstname,
                lastname: sbContext.CheckoutState.lastname,
                mobile: sbContext.CheckoutState.phoneNumber,
                paidamt: (parseFloat(sbContext.CheckoutState.total || 0) - parseFloat(sbContext.CheckoutState.creditredeem || 0)),
                postcode: sbContext.CheckoutState.postalCode,
                paymentref: 'paynow',
                title: sbContext.CheckoutState.title
            }
        );
        if (result) {
            globalContext.loaderDispatch({ type: 'STOP_LOADING' });
            if (result.error) {
                globalContext.notifications('error', result.error);
                sbContext.CheckoutDispatch({ type: 'PAYMENT_ERROR' });
            }
            else {
                history.push(
                    {
                        pathname: "/paynow-qrcode",
                        state: { sor: result.success }
                    }
                );
            }
        }
    }

    const handlePaymentMethodResult = async (result) => {
        try {
            if (result.error) {
                // An error happened when collecting card details, show it in the payment form
                sbContext.CheckoutDispatch({ type: 'PAYMENT_ERROR', payload: { message: result.error } });
            } else {
                // Otherwise send paymentMethod.id to your server (see Step 3)
                api.post('checkout/initiatepayment', {
                    PaymentMethodId: result.paymentMethod.id,
                    refnum: sbContext.CheckoutState.refnum,
                    paidamt: parseFloat(sbContext.CheckoutState.total || 0),
                    creditused: parseFloat(sbContext.CheckoutState.creditredeem || 0),
                    availcredit: parseFloat(sbContext.CheckoutState.availableCredit || 0),
                    email: sbContext.CheckoutState.email || ''
                }).then(function (result) {
                    return result.data;
                }).then(handleServerResponse);
            }
        }
        catch (e) {
            sbContext.CheckoutDispatch({ type: 'PAYMENT_ERROR' });
        }
    }

    const handleServerResponse = async (responseJson) => {
        try {
            if (responseJson.error) {
                // Show error from server on payment form
                sbContext.CheckoutDispatch({ type: 'PAYMENT_ERROR', payload: { message: responseJson.error } });
            } else if (responseJson.requiresAction) {
                // Use Stripe.js to handle required card action
                stripe.handleCardAction(
                    responseJson.clientSecret
                ).then(function (result) {
                    if (result.error) {
                        // Show `result.error.message` in payment form
                        sbContext.CheckoutDispatch({ type: 'PAYMENT_ERROR', payload: result.error });
                    } else {
                        // The card action has been handled
                        // The PaymentIntent can be confirmed again on the server
                        api.post('checkout/confirmpayment', {
                            refnum: sbContext.CheckoutState.refnum,
                            PaymentIntentId: result.paymentIntent.id
                        }).then(function (confirmResult) {
                            return confirmResult.data;
                        }).then(handleServerResponse);
                    }
                });
            } else {
                // Show success message
                // alert('success');
                sbContext.CheckoutDispatch({ type: 'PAYMENT_SUCCESS', payload: responseJson.transactionId });
                history.push(
                    {
                        pathname: "/payment-successfully",
                        state: { txnId: responseJson.transactionId }
                    }
                );
            }
        }
        catch (e) {
            sbContext.CheckoutDispatch({ type: 'PAYMENT_ERROR' });
        }
    }

    const c2ppayment = async() => {
        try {
            //First sending Payable details to the userver.
            var result = await PostAPI(`booking/cart/confirm/${sbContext.CheckoutState.refnum}`,
                {
                    addr1: sbContext.CheckoutState.addr1,
                    addr2: sbContext.CheckoutState.addr2,
                    addr3: sbContext.CheckoutState.addr3,
                    creditused: parseFloat(sbContext.CheckoutState.creditredeem || 0),
                    email: sbContext.CheckoutState.email,
                    firstname: sbContext.CheckoutState.firstname,
                    lastname: sbContext.CheckoutState.lastname,
                    mobile: sbContext.CheckoutState.phoneNumber,
                    paidamt: (parseFloat(sbContext.CheckoutState.total || 0) - parseFloat(sbContext.CheckoutState.creditredeem || 0)),
                    postcode: sbContext.CheckoutState.postalCode,
                    paymentref: 'card',
                    title: sbContext.CheckoutState.title
                }
            );
            if (result) {
                if (result.error) {
                    globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                    globalContext.notifications('error', result.error);
                    sbContext.CheckoutDispatch({ type: 'PAYMENT_ERROR' });
                }
                else {
                    let invoice = {
                        transcode: sbContext.CheckoutState.refnum+"#"+Date.now(), // for debugging only, remove the datetime
                        description: "Whissh Web Payment",
                        amt: parseFloat(sbContext.CheckoutState.total).toFixed(2),
                        callbackUrl: window.location.origin
                    }
                    invoice = btoa(JSON.stringify(invoice));
                    let payload = sbContext.CheckoutState.twoc2pCardNumber + "#" + sbContext.CheckoutState.twoc2pExpiry + "#" + sbContext.CheckoutState.twoc2pCvc + "#" + invoice;
                    payload = btoa(payload);
                    window.location.href = "https://wukpg.whissh.co.uk/payment/2c2p.php?p="+payload;
            }
        }
        }
        catch (e) {
            sbContext.CheckoutDispatch({ type: 'PAYMENT_ERROR' });
        }
    }

    const uktakepayments = async() => {
        try {
            //First sending Payable details to the userver.
            var result = await PostAPI(`booking/cart/confirm/${sbContext.CheckoutState.refnum}`,
                {
                    addr1: sbContext.CheckoutState.addr1,
                    addr2: sbContext.CheckoutState.addr2,
                    addr3: sbContext.CheckoutState.addr3,
                    creditused: parseFloat(sbContext.CheckoutState.creditredeem || 0),
                    email: sbContext.CheckoutState.email,
                    firstname: sbContext.CheckoutState.firstname,
                    lastname: sbContext.CheckoutState.lastname,
                    mobile: sbContext.CheckoutState.phoneNumber,
                    paidamt: (parseFloat(sbContext.CheckoutState.total || 0) - parseFloat(sbContext.CheckoutState.creditredeem || 0)),
                    postcode: sbContext.CheckoutState.postalCode,
                    paymentref: 'card',
                    title: sbContext.CheckoutState.title
                }
            );
            if (result) {
                if (result.error) {
                    globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                    globalContext.notifications('error', result.error);
                    sbContext.CheckoutDispatch({ type: 'PAYMENT_ERROR' });
                }
                else {
                    let invoice = {
                        transcode: sbContext.CheckoutState.refnum+"#"+Date.now(), // for debugging only, remove the datetime
                        description: "Whissh Web Payment",
                        amt: sbContext.CheckoutState.total,
                        callbackUrl: window.location.origin,
                        customer: sbContext.CheckoutState.firstname + " " + sbContext.CheckoutState.lastname 
                    }
                    invoice = btoa(JSON.stringify(invoice));
                    let payload = sbContext.CheckoutState.uktakepayCardNumber + "#" + sbContext.CheckoutState.uktakepayExpiry + "#" + sbContext.CheckoutState.uktakepayCvc + "#" + invoice;
                    payload = btoa(payload);
                    //window.location.href = "https://hesedph.homedns.org/paymentUK/uktakepay.php?p="+payload;
		    window.location.href = "https://wukpg.whissh.co.uk/ukpayment/uktakepay.php?p="+payload;
            }
        }
        }
        catch (e) {
            sbContext.CheckoutDispatch({ type: 'PAYMENT_ERROR' });
        }
    }
    useImperativeHandle(ref, () => {
        return {
            handleSubmit: handleSubmit
        };
    });
       
    // const ProductPaymentSuccess = async () => {
    //     try {
    //         var result = await PostAPI(`booking/cart/confirm/${sbContext.CheckoutState.refnum}`,
    //             {
    //                 addr1: sbContext.CheckoutState.addr1,
    //                 addr2: sbContext.CheckoutState.addr2,
    //                 addr3: sbContext.CheckoutState.addr3,
    //                 creditused: parseFloat(sbContext.CheckoutState.creditredeem || 0),
    //                 email: sbContext.CheckoutState.email,
    //                 firstname: sbContext.CheckoutState.firstname,
    //                 lastname: sbContext.CheckoutState.lastname,
    //                 mobile: sbContext.CheckoutState.phoneNumber,
    //                 paidamt: (parseFloat(sbContext.CheckoutState.finalAmount || 0) - parseFloat(sbContext.CheckoutState.creditredeem || 0)),
    //                 paymentref: '',
    //                 postcode: sbContext.CheckoutState.postalCode,
    //             }
    //         );
    //         if (result) {
    //             globalContext.loaderDispatch({ type: 'STOP_LOADING' });
    //             if (result.error) {
    //                 globalContext.notifications('error', result.error);
    //             }
    //             else {
    //                 sbContext.CheckoutDispatch({ type: 'PAYMENT_SUCCESS' });
    //                 history.push("/payment-successfully");
    //             }
    //         }
    //     }
    //     catch (e) {
    //         sbContext.CheckoutDispatch({ type: 'PAYMENT_ERROR' });
    //     }
    // };

    // const reset = () => {
    //     setError(null);
    //     setProcessing(false);
    //     setPaymentMethod(null);
    //     setBillingDetails({
    //         email: '',
    //         phone: '',
    //         name: '',
    //     });
    // };
    return (
        <>
            <div id="StripeCheckout">
                <fieldset className="FormGroup">
                    <CardField
                        options={{ hidePostalCode: true }}
                        onChange={(e) => {
                            sbContext.CheckoutDispatch({ type: 'IS_CARD_COMPLETE', status: e.complete, error: e.error });
                        }}
                    />
                </fieldset>
                {/* {error && <ErrorMessage>{error.message}</ErrorMessage>}
                <SubmitButton processing={processing} error={error} disabled={!stripe}>
                    Pay $25
                </SubmitButton> */}
            </div>

        </>
    )
});

export default CheckoutForm;