import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Button, FormGroup, Input, Label, ModalHeader, ModalBody } from "reactstrap";
import { useHistory } from "react-router-dom";
import { GlobalContext } from '../../App.js';
import { getData } from "../../utils/localStorage";
import { GetAPI } from "../../API/APICalling";
import { storeData } from "../../utils/localStorage";
import { ServiceBookingContext } from '../../views/ServiceBooking';
import { PostAPI } from "../../API/APICalling";


export default function ModalPackageAddOns() {
    const sbContext = useContext(ServiceBookingContext);
    const globalContext = useContext(GlobalContext);
    const history = useHistory();
    const [addOnsList, setAddOnsList] = useState([]);
    const [showBookNow, setShowBookNow] = useState(false);
    useEffect(() => {
        document.querySelector('.modal').scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        if (sbContext.ServiceState.PackageDetails) {
            FnGetAddOns(sbContext.ServiceState.PackageDetails.refnum, sbContext.ServiceState.PackageDetails.guid);
        }
    }, []);



    const FnGetAddOns = async (refnum, guid) => {
        globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
        var result = await GetAPI(`booking/cart/addons/${refnum}?guid=${guid}`);
        if (result) {
            if (result.error) {
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                globalContext.notifications('error', result.error);
            }
            else if (result.data) {
                console.log(result.data)
                setAddOnsList(result.data);
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
            }
        }
    };

    const UpdateQty = async (optionnum, qty) => {
        setAddOnsList(addOnsList.map(item => {
            return (item.optionnum === optionnum && parseInt(qty || 0) >= 0 && parseInt(qty || 0) <= 100) ?
                {
                    ...item,
                    qty: parseInt(qty || 0)
                }
                :
                item
        }))
        const TotalQty = addOnsList.reduce((tl, item) => {
            return tl + ((item.optionnum === optionnum && parseInt(qty || 0) >= 0 && parseInt(qty || 0) <= 100) ? parseInt(qty || 0) : item.qty)
        }, 0)
        TotalQty > 0 ? setShowBookNow(true) : setShowBookNow(false);
        console.log(addOnsList);
    }

    const onSubmit = async () => {
        const TotalQty = addOnsList.reduce((tl, item) => {
            return tl + (item.qty)
        }, 0)
        if (TotalQty > 0) {
            if (!sbContext.ServiceState.PackageDetails.refnum || !sbContext.ServiceState.PackageDetails.guid) {
                globalContext.notifications('error', 'Oops ! Something went wrong., Please try again');
                return false;
            }
            else {
                globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
                var result = await PostAPI(`booking/cart/addons/${sbContext.ServiceState.PackageDetails.refnum}?guid=${sbContext.ServiceState.PackageDetails.guid}`, addOnsList);
                if (result) {
                    globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                    if (result.error) {
                        globalContext.notifications('error', result.error);
                    }
                    else {
                        sbContext.ServiceDispatch({ type: 'CLOSE_MODAL' });
                        //trigger callback to reload finalize screen.
                        sbContext.ServiceState.callback();
                    }
                }
            }
        }
    }

    return (
        <>
            <div className="package-add-ons-modal">
                <ModalHeader toggle={() => sbContext.ServiceDispatch({ type: 'CLOSE_MODAL' })}>
                </ModalHeader>
                <ModalBody className="p-0">
                    <div className="card">
                        <div className="card-header text-left title-2">
                            Fill Items Quantity
								</div>
                        <div className="card-body">
                            {
                                addOnsList.length > 0 && addOnsList.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <Row>
                                            <Col xs="3" md="6">
                                                <img
                                                    alt="..."
                                                    className="laundry-img"
                                                    src={item.directory || require("../../assets/img/img-sample-service2.jpg")}
                                                ></img>
                                            </Col>
                                            <Col xs="9" md="5" className="pl-0">
                                                {/* <div className="text-left">
                                                                    Qty:  <Input type="number" className="laundry-qty"
                                                                        value={item.qty}
                                                                        onChange={e => {
                                                                            e.preventDefault();
                                                                            UpdateQty(item.optionnum, e.currentTarget.value)
                                                                        }}
                                                                    ></Input>
                                                                </div> */}
                                                <Row className="mt-5">
                                                    <Col xs="12">
                                                        <div className="text-left title-3 pb-10">{item.optiondesc} ( ${parseFloat(item.price).toFixed(2)} / {item.uom})</div>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-5">
                                                    <Col xs="5">
                                                        <FormGroup>
                                                            <Row>
                                                                <Col xs="4"> <Label >Qty:</Label></Col>
                                                                <Col xs="8">
                                                                    <Input type="number" className="laundry-qty"
                                                                        value={item.qty}
                                                                        onChange={e => {
                                                                            e.preventDefault();
                                                                            UpdateQty(item.optionnum, e.currentTarget.value)
                                                                        }}
                                                                    ></Input>
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs="7" className="text-right">
                                                        <div className="title-3 pb-10">${(parseFloat(item.price || 0) * (parseFloat(item.qty || 0))).toFixed(2)}</div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <hr></hr>
                                    </React.Fragment>
                                ))
                            }
                        </div>
                        <div className="text-center">
                            <FormGroup>
                                <Button
                                    className={"btn-lg btn-round btn-default"}
                                    onClick={e => {
                                        e.preventDefault();
                                        // props.Function.FnCloseModal();
                                        sbContext.ServiceDispatch({ type: 'CLOSE_MODAL' });
                                    }}>
                                    CANCEL
                                     </Button>
                                {showBookNow &&
                                    <Button className="btn btn-round btn-info"
                                        onClick={e => {
                                            e.preventDefault();
                                            onSubmit();
                                        }}
                                    >BOOK NOW</Button>
                                }
                            </FormGroup>
                        </div>
                    </div>
                </ModalBody>
            </div>
        </>
    );

}
