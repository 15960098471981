import React, { useReducer, createContext, useEffect, version } from 'react';
import { Modal } from "reactstrap";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import Layout from './Layout.js';
import RedirectWithStatus from './RedirectWithStatus.js';
// pages for this kit
import Index from "./views/Index.js";
// import ExistingCustomerPackageList from "./views/ExistingCustomerPackageList.js";
// import OurServices from "./views/OurServices.js";


// import NewCustomerPackageList from "./views/NewCustomerPackageList.js";
import PackageList from "./views/PackageList";
import ServiceBooking from "./views/ServiceBooking.js";
import PaymentSuccessfullyDone from "./views/PaymentSuccessfullyDone.js";


import AboutUs from "./views/CorporatePages/AboutUs.js";
import RateCard from "./views/CorporatePages/RateCard.js";
import HowItWorks from './views/CorporatePages/HowItWorks.js';
import FAQ from './views/CorporatePages/FAQ.js';
import ContactUs from './views/CorporatePages/ContactUs.js';
import OurServices from "./views/CorporatePages/OurServices.js";

import ThankYou from "./views/ThankYou.js"
import Soft404 from "./views/404.js"
import TermsAndCondition from './views/CorporatePages/TermsAndCondition';
import TermsOfService from './views/CorporatePages/TermsOfService';
import PrivacyPolicy from './views/CorporatePages/PrivacyPolicy';
import ScheduleAssessment from './views/ScheduleAssessment';
import ServiceCrew from './views/CorporatePages/ServiceCrew';
import MyProfile from './views/MyAccount/MyProfile';
import MyBooking from './views/MyAccount/MyBooking';
import MyBookingDetails from './views/MyAccount/MyBookingDetails';
// import MyBookingHistoryDetails from './views/MyAccount/MyBookingHistoryDetails';
import MyRequest from './views/MyAccount/MyRequest';
import RequestQuotation from './views/MyAccount/RequestQuotation';
import MyInbox from './views/MyAccount/MyInbox';
import MyCredits from './views/MyAccount/MyCredits';
import MyFeedback from './views/MyAccount/MyFeedback';
import OurServicesDetails from './views/CorporatePages/OurServicesDetails';

import ModalSubCategory from './components/Headers/ModalSubCategory.js';
import ModalChooseSerivice from './components/Login/ModalChooseSerivice.js';
import ModalLogin from './components/Login/ModalLogin.js';
import ModalOtp from './components/Login/ModalOtp.js';
import ModalChooseLocation from "./components/Login/ModalChooseLocation.js";
import ModalSignUp from "./components/Login/ModalSignUp.js";
import ModalChooseLocationNewUser from "./components/Login/ModalChooseLocationNewUser.js";
import ModalForgotPassword from "./components/Login/ModalForgotPassword.js";
import { NotificationContainer, NotificationManager } from 'react-notifications';//help https://www.npmjs.com/package/react-notifications

import ModalProgress from './components/Login/ModalProgress';
import { HeaderInitialState, HeaderReducer, AuthReducer, AuthInitialState, LoaderInitialState, LoaderReducer, CommonReducer, CommonInitialState } from './reducer/GloabReducer';
import { getData } from './utils/localStorage';
import Loader from './components/Login/Loader';
import AddOnList from './views/AddOnList';
import EnquiryForm from './views/CorporatePages/EnquiryForm.js';
import Paynowqr from './views/Paynowqr';
import Blog from './views/CorporatePages/Blog';
import BlogDetails from './views/CorporatePages/BlogDetails';
import shop from './views/CorporatePages/Shop';
import GoogleTranslate from "./utils/GoogleTranslate";
import { GetAPI } from './API/APICalling';
import Metadata from './Metadata';
import { BASE_URL, api } from './constant.js';
import { Helmet } from "react-helmet";

export const GlobalContext = createContext();

function App() {
  const [authState, authDispatch] = useReducer(AuthReducer, AuthInitialState)
  const [headerState, headerDispatch] = useReducer(HeaderReducer, HeaderInitialState)
  const [loaderState, loaderDispatch] = useReducer(LoaderReducer, LoaderInitialState)
  const [commonState, commonDispatch] = useReducer(CommonReducer, CommonInitialState)
  const { modal, visibleComponentType, modalTransparent } = headerState;
  // const { isLoggedIn, userdata: { } } = authState;
  const history = useHistory();
  useEffect(() => {

    if (history.location.search === '?q=forgotpassword') {
      headerDispatch({ type: 'FORGOT_PASSWORD' });
      history.replace('/');
    }

    // console.log('isLoggedIn: ' + authState.isLoggedIn)
    if (authState.isLoggedIn === false) {
      const session = getData('session');
      if (session) {
        authDispatch({ type: 'SET', payload: session });
        // console.log('after SET: ' + authState.isLoggedIn)
      }
      else {
        //authDispatch({ type: 'RESET' });
        console.log('isLoggedIn: ' + authState.isLoggedIn);
        //window.location.href='/';
      }
      if (history.location.search === '?q=login') {
        authDispatch({ type: 'RESET' });
        headerDispatch({ type: 'HEADERLOGIN' });
        history.replace('/');
      }
    }
  }, []);

  useEffect(() => {

    // const FnMetaLoading = async () => {
    //   var response = await api.get(BASE_URL + 'metadata.json');
    //   if (response.data) {
    //     //const response = res.json();
    //     if (response.data != null) {
    //       commonDispatch({ type: 'SET_METADATA', payload: response.data })
    //     }
    //   }
    // }

    const FnMetaLoading = async () => {
      var result = await GetAPI(`cms/_metadata/list`);
      if (result) {
        if (result.data) {
          commonDispatch({ type: 'SET_METADATA', payload: result.data })
        }
      }
    }


    const fetchheader = async () => {
      loaderDispatch({ type: 'START_LOADING', payload: true });
      var result = await GetAPI(`cms/home-top`);
      if (result) {
        loaderDispatch({ type: 'STOP_LOADING' });
        if (result.data) {
          commonDispatch({ type: 'SET_HOMEHEADER', payload: result.data.content })
        }
      }
    }

    const fetchmenu = async e => {
      loaderDispatch({ type: 'START_LOADING', payload: true });
      var result = await GetAPI(`home/services`);
      if (result) {
        loaderDispatch({ type: 'STOP_LOADING' });
        if (result.data) {
          commonDispatch({ type: 'SET_MENUITEMS', payload: result.data })
        }
      }
    }
    const FnGetFooterContent = async () => {
      loaderDispatch({ type: 'START_LOADING', payload: true });
      var result = await GetAPI(`cms/footer`);
      if (result) {
        loaderDispatch({ type: 'STOP_LOADING' });
        if (result.data) {
          commonDispatch({ type: 'SET_FOOTERDATA', payload: result.data.content })
        }
      }
    }

    const fetchmegamenu = async () => {
      loaderDispatch({ type: 'START_LOADING', payload: true });
      var result = await GetAPI(`home/ourservices`);
      if (result) {
        loaderDispatch({ type: 'STOP_LOADING' });
        if (result.data) {
          commonDispatch({ type: 'SET_MEGAMENU', payload: result.data })
        }
      }
    }
    FnMetaLoading();
    fetchheader();
    fetchmenu();
    fetchmegamenu();
    FnGetFooterContent();
  }, []);

  useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const FnReturnComponent = (param) => {
    console.log('param:  ' + param)
    switch (param) {
      case 'SUB_MENU':
        return <ModalSubCategory />
      case 'CHOOSE_SERVICE':
        //return <ModalChooseSerivice />
        //return <ModalLogin />
        return <ModalChooseLocation />
      case 'LOGIN':
        return <ModalLogin />
      case 'OTP':
        return <ModalOtp />
      case 'CHOOSELOCATION_OLDUSER':
        return <ModalChooseLocation />
      case 'SIGNUP':
        return <ModalSignUp />
      case 'CHOOSELOCATION_NEWUSER':
        return <ModalChooseLocationNewUser />
      case 'FORGOT_PASSWORD':
        return <ModalForgotPassword />
      case 'LOADING':
        return <ModalProgress />
      default:
        return null
    }
  }

  const createNotification = (type, msg) => {
    switch (type) {
      case 'info':
        NotificationManager.info(msg);
        break;
      case 'success':
        NotificationManager.success(msg, 'Success Message', 3000);
        break;
      case 'warning':
        NotificationManager.warning(msg, 'Warning Message', 3000);
        break;
      case 'error':
        NotificationManager.error(msg, 'Error Message', 5000);

        // NotificationManager.error(msg, 'Click me!', 5000, () => {
        //     alert('callback');
        // });
        break;
      default:
        break
    }
  }
  return (
    <GlobalContext.Provider
      value={{
        headerState: headerState,
        headerDispatch: headerDispatch,
        authState: authState,
        authDispatch: authDispatch,
        notifications: createNotification,
        loaderState: loaderState,
        loaderDispatch: loaderDispatch,
        commonState: commonState,
        commonDispatch: commonDispatch
      }}
    >
      <>
      <Helmet>
        <link rel="canonical" href={"https://whissh.co.uk"+history.location.pathname} />
      </Helmet>
        <Metadata></Metadata>
        <GoogleTranslate></GoogleTranslate>
        <React.Fragment>
          {loaderState.showLoader && <Loader />}
          <Layout>
            <Switch>
              <Route exact path="/" component={Index} />
              {/* <Route exact path="/index" component={Index} /> */}
              <Route exact path="/index" render={props => (
                <RedirectWithStatus status={301} from="/index" to="/" />
              )} />

              <Route path="/about-us" component={AboutUs} />
              <Route path="/aboutus" component={AboutUs} />

              <Route path="/enquiry" component={EnquiryForm} />
              <Route path="/corporate-enquiry" component={EnquiryForm} />

              {/* <Route path='/our-services/:type' component={OurServicesDetails} /> */}

              <Route path="/rate" component={RateCard} />
              <Route path="/ourrates" component={RateCard} />

              <Route path="/how-it-works" component={HowItWorks} />
              <Route path="/faq" component={FAQ} />
              <Route path="/contact-us" component={ContactUs} />
              <Route path="/blog/:id" component={BlogDetails} />
              <Route path="/blog" component={Blog} />
              <Route path="/shop" component={shop} />

              {/* <Route path='/existing-customer-package' component={ExistingCustomerPackageList} /> */}
              {/* <Route path='/new-customer-package' component={NewCustomerPackageList} /> */}

              <Route
                path="/package-listing"
                render={() =>
                  /* getData("session") ? (
                    <Route component={PackageList} />
                  ) : (
                      <Redirect to="/" />
                    ) */
                    <Route component={PackageList} />
                }
              />

              <Route
                path="/addon-listing"
                render={() =>
                  getData("session") ? (
                    <Route component={AddOnList} />
                  ) : (
                      <Redirect to="/" />
                    )
                }
              />

              <Route
                exact
                path="/service-booking"
                render={() =>
                  /* getData("session") ? (
                    <Route component={ServiceBooking} />
                  ) : (
                      <Redirect to="/" />
                    ) */
                    <Route component={ServiceBooking} />
                }
              />

              <Route
                path="/schedule-assessment"
                render={() =>
                  getData("session") ? (
                    <Route component={ScheduleAssessment} />
                  ) : (
                      <Redirect to="/" />
                    )
                }
              />

              <Route
                path="/payment-successfully"
                render={() =>
                  getData("session") ? (
                    <Route component={PaymentSuccessfullyDone} />
                  ) : (
                      <Redirect to="/" />
                    )
                }
              />

              <Route
                path="/paynow-qrcode"
                render={() =>
                  getData("session") ? (
                    <Route component={Paynowqr} />
                  ) : (
                      <Redirect to="/" />
                    )
                }
              />

              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route
                path="/terms-and-condition"
                component={TermsAndCondition}
              />
              <Route path="/terms-service" component={TermsOfService} />
              <Route path="/terms-of-use" component={TermsOfService} />
              <Route path="/service-crew" component={ServiceCrew} />
              <Route path="/servicecrew" component={ServiceCrew} />


              {/* <Route path='/my-account/my-profile' component={MyProfile} /> */}

              <Route
                path="/my-account/my-profile"
                render={() =>
                  getData("session") ? (
                    <Route component={MyProfile} />
                  ) : (
                      <Redirect to="/" />
                    )
                }
              />

              {/* <Route path='/my-account/my-booking' component={MyBooking} /> */}

              <Route
                path="/my-account/my-booking"
                render={() =>
                  getData("session") ? (
                    <Route component={MyBooking} />
                  ) : (
                      <Redirect to="/" />
                    )
                }
              />

              <Route
                path="/my-booking-details/:type/:refnum"
                render={() =>
                  getData("session") ? (
                    <Route component={MyBookingDetails} />
                  ) : (
                      <Redirect to="/" />
                    )
                }
              />

              {/* <Route path='/my-account/my-booking-history-details/:refnum' render={() => (
                            getData('session') ? (<Route component={MyBookingHistoryDetails} />)
                                : (<Redirect to="/" />)
                        )} /> */}

              {/* <Route path='/my-account/my-request' component={MyRequest} /> */}
              <Route
                path="/my-account/my-request"
                render={() =>
                  getData("session") ? (
                    <Route component={MyRequest} />
                  ) : (
                      <Redirect to="/" />
                    )
                }
              />
              <Route
                path="/my-request-quotation/:refnum"
                render={() =>
                  getData("session") ? (
                    <Route component={RequestQuotation} />
                  ) : (
                      <Redirect to="/" />
                    )
                }
              />

              {/* <Route path='/my-account/my-credits' component={MyCredits} /> */}
              <Route
                path="/my-account/my-credits"
                render={() =>
                  getData("session") ? (
                    <Route component={MyCredits} />
                  ) : (
                      <Redirect to="/" />
                    )
                }
              />
              {/* <Route path='/my-account/my-inbox' component={MyInbox} /> */}
              <Route
                path="/my-account/my-inbox"
                render={() =>
                  getData("session") ? (
                    <Route component={MyInbox} />
                  ) : (
                      <Redirect to="/" />
                    )
                }
              />
              {/* <Route path='/my-account/my-feedback' component={MyFeedback} /> */}
              <Route
                path="/my-account/my-feedback"
                render={() =>
                  getData("session") ? (
                    <Route component={MyFeedback} />
                  ) : (
                      <Redirect to="/" />
                    )
                }
              />

              <Route path="/404" component={Soft404} />
              <Route path="/thankyou" component={ThankYou} />

              <Route path="/our-services" component={OurServices} />
              {/* <Route path="/ourservices" component={OurServices} /> */}

              <Route exact path="/ourservices" render={props => (
                <RedirectWithStatus status={301} from="ourservices" to="/our-services" />
              )} />

              <Route exact path="/ourservices/:type" render={props => (
                <RedirectWithStatus status={301} from="/ourservices/:type" to={`/${props.match.params.type}`} />
              )} />

              <Route path="/:type" component={OurServicesDetails} />
              {/* 
              <Redirect to="/index" />
              <Redirect from="/" to="/index" /> */}


              <Redirect to="/" />
              <Redirect from="/index" to="/" />

              

            </Switch>
            <NotificationContainer />
          </Layout>
          {modal ? (
            <Modal
              size="lg"
              isOpen={modal}
              className={modalTransparent}
            // toggle={() => headerDispatch({ type: 'CLOSE' })}
            >
              {FnReturnComponent(visibleComponentType)}
            </Modal>
          ) : null}
        </React.Fragment>
      </>
    </GlobalContext.Provider>
  );
}
export default App