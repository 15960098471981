import React, { useState, useContext, } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Button, FormGroup, Input } from "reactstrap";
import { GlobalContext } from '../../App';
import { getData } from "../../utils/localStorage";
import { PostAPI } from "../../API/APICalling";


export default function MPUpdatePassword() {
    const globalContext = useContext(GlobalContext);
    const [data, setData] = useState({ encryptedOldPWD: '', encryptedNewPWD: '', confirmPWS: '' })
    const history = useHistory();


    const FnUpdatePassword = async (e) => {
        e.preventDefault();
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }

        if (!data.encryptedOldPWD) {
            globalContext.notifications('error', 'Current password is required.');
            return false;
        }
        if (!data.encryptedNewPWD) {
            globalContext.notifications('error', 'New password is required.');
            return false;
        }
        if (!data.confirmPWS) {
            globalContext.notifications('error', 'Confirm password is required.');
            return false;
        }
        if (data.encryptedNewPWD !== data.confirmPWS) {
            globalContext.notifications('error', 'New password and confirm password must be same.');
            return false;
        }

        else {
            if (session.email) {
                globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
                var result = await PostAPI(`/user/changepwd`, {
                    email: session.email,
                    encryptedOldPWD: data.encryptedOldPWD,
                    encryptedNewPWD: data.encryptedNewPWD
                });
                if (result) {
                    globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                    if (result.error) {
                        setData({});
                        globalContext.notifications('error', result.error);
                    }
                    else {
                        setData({});
                        globalContext.notifications('success', 'Password updated successfully.');
                    }
                }
            }
        }
    }


    return (
        <>
            <div className="card">
                <div className="card-header text-left title-2">
                    Update Password
                 </div>
                <div className="card-body">
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Input type="password" className="form-control" placeholder="Current Password*"
                                    maxLength={15}
                                    value={data.encryptedOldPWD || ''}
                                    onChange={e => {
                                        setData({ ...data, encryptedOldPWD: e.target.value });
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Input type="password" className="form-control" placeholder="New Password*"
                                    maxLength={15}
                                    value={data.encryptedNewPWD || ''}
                                    onChange={e => {
                                        setData({ ...data, encryptedNewPWD: e.target.value });
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Input type="text" className="form-control" placeholder="Confirm Password*"
                                    maxLength={15}
                                    value={data.confirmPWS || ''}
                                    onChange={e => {
                                        setData({ ...data, confirmPWS: e.target.value });
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                <div className="card-footer">
                    <Row>
                        <Col md="12" className="text-cenetr">
                            <FormGroup>
                                <Button className="btn btn-round btn-info"
                                    onClick={e => {
                                        FnUpdatePassword(e);
                                    }}
                                >UPDATE PASSWORD</Button>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}
