import React, { useEffect, useReducer, useContext } from "react";
import { useHistory } from "react-router-dom";
import Datetime from "react-datetime";
import { Row, Col, Button, FormGroup, Input, Label } from "reactstrap";
import { MyParticularsReducer, MyParticularsInitialState } from "../../reducer/MyAccountReducer";
import { getData } from "../../utils/localStorage";
import { GetAPI, PostAPI } from "../../API/APICalling";
import { ValidateEmail, pastDate, DateFormat } from "../../utils/common";
import { GlobalContext } from '../../App';


export default function MPMyPraticulars() {
    const globalContext = useContext(GlobalContext);
    const [state, dispatch] = useReducer(MyParticularsReducer, MyParticularsInitialState)
    const history = useHistory();

    useEffect(() => {
        FnGetMyParticulars();
    }, []);


    const FnGetMyParticulars = async () => {
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again');
            history.push("/");
        }
        else {
            globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
            var result = await GetAPI(`myaccount/myparticulars/${session.arnum}?email=${session.email}`);
            if (result) {
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                if (result.error) {
                    dispatch({ type: 'ERROR' });
                    globalContext.notifications('error', result.error);
                }
                else if (result.data) {
                    dispatch({ type: 'GET_SUCCESS', payload: result.data })
                }
            }
        }

    }

    const FnUpdateMyParticulars = async (e) => {
        e.preventDefault();
        var dob = '';
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }
        else {
            if (!state.myparticulars.firstname) {
                globalContext.notifications('error', 'First name is required.');
                return false
            }
            if (!state.myparticulars.lastname) {
                globalContext.notifications('error', 'Last name is required.');
                return false
            }
            if (!state.myparticulars.email) {
                globalContext.notifications('error', 'Email-Id is required.');
                return false
            }
            if (!ValidateEmail(state.myparticulars.email)) {
                globalContext.notifications('error', 'You have entered an invalid email address.');
                return false
            }
            if (!state.myparticulars.mobile) {
                globalContext.notifications('error', 'Phone number is required.');
                return false
            }

            if (DateFormat(state.myparticulars.dob, 'YYYYMMDD') !== DateFormat(new Date(), 'YYYYMMDD')) {
                dob = DateFormat(state.myparticulars.dob, 'YYYYMMDD');
            }
            if (state.myparticulars) {
                globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
                var result = await PostAPI(`myaccount/myparticulars/${session.arnum}?email=${session.email}`,
                    {
                        arnum: session.arnum,
                        firstname: state.myparticulars.firstname,
                        lastname: state.myparticulars.lastname,
                        email: state.myparticulars.email,
                        mobile: state.myparticulars.mobile,
                        title: state.myparticulars.title,
                        dob: dob
                    }
                );

                if (result) {
                    globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                    if (result.error) {
                        dispatch({ type: 'ERROR' });
                        globalContext.notifications('error', result.error);
                    }
                    else {
                        globalContext.notifications('success', 'My Particulars Updated successfully.');
                    }
                }
            }
        }

    }

    return (
        <>
            <div className="card">
                <div className="card-header text-left title-2">
                    My Particulars
                 </div>
                <div className="card-body">
                    <Row>
                        <Col md="6">
                            <FormGroup className="text-left">
                                <Label className="badge badge-neutral">Title:</Label>
                                <select className="form-control"
                                    value={state.myparticulars.title || ''}
                                    onChange={e => {
                                        dispatch({
                                            type: 'FIELD',
                                            field: 'title',
                                            value: e.currentTarget.value,
                                        })
                                    }}>
                                    <option value="Mr">Mr </option>
                                    <option value="Mrs">Mrs</option>
                                    <option value="Ms">Ms</option>
                                    <option value="Mdm">Mdm</option>
                                    <option value="Dr">Dr</option>
                                </select>
                            </FormGroup>
                        </Col>

                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup className="text-left">
                                <Label className="badge badge-neutral">First Name*:</Label>
                                <Input type="text" className="form-control" placeholder="First Name*"
                                    maxLength={20}
                                    value={state.myparticulars.firstname || ''}
                                    onChange={e => {
                                        e.preventDefault();
                                        dispatch({
                                            type: 'FIELD',
                                            field: 'firstname',
                                            value: e.currentTarget.value
                                        })
                                    }} />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup className="text-left">
                                <Label className="badge badge-neutral">Last Name*:</Label>
                                <Input type="text" className="form-control" placeholder="Last Name*"
                                    maxLength={20}
                                    value={state.myparticulars.lastname || ''}
                                    onChange={e => {
                                        e.preventDefault();
                                        dispatch({
                                            type: 'FIELD',
                                            field: 'lastname',
                                            value: e.currentTarget.value
                                        })
                                    }} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup className="text-left">
                                <Label className="badge badge-neutral">Phone Number^:</Label>
                                <Input type="number" className="form-control" placeholder="Phone Number*"
                                    maxLength={15}
                                    value={state.myparticulars.mobile || ''}
                                    onChange={e => {
                                        e.preventDefault();
                                        dispatch({
                                            type: 'FIELD',
                                            field: 'mobile',
                                            value: e.currentTarget.value
                                        })
                                    }} />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup className="text-left">
                                <Label className="badge badge-neutral">Email Address*:</Label>
                                <Input type="text" className="form-control" placeholder="Email Address*"
                                    maxLength={50}
                                    value={state.myparticulars.email || ''}
                                    onChange={e => {
                                        e.preventDefault();
                                        dispatch({
                                            type: 'FIELD',
                                            field: 'email',
                                            value: e.currentTarget.value
                                        })
                                    }} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup className="text-left">
                                <Label className="badge badge-neutral">Date of Birth:</Label>
                                <Datetime
                                    timeFormat={false}
                                    dateFormat="DD/MM/YYYY"
                                    inputProps={{ placeholder: "DD/MM/YYYY" }}
                                    closeOnSelect={true}
                                    value={DateFormat(state.myparticulars.dob, "DD/MM/YYYY")}
                                    isValidDate={pastDate}
                                    onChange={e => {
                                        dispatch({
                                            type: 'FIELD',
                                            field: 'dob',
                                            value: e._d
                                        })
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                <div className="card-footer">
                    <Row>
                        <Col md="12" className="text-cenetr">
                            <FormGroup>
                                <Button className="btn btn-round btn-info"
                                    onClick={e => {
                                        e.preventDefault();
                                        FnGetMyParticulars();
                                    }}
                                >RESET</Button>
                                <Button className="btn btn-round btn-info"
                                    onClick={e => {
                                        FnUpdateMyParticulars(e);
                                    }}
                                >UPDATE DETAILS</Button>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}
