
import Datetime from 'react-datetime'
const yesterday = Datetime.moment().subtract(1, 'day');
const tomorrow = Datetime.moment().add(1, 'day');
// export const validAssessmentDate = (current) => {
//     return current.isAfter(yesterday);
// };

//user in pick date time/ first step of booking.
export const validDate = (current, holidays, startAfterDays) => {
    startAfterDays = startAfterDays ? parseInt(startAfterDays - 1 || 0) : 0;
    //console.log(startAfterDays);
    const afterdays = Datetime.moment().add(startAfterDays, 'day');
    if (holidays && holidays.length !== 0) {
        return !holidays.includes(DateFormat(current, 'DD-MMM-YYYY')) && current.isAfter(afterdays)
    }
    else
        return current.isAfter(afterdays);
};

export const validDate2 = (current, startdate, holidays, startAfterDays) => {
    // console.log(startdate);
    startAfterDays = startAfterDays ? parseInt(startAfterDays - 1 || 0) : 0;
    //console.log(startAfterDays);
    const afterdays = Datetime.moment(startdate).add(startAfterDays, 'day');
    if (holidays && holidays.length !== 0) {
        return !holidays.includes(DateFormat(current, 'DD-MMM-YYYY')) && current.isAfter(afterdays);
    }
    else
        return current.isAfter(afterdays);
};

//used in mybooking 
export const pastDate = (current) => {
    // debugger
    // console.log(current);
    const today = Datetime.moment()
    return current.isBefore(today)
};

export const beetweenDate = (current, start) => {
    // debugger
    // console.log(Datetime.moment(current).format('DD MMM YYYY'));
    // console.log(Datetime.moment(start).format('DD MMM YYYY'));
    const today = Datetime.moment()
    //return current.from(start)
    // console.log(current.isBefore(today)  && current.isAfter(start));
    return current.isBefore(today) && current.isAfter(start)
};



// export const pastDate2 = (current, startdate) => {
//     // console.log(startdate);
//     const today = Datetime.moment(startdate)
//     return current.isAfter(yesterday);
// };


export const getGreetingTime = (st, et) => {
    var sd = new Date('1/1/1999 ' + st)
    // var start = new Date ('1/1/1999 ' + et)
    var start = sd.getHours();

    if (start < 12) {
        return 'Morning'
    } else if (start < 18) {
        return 'Afternoon'
    } else {
        return 'Evening'
    }
}
// export const DateFormat = (date) => {
//     try {
//         // debugger;
//         // var newDate = new Date(date);
//         // var dd = newDate.getDate();
//         // var mm = newDate.getMonth() + 1;
//         // var yyyy = newDate.getFullYear();
//         // if (dd < 10) {
//         //     dd = '0' + dd;
//         // }
//         // if (mm < 10) {
//         //     mm = '0' + mm;
//         // }
//         // return dd + '/' + mm + '/' + yyyy;
//         return Datetime.moment(date).format('DD MMM YYYY');
//     }
//     catch (e) {
//         console.log(e);
//         return date;
//     }
// }

export const DateFormat = (date, format) => {
    try {
        return Datetime.moment(date).format(format);
    }
    catch (e) {
        console.log(e);
        return date;
    }
}

export const ConvertUrlToFileName = (url) => {
    try {
        return url.split('/').pop()
    }
    catch (e) {
        console.log(e);
        return url;
    }
}

// export const DateTimeFormat = (date) => {
//     try {
//         return Datetime.moment(date).format('DD MMM YYYY h:mm a');
//     }
//     catch (e) {
//         console.log(e);
//         return date;
//     }
// }

// export const DateDayFormat = (date) => {
//     try {
//         return Datetime.moment(date).format('DD MMM YYYY (dddd)');
//     }
//     catch (e) {
//         console.log(e);
//         return date;
//     }
// }

export const TimeFormat = (time) => {
    var date = new Date('1/1/1999 ' + time)
    try {

        return Datetime.moment(date).format('HH:mm');
    }
    catch (e) {
        console.log(e);
        return time;
    }
}

export const ReadRate = (rate) => {
    try {
        return {
            beforerate: rate.substring(0, rate.indexOf("**")),
            rate: (rate.substring(rate.substring(0, rate.indexOf("**")).length, ((rate.length) - (rate.split('**').pop().length)))).replace(/\x2a/g, ""),
            afterrate: rate.split('**').pop()
        };
    }
    catch (e) {
        console.log(e);
        return { beforerate: '', rate: '', afterrate: '' }
    }
}

export const ValidateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return true
    }
    return false
}

export const isiOS = () => {
    return !!navigator.platform.match(/iPhone|iPod|iPad|MacIntel/)
}