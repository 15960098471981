import React from 'react';
// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import VersionControl from "components/Footers/VersionControl";
import FooterCorporate from "components/Footers/FooterCorporate.js";
// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/css/react-datepicker.css";
import "assets/css/ResponsiveTable.css";
import "assets/scss/now-ui-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/nucleo-icons-page-styles.css";
import "Site.css";
import "assets/demo/nucleo-icons-page-styles.css";
import "assets/css/notifications.css";
import AcceptCookies from 'components/Footers/AcceptCookies';
import Annoucement from 'components/Headers/AnnoucementBar';

function Layout(props) {

    return (
        <>
            <div>                               
                <IndexNavbar />               
                <div className="wrapper">
                    {props.children}
                    <FooterCorporate />
                    <Footer />
                    <VersionControl />
                </div>
                <AcceptCookies />
            </div>
        </>
    );
}

export default React.memo(Layout)