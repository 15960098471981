import React, { useEffect, useContext, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
// import DatePicker from "react-datepicker";
// //help https://reactdatepicker.com/#example-range-quarter-picker
// reactstrap components
//import { Link } from "react-router-dom";
//help https://www.cssscript.com/multi-step-form-bootstrap/
// https://codepen.io/aniket/pen/whDqz

// react plugins that creates an input with a date picker
import Datetime from "react-datetime";
import { ServiceBookingContext } from '../../views/ServiceBooking';
import { GlobalContext } from '../../App';
import { getData } from "../../utils/localStorage";
//import { PDTInitialState, PDTReducer } from "reducer/bookingReducer";
import { validDate, validDate2, getGreetingTime } from "../../utils/common";
import { GetAPI } from "../../API/APICalling";
import { TimeFormat } from "../../utils/common";
import { DateFormat } from "../../utils/common";

function PickDateTime(props) {
    const sbContext = useContext(ServiceBookingContext);
    const globalContext = useContext(GlobalContext);
    //const [state, dispatch] = useReducer(PDTReducer, PDTInitialState);
    const history = useHistory();
    const [holidays, setHoliday] = useState([]);
    const [startAfterDays, setStartAfterDays] = useState(0);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        const session = getData('session');
        if (!session) {
            //globalContext.notifications('error', 'Your session has been expired, Please login again.');
            //history.push("/");
            FnGetHolidaysList();
        }
        else {
            FnGetHolidaysList();
            // console.log('PickDateTime');
            //console.log(sbContext.BookingState.PackageDetails)
            //dispatch({ type: 'SET_DATA', payload: sbContext.BookingState.PackageDetails });//setting all data in local reducer
            // if (sbContext.BookingState.PackageDetails) {
            //     if (sbContext.BookingState.PackageDetails.jobnum) {
            //         FnGetTimeslots(new Date())
            //     }
            //     else {
            //         globalContext.notifications('error', 'Oops ! Something went wrong.');
            //         history.push("/");
            //     }
            // }
            // else
            //     history.push("/");
        }
    }, [])

    const FnGetTimeslots = async (date, currmoment=null, startAfterDays=0) => {
        startAfterDays = startAfterDays ? parseInt(startAfterDays - 1 || 0) : 0;
        const afterdays = Datetime.moment().add(startAfterDays, 'day');

        if (currmoment && !currmoment.isAfter(afterdays)) {
            sbContext.BookingDispatch({ type: 'TIME_SLOT_SUCCESS', payload: [] });
        }
        
        else if (sbContext.BookingState.PackageDetails) {
            if (sbContext.BookingState.PackageDetails.jobnum) {
                globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
                var result = await GetAPI(`booking/timeslots/${sbContext.BookingState.PackageDetails.jobnum}?date=${DateFormat(date, 'YYYY-MM-DD')}`);
                if (result) {
                    globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                    if (result.error) {
                        sbContext.BookingDispatch({ type: 'ERROR' });
                        globalContext.notifications('error', result.error);
                    }
                    else if (result.data) {

                        if (sbContext.BookingState.PackageDetails.shiftnum)
                            sbContext.BookingDispatch({ type: 'FIELD', field: 'shiftnum', value: sbContext.BookingState.PackageDetails.shiftnum })

                        if (sbContext.BookingState.PackageDetails.shiftnum2)
                            sbContext.BookingDispatch({ type: 'FIELD', field: 'shiftnum2', value: sbContext.BookingState.PackageDetails.shiftnum2 })

                        //sbContext.BookingDispatch({ type: 'FIELD', field: 'shiftnum', value: '' })
                        sbContext.BookingDispatch({ type: 'TIME_SLOT_SUCCESS', payload: result.data });
                    }
                }
            }
            else {
                globalContext.notifications('error', 'Oops ! Something went wrong.');
                history.push("/");
            }
        }
        else
            history.push("/");

    };

    const FnGetHolidaysList = async () => {
        globalContext.loaderDispatch({ type: 'START_LOADING', payload: false });
        var result = await GetAPI(`booking/holidaylist/${sbContext.BookingState.PackageDetails.matnum}`);
        if (result) {
            globalContext.loaderDispatch({ type: 'STOP_LOADING' });
            if (result.data) {
                if (sbContext.BookingState.PackageDetails.guid)
                    FnGetTimeslots(new Date(sbContext.BookingState.PackageDetails.startdate));
                else {
                    FnGetTimeslots(Datetime.moment().add(result.data.startAfterDays, 'day'));
                    sbContext.BookingDispatch({ type: 'STARDATE_FIELD', field: 'startdate', value: Datetime.moment().add(result.data.startAfterDays, 'day') })
                }
                if (result.data.holidays) {
                    setHoliday(
                        result.data.holidays.map(item => {
                            return DateFormat(item.pubDate, 'DD-MMM-YYYY')
                        })
                    );
                }
                setStartAfterDays(result.data.startAfterDays);
            }
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (!sbContext.BookingState.PackageDetails.startdate) {
            globalContext.notifications('error', 'Please choose date.');
            return false
        }
        if (!sbContext.BookingState.PackageDetails.shiftnum) {
            globalContext.notifications('error', 'Please choose shift.');
            return false
        }
        if (sbContext.BookingState.PackageDetails.jobnum2 && !sbContext.BookingState.PackageDetails.startdate2) {
            globalContext.notifications('error', 'Please choose pick date.');
            return false
        }
        if (sbContext.BookingState.PackageDetails.jobnum2 && !sbContext.BookingState.PackageDetails.shiftnum2) {
            globalContext.notifications('error', 'Please choose pick shift.');
            return false
        }
        const session = getData('session')
        if (!session)
        {
            //globalContext.notifications('error', 'Please login to continue.');
            globalContext.headerDispatch({ type: 'HEADERLOGIN' });
            return false
        }
        else
        {
            sbContext.ServiceDispatch({ type: 'BOOKING_INFORMATION' })
        }

    }
    return (
        <>
            <Row>
                <Col className="ml-auto mr-auto pt-10 pb-30" xl="9" lg="10" md="12">
                    <div className="card">
                        <div className="card-header text-left title-2">
                            Date & Start Time
                                 </div>
                        <div className="card-body p-0-xs">
                            <Container>
                                <Row>
                                    <Col lg="5" md="12" sm="12" xs="12">
                                        <div className="text-center">
                                            <Datetime
                                                timeFormat={false}
                                                dateFormat="DD/MM/YYYY"
                                                inputProps={{ placeholder: "DD/MM/YYYY" }}
                                                className="rdtOpen inlineDatepicker"
                                                closeOnSelect={true}
                                                value={new Date(sbContext.BookingState.PackageDetails.startdate)}
                                                isValidDate={e => validDate(e, holidays, 0)}
                                                onChange={e => {
                                                    sbContext.BookingDispatch({
                                                        type: 'STARDATE_FIELD',
                                                        field: 'startdate',
                                                        value: e._d,
                                                    })
                                                    FnGetTimeslots(e._d,e, startAfterDays)
                                                }
                                                }
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="7" md="12" sm="12" xs="12">
                                        <p className="title-3">What time would you like us to come?</p>
                                        <p className="description">Select a service time slot </p>
                                        {sbContext.BookingState.timeslots &&
                                            <React.Fragment>
                                                {sbContext.BookingState.timeslots.length == 0 &&
                                                    <React.Fragment>
                                                        <p className="description">For assistance, please call our office at +44 20 8168 9818 </p>
                                                    </React.Fragment>
                                                }
                                            </React.Fragment>
                                        }
                                        <Row>
                                            {sbContext.BookingState.timeslots &&
                                                <React.Fragment>
                                                    {sbContext.BookingState.timeslots.length !== 0 &&
                                                        <React.Fragment>
                                                            {
                                                                sbContext.BookingState.timeslots.map((item, index) => (
                                                                    <Col key={index} xl="4" lg="4" md="4" sm="12" xs="12" className="text-center">
                                                                        <button
                                                                            className={sbContext.BookingState.PackageDetails.shiftnum === item.shiftnum ? "btn btn-time-slot active" : "btn btn-time-slot"}
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                // setTimeSlot(item.shiftnum);
                                                                                sbContext.BookingDispatch({
                                                                                    type: 'FIELD',
                                                                                    field: 'shiftnum',
                                                                                    value: item.shiftnum,
                                                                                })
                                                                            }}>
                                                                            <b className="mb-10">{getGreetingTime(item.starttime, item.endtime)}</b>
                                                                            <span>{TimeFormat(item.starttime)} - {TimeFormat(item.endtime)}</span>
                                                                        </button>
                                                                    </Col>
                                                                ))
                                                            }
                                                        </React.Fragment>
                                                    }
                                                </React.Fragment>
                                            }

                                        </Row>
                                    </Col>
                                </Row>
                                {sbContext.BookingState.PackageDetails.jobnum2 &&
                                    <React.Fragment>
                                        <hr></hr>
                                        <Row>
                                            <Col lg="5" md="12" sm="12" xs="12">
                                                <div className="text-center">
                                                    <Datetime
                                                        timeFormat={false}
                                                        dateFormat="DD/MM/YYYY"
                                                        inputProps={{ placeholder: "DD/MM/YYYY" }}
                                                        className="rdtOpen inlineDatepicker"
                                                        closeOnSelect={true}
                                                        value={new Date(sbContext.BookingState.PackageDetails.startdate2)}
                                                        isValidDate={e => validDate2(e, (new Date(sbContext.BookingState.PackageDetails.startdate)), holidays, startAfterDays)}
                                                        onChange={e =>
                                                            sbContext.BookingDispatch({
                                                                type: 'FIELD',
                                                                field: 'startdate2',
                                                                value: e._d,
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg="7" md="12" sm="12" xs="12">
                                                <p className="title-3">What time would you like us to come?</p>
                                                <p className="description">Select a service time slot </p>
                                                <Row>
                                                    {sbContext.BookingState.timeslots &&
                                                        <React.Fragment>
                                                            {sbContext.BookingState.timeslots.length !== 0 &&
                                                                <React.Fragment>
                                                                    {
                                                                        sbContext.BookingState.timeslots.map((item, index) => (
                                                                            <Col key={index} xl="4" lg="4" md="4" sm="12" xs="12" className="text-center">
                                                                                <button
                                                                                    className={sbContext.BookingState.PackageDetails.shiftnum2 === item.shiftnum ? "btn btn-time-slot active" : "btn btn-time-slot"}
                                                                                    onClick={e => {
                                                                                        e.preventDefault();
                                                                                        // setTimeSlot(item.shiftnum);
                                                                                        sbContext.BookingDispatch({
                                                                                            type: 'FIELD',
                                                                                            field: 'shiftnum2',
                                                                                            value: item.shiftnum,
                                                                                        })
                                                                                    }}>
                                                                                    <b className="mb-10">{getGreetingTime(item.starttime, item.endtime)}</b>
                                                                                    <span>{item.starttime} - {item.endtime}</span>
                                                                                </button>
                                                                            </Col>
                                                                        ))
                                                                    }
                                                                </React.Fragment>
                                                            }
                                                        </React.Fragment>
                                                    }

                                                </Row>
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                }

                            </Container>
                        </div>
                        <div className="card-footer text-center">
                            <button
                                className={"btn btn-round btn-info"}
                                onClick={e => {

                                    // props.Function.FnSetRenderComponentType("BOOKING_INFORMATION");
                                    // sbContext.ServiceDispatch({ type: 'BOOKING_INFORMATION' });
                                    onSubmit(e);
                                }}>
                                CONTINUE
                             </button>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default PickDateTime;
