import React, { useContext, useEffect } from "react";
import { BookingDetailsContext } from "../../views/MyAccount/MyBookingDetails";
import { useHistory } from "react-router-dom";
import { FormGroup, Row, Col, Button, ModalBody, ModalHeader, Input } from "reactstrap";
import { GlobalContext } from '../../App';
import { getData } from "../../utils/localStorage";
import { PostAPI } from "../../API/APICalling";

export default function ModalMBRefund(props) {
    const bdContext = useContext(BookingDetailsContext);
    const globalContext = useContext(GlobalContext);
    const history = useHistory();

    useEffect(() => {
        document.querySelector('.modal').scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        if (bdContext.BDState.BookingDetails.length > 0) {
            if (bdContext.BDState.BookingDetails[0].refnum) {
                bdContext.BDDispatch({ type: 'REFUND_FIELD', field: 'refnum', value: bdContext.BDState.BookingDetails[0].refnum })
            }
            else {
                globalContext.notifications('error', 'Oops ! Something went wrong.');
                bdContext.BDDispatch({ type: 'CLOSE_MODAL' });
            }
        }
        else {
            globalContext.notifications('error', 'Oops ! Something went wrong.');
            bdContext.BDDispatch({ type: 'CLOSE_MODAL' });
        }
    }, []);

    const onSubmit = async (e) => {
        e.preventDefault()
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }
        else {
            if (!bdContext.BDState.refund.reason) {
                globalContext.notifications('error', 'Please select reason of refund.');
                return false
            }
            if (bdContext.BDState.refund.refnum) {
                globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
                var result = await PostAPI(`mybooking/details/refund/${bdContext.BDState.refund.refnum}`, bdContext.BDState.refund
                );
                if (result) {
                    globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                    if (result.error) {
                        bdContext.BDDispatch({ type: 'ERROR' });
                        globalContext.notifications('error', result.error);
                    }
                    else {
                        globalContext.notifications('success', 'Your request has been submitted successfully.');
                        bdContext.BDDispatch({ type: 'REFUND_SUCCESS' });
                        bdContext.BDDispatch({ type: 'CLOSE_MODAL' });
                    }
                }
            }
        }

    }
    return (
        <>
            <div className="change-service-date-modal">
                <div className="col-md-10">
                    <ModalHeader toggle={() => bdContext.BDDispatch({ type: 'CLOSE_MODAL' })}>
                    </ModalHeader>
                </div>
                <ModalBody className="p-0 text-center">
                    <div className="card col-md-8">
                        <div className="card-header text-center title-2">
                            Request Refund
                         </div>
                        <div className="card-body">
                            <Row>
                                <Col md="12">
                                    <p className="title-3">Reason for refund</p>
                                    <FormGroup>
                                        <select className="form-control"
                                            style={{
                                                textAlignLast: "center"
                                            }}
                                            value={bdContext.BDState.refund.reason}
                                            onChange={e => {
                                                e.preventDefault();
                                                bdContext.BDDispatch({
                                                    type: 'REFUND_FIELD',
                                                    field: 'reason',
                                                    value: e.currentTarget.value
                                                })
                                            }}
                                        >
                                            <option value="">-Please Select-</option>
                                            <option value="Reason 1">Reason 1</option>
                                            <option value="Reason 2">Reason 2</option>
                                            <option value="Reason 3">Reason 3</option>
                                            <option value="Reason 4">Reason 4</option>
                                        </select>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <hr></hr>
                            <Row>
                                <Col md="12" className="text-left">
                                    <p className="title-3">Provide more information</p>
                                    <p className="description">Let us know more so we can investigate the case properly</p>
                                    <FormGroup>
                                        <Input type="textarea" className="form-control" placeholder="Write remarks"
                                            maxLength={500}
                                            value={bdContext.BDState.refund.remark}
                                            onChange={e => {
                                                e.preventDefault();
                                                bdContext.BDDispatch({
                                                    type: 'REFUND_FIELD',
                                                    field: 'remark',
                                                    value: e.currentTarget.value
                                                })
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                        <div className="card-footer text-center">
                            <p className="description">We will confirm your refund request within X hours. Thank you for your feedback.</p>
                            <Button
                                disabled={bdContext.BDState.refund.reason ? false : true}
                                onClick={e => {
                                    // e.preventDefault();
                                    // bdContext.BDDispatch({ type: 'CLOSE_MODAL' })
                                    onSubmit(e);
                                }}
                                className={"btn btn-round btn-info"}
                            >
                                SUBMIT
                              </Button>
                        </div>
                    </div>
                </ModalBody>
            </div>
        </>
    )
}