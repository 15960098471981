import React from "react";

// reactstrap components
import { Link } from "react-router-dom";

import {
    Container,
    Row,
    Col,
} from "reactstrap";
// core components

export default React.memo(function MyAccountHeader() {
    return (
        <>
            <Container className="mt-0">
                <div className="ml-auto mr-auto">
                    <div className="text-center">
                        <h2 className="title">My Account</h2>
                    </div>
                    <hr className="hr-large"></hr>
                </div>
                <div className="myaccount-box">
                    <Row>
                        <Col md="2" sm="6" xs="6" className="myaccount-icon">
                            <Link to="/my-account/my-profile">
                                <img
                                    className="d-none d-sm-block"
                                    alt="..."
                                    src={require("../../assets/img/icon-account-profile.png")}
                                ></img>
                                My Profile
                              </Link>
                        </Col>
                        <Col md="2" sm="6" xs="6" className="myaccount-icon">
                            <Link to="/my-account/my-booking">
                                <img
                                    className="d-none d-sm-block"
                                    alt="..."
                                    src={require("../../assets/img/icon-account-booking.png")}
                                ></img>
                                 My Booking
                              </Link>
                        </Col>
                        <Col md="2" sm="6" xs="6" className="myaccount-icon">
                            <Link to="/my-account/my-request">
                                <img
                                    className="d-none d-sm-block"
                                    alt="..."
                                    src={require("../../assets/img/icon-account-request.png")}
                                ></img>
                                 My Request
                              </Link>
                        </Col>
                        <Col md="2" sm="6" xs="6" className="myaccount-icon">
                            <Link to="/my-account/my-credits">
                                <img
                                    className="d-none d-sm-block"
                                    alt="..."
                                    src={require("../../assets/img/icon-account-credits.png")}
                                ></img>
                                 My Credits
                              </Link>
                        </Col>
                        <Col md="2" sm="6" xs="6" className="myaccount-icon">

                            <Link to="/my-account/my-inbox">
                                <img
                                    className="d-none d-sm-block"
                                    alt="..."
                                    src={require("../../assets/img/icon-account-inbox.png")}
                                ></img>
                                 My Inbox
                              </Link>
                        </Col>
                        <Col md="2" sm="6" xs="6" className="myaccount-icon">
                            <Link to="/my-account/my-feedback">
                                <img
                                    className="d-none d-sm-block"
                                    alt="..."
                                    src={require("../../assets/img/icon-account-feedback.png")}
                                ></img>
                                 My Feedback
                              </Link>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    );
});
