import React, { useContext, useEffect, forwardRef, useImperativeHandle } from 'react';
import { ServiceBookingContext } from '../../views/ServiceBooking';
import CreditCardInput from 'react-credit-card-input';

const CheckoutFormUKTakePay = forwardRef((props, ref) => {

    
    const sbContext = useContext(ServiceBookingContext);
    const handleCardNumberChange = (e)=>{
        sbContext.CheckoutDispatch({
            type: 'FIELD',
            field: 'uktakepayCardNumber',
            value: e.currentTarget.value,
        })
    }
    const handleCardExpiryChange = (e)=>{
        sbContext.CheckoutDispatch({
            type: 'FIELD',
            field: 'uktakepayExpiry',
            value: e.currentTarget.value,
        })
    }
    const handleCardCVCChange = (e)=>{
        sbContext.CheckoutDispatch({
            type: 'FIELD',
            field: 'uktakepayCvc',
            value: e.currentTarget.value,
        })
    }
    return (
        <>
            <CreditCardInput
                cardNumberInputProps={{ value: sbContext.CheckoutDispatch.uktakepayCardNumber,onChange: handleCardNumberChange }}
                cardExpiryInputProps={{ value: sbContext.CheckoutDispatch.uktakepayExpiry,onChange: handleCardExpiryChange  }}
                cardCVCInputProps={{ value: sbContext.CheckoutDispatch.uktakepayCvc,onChange: handleCardCVCChange }}
                fieldClassName="input"
            />

        </>
    )

})


export default CheckoutFormUKTakePay;