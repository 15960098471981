/*eslint-disable*/
import React, { useContext, createRef, useEffect, useState } from "react";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Navbar,
  Nav,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators

} from "reactstrap";
// core components

import { GlobalContext } from '../../App.js';
import * as constant from '../../constant';

// const items = [
//   {
//     src: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg",
//     altText: "header 1",
//     caption: "header 1"
//   },
//   {
//     src: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026485.jpg",
//     altText: "header 2",
//     caption: "header 3"
//   },
//   {
//     src: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1589816138.jpg",
//     altText: "header 3",
//     caption: "header 3"
//   }
// ];

function IndexHeader() {
  let pageHeader = createRef();
  const globalContext = useContext(GlobalContext);
  const [isFetching, setIsFetching] = useState(true);
  const [drpData, setDrpData] = useState([]);
  const [slider, setSlider] = useState([]);
  const [sliderLength, setSliderLength] = useState(0);




  // useEffect(() => {
  //   if (window.innerWidth > 991) {
  //     const updateScroll = () => {
  //       let windowScrollTop = window.pageYOffset / 3;
  //       pageHeader.current.style.transform =
  //         "translate3d(0," + windowScrollTop + "px,0)";
  //     };
  //     window.addEventListener("scroll", updateScroll);
  //     return function cleanup() {
  //       window.removeEventListener("scroll", updateScroll);
  //     };
  //   }
  // });



  useEffect(() => {
    if (globalContext.commonState.homeheader.slider) {
      setSlider(globalContext.commonState.homeheader.slider);
      setSliderLength(parseInt(globalContext.commonState.homeheader.slider.length) || 0);
    }
  }, [globalContext.commonState.homeheader]);


  useEffect(() => {
    setDrpData(globalContext.commonState.menuitems.map((item, key1) => (
      <UncontrolledDropdown nav inNavbar key={key1.toString()}>
        <DropdownToggle
          caret
          className="btn-round"
          color="default"
          data-toggle="dropdown"
          btn-value={item.svcnum}
        >
          {item.wname}
        </DropdownToggle>
        <DropdownMenu className="sub-menu-container">
          {item.subCategories.map((item2, key2) => (
            <DropdownItem
              href="#pablo"
              onClick={e => e.preventDefault()}
              key={key2.toString()}
            >
              <Row style={{ width: "100%" }} onClick={() => globalContext.headerDispatch({ type: "SUB_MENU", title: item2.wname, subsvcnum: item2.subsvcnum })}>
                <div className="menu-image">
                  <img
                    alt="..."
                    src={item2.directory ? item2.directory : constant.AVTAR}
                  ></img>
                </div>
                <div className="menu-desc">
                  <div className="text-left">
                    <div>
                      <div className="menu-title">{item2.wname}</div>
                      <div className="menu-content">
                        {item2.wdesc}
                      </div>
                      <div className="menu-link">More Info</div>
                    </div>
                  </div>
                </div>
              </Row>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown >
    )))
  }, [globalContext.commonState.menuitems]);



  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === sliderLength - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? sliderLength - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  return (
    <>
      <div className="page-header clear-filter" filter-color="white">
        {/* <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("../../assets/img/banner-slide-1.jpg") + ")"
          }}
          ref={pageHeader}
        ></div> */}

        <Row className="justify-content-center"
          style={{
            backgroundImage: "https://biz-content.s3-ap-southeast-1.amazonaws.com/1591026471.jpg"
          }}

        >
          <Col md="12">
            <Carousel
              activeIndex={activeIndex}
              next={next}
              previous={previous}
            >
              <CarouselIndicators
                items={slider}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}

              />
              {slider.map((item, index) => (
                <CarouselItem
                  onExiting={onExiting}
                  onExited={onExited}
                  key={index}
                  className="home-carousel-inner"
                >
                  <img src={item.image} alt={"header" + (index + 1)} />
                  {/* <div className="carousel-caption d-none d-md-block"> */}
                  {/* <div className="carousel-caption">
                    <h5>{item.caption}</h5>
                  </div> */}
                </CarouselItem>
              ))}

              <a
                className="carousel-control-prev"
                data-slide="prev"
                href="#pablo"
                onClick={e => {
                  e.preventDefault();
                  previous();
                }}
                role="button"
              >
                <i className="now-ui-icons arrows-1_minimal-left"></i>
              </a>
              <a
                className="carousel-control-next"
                data-slide="next"
                href="#pablo"
                onClick={e => {
                  e.preventDefault();
                  next();
                }}
                role="button"
              >
                <i className="now-ui-icons arrows-1_minimal-right"></i>
              </a>
            </Carousel>
            <div className="home-carousel-caption">
              {/* <h5>{item.caption}</h5> */}
              <div id="menu-dropdown">
                <div className="title">
                  <div id="navbar-menu">
                    <Navbar className="navbar-default" expand="lg">
                      <Nav className="adjust-menu-space" navbar>
                        {drpData}
                      </Nav>
                    </Navbar>
                  </div>
                </div>
              </div>
              <a href="https://uk.trustpilot.com/review/whissh.co.uk"><img src="https://i.pinimg.com/originals/fd/53/5d/fd535d38b57db4189c1664049d8c5320.png" width="100" height="50"></img></a>
            </div>
          </Col>
        </Row>

      </div>
    </>
  );
}

export default React.memo(IndexHeader);
