import React, { useContext, useEffect } from 'react';
import { Button, ModalBody, Row, Col, Container } from 'reactstrap';
import { GlobalContext } from '../../App.js';


function ModalChooseSerivice() {
  const globalContext = useContext(GlobalContext);
  const FnNew = () => {
    // globalContext.authState.isLoggedIn ?
      // globalContext.headerDispatch({ type: 'CHOOSELOCATION_NEWUSER' }) : globalContext.headerDispatch({ type: 'SIGNUP' });
      globalContext.headerDispatch({ type: 'SIGNUP' });
  }

  const FnOld = () => {
    // globalContext.authState.isLoggedIn ?
      // globalContext.headerDispatch({ type: 'CHOOSELOCATION_OLDUSER' }) : globalContext.headerDispatch({ type: 'LOGIN' });
      globalContext.headerDispatch({ type: 'LOGIN' });
  }
  useEffect(() => {
    document.querySelector('.modal').scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [])

  return (
    <>
      <div className="choose-service-modal pt-80">
        <ModalBody>
          <Container className="first-half">
            <Row>
              <div className="left-side">
                <div className="text-left">
                  <b className="title-2">Hi, Welcome to Whissh!</b>
                  <p className="description"> If you are new to our services, please click ‘Guide me’ and we will assist you in finding the most suitable  services.
                    Or Login for direct booking if you already have an account.</p>
                </div>
              </div>
              <div className="right-side">
                <img
                  alt="..."
                  src={require("../../assets/img/avatar-butler.png")}
                  className="butler"
                ></img>
              </div>
            </Row>
          </Container>

          <Container className="second-half">
            <Row>
              <hr style={{ width: "45%" }}></hr>
            </Row>
            <Row>
              <Col xl="10" lg="10" md="12" className="pb-20 pr-30 pl-30 ml-auto mr-auto text-center">
                <Row>
                  <Col xl="6" lg="6" md="12" sm="12" className="text-center pl-5 pr-5">
                    <Button className="btn btn-service" onClick={() => FnNew()}>
                      <b className="mb-10">Guide me!</b>
                         I am not familiar with services.
                       </Button>
                  </Col>
                  <Col xl="6" lg="6" md="12" sm="12" className="text-center pl-5 pr-5">
                    <Button className="btn btn-service" onClick={() => FnOld()}>
                      <b className="mb-10">No,</b>
                          I already have service in mind
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </div>
    </>
  );
}

export default ModalChooseSerivice;