import React, { useContext, useEffect, useState } from "react";
import { BookingDetailsContext } from "../../views/MyAccount/MyBookingDetails";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Button, ModalBody, ModalHeader } from "reactstrap";

import { GlobalContext } from '../../App';
import { getData } from "../../utils/localStorage";
import Datetime from "react-datetime";
import { GetAPI } from "../../API/APICalling";
import { validDate, getGreetingTime } from "../../utils/common";
import { PostAPI } from "../../API/APICalling";
import { TimeFormat } from "../../utils/common";
import { DateFormat } from "../../utils/common";

export default function ModalMBChangeDate(props) {
    const bdContext = useContext(BookingDetailsContext);
    const globalContext = useContext(GlobalContext);
    const history = useHistory();
    const [holidays, setHoliday] = useState([]);
    const [startAfterDays, setStartAfterDays] = useState(0);

    useEffect(() => {
        document.querySelector('.modal').scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        FnGetHolidaysList();

        if (bdContext.BDState.BookingDetails.length > 0) {
            if (bdContext.BDState.BookingDetails[0].refnum) {
                //FnGetTimeslots(bdContext.BDState.BookingDetails[0].jobnum);

                bdContext.BDDispatch({ type: 'CHANGE_DATE_FIELD', field: 'refnum', value: bdContext.BDState.BookingDetails[0].refnum })
            }
            else {
                globalContext.notifications('error', 'Oops ! Something went wrong.');
                bdContext.BDDispatch({ type: 'CLOSE_MODAL' });
            }
        }
        else {
            globalContext.notifications('error', 'Oops ! Something went wrong.');
            bdContext.BDDispatch({ type: 'CLOSE_MODAL' });
        }
    }, []);

    const FnGetTimeslots = async (date) => {
        globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
        // var result = await GetAPI(`booking/timeslots/${jobnum}`);
        var result = await GetAPI(`booking/timeslots/${bdContext.BDState.BookingDetails[0].jobnum}?date=${DateFormat(date, 'YYYY-MM-DD')}`)
        if (result) {
            globalContext.loaderDispatch({ type: 'STOP_LOADING' });
            if (result.error) {
                bdContext.BDDispatch({ type: 'ERROR' });
                globalContext.notifications('error', result.error);
            }
            else if (result.data) {
                bdContext.BDDispatch({ type: 'CHANGE_DATE_FIELD', field: 'shiftnum', value: '' })
                bdContext.BDDispatch({ type: 'TIME_SLOT_SUCCESS', payload: result.data });
            }
        }
    };

    const FnGetHolidaysList = async () => {
        globalContext.loaderDispatch({ type: 'START_LOADING', payload: false });
        var result = await GetAPI(`booking/holidaylist/${bdContext.BDState.BookingDetails[0].matnum}`);
        if (result) {
            globalContext.loaderDispatch({ type: 'STOP_LOADING' });
            if (result.data) {
                FnGetTimeslots(Datetime.moment().add(result.data.startAfterDays, 'day'));
                bdContext.BDDispatch({ type: 'CHANGE_DATE_FIELD', field: 'requestDate', value: Datetime.moment().add(result.data.startAfterDays, 'day') })
                if (result.data.holidays) {
                    setHoliday(
                        result.data.holidays.map(item => {
                            return DateFormat(item.pubDate, 'DD-MMM-YYYY')
                        })
                    );
                }
                setStartAfterDays(result.data.startAfterDays);
            }
        }
    };

    const onSubmit = async () => {

        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }
        else {
            if (!bdContext.BDState.changeDate.requestDate) {
                globalContext.notifications('error', 'Please choose date.');
                return false
            }
            if (!bdContext.BDState.changeDate.shiftnum) {
                globalContext.notifications('error', 'Please choose shift.');
                return false
            }
            if (bdContext.BDState.BookingDetails[0].refnum) {
                globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
                var result = await PostAPI(`mybooking/details/changedate/${bdContext.BDState.BookingDetails[0].refnum}`, bdContext.BDState.changeDate
                );
                if (result) {
                    globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                    if (result.error) {
                        bdContext.BDDispatch({ type: 'ERROR' });
                        globalContext.notifications('error', result.error);
                    }
                    else {
                        globalContext.notifications('success', 'Date time changed successfully.');
                        bdContext.BDDispatch({ type: 'CHANGE_DATE_SUCCESS' });
                        bdContext.BDDispatch({ type: 'CLOSE_MODAL' });
                    }
                }
            }
        }

    }


    return (
        <>
            <div className="change-service-date-modal">
                <ModalHeader toggle={() => bdContext.BDDispatch({ type: 'CLOSE_MODAL' })}>
                </ModalHeader>
                <ModalBody className="p-0">
                    <div className="card">
                        <div className="card-header text-center title-2">
                            Date & Time Slot
                                 </div>
                        <div className="card-body">
                            <Container>
                                <Row>
                                    <Col lg="5" md="12" sm="12" xs="12">
                                        <div className="text-center">
                                            <Datetime
                                                timeFormat={false}
                                                dateFormat="DD/MM/YYYY"
                                                inputProps={{ placeholder: "DD/MM/YYYY" }}
                                                className="rdtOpen inlineDatepicker"
                                                closeOnSelect={true}
                                                value={new Date(bdContext.BDState.changeDate.requestDate)}
                                                isValidDate={e => validDate(e, holidays, startAfterDays)}
                                                onChange={e => {
                                                    bdContext.BDDispatch({
                                                        type: 'CHANGE_DATE_FIELD',
                                                        field: 'requestDate',
                                                        value: e._d,
                                                    })
                                                    FnGetTimeslots(e._d)
                                                }
                                                }
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="7" md="12" sm="12" xs="12">
                                        <p className="title-3">What time would you like us to come?</p>
                                        <p className="description">Select a service time slot </p>
                                        <Row>

                                            {bdContext.BDState.timeslots &&
                                                <React.Fragment>
                                                    {bdContext.BDState.timeslots.length > 0 &&
                                                        <React.Fragment>
                                                            {
                                                                bdContext.BDState.timeslots.map((item, index) => (
                                                                    <Col key={index} xl="4" lg="4" md="4" sm="12" xs="12" className="text-center">
                                                                        <button
                                                                            className={bdContext.BDState.changeDate.shiftnum === item.shiftnum ? "btn btn-time-slot active" : "btn btn-time-slot"}
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                bdContext.BDDispatch({
                                                                                    type: 'CHANGE_DATE_FIELD',
                                                                                    field: 'shiftnum',
                                                                                    value: item.shiftnum,
                                                                                })
                                                                            }}>
                                                                            <b className="mb-10">{getGreetingTime(item.starttime, item.endtime)}</b>
                                                                            <span>{TimeFormat(item.starttime)} - {TimeFormat(item.endtime)}</span>
                                                                        </button>
                                                                    </Col>
                                                                ))
                                                            }
                                                        </React.Fragment>
                                                    }
                                                </React.Fragment>
                                            }

                                            {/* <Col lg="4" md="12" sm="12" xs="12" className="text-center">
                                                <Button
                                                    className={timeSlot === "1" ? "btn btn-time-slot active" : "btn btn-time-slot"}
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        setTimeSlot("1");
                                                    }}>
                                                    <b className="mb-10">Morning Slot</b>
                                                    <span>9:00 am - 1:00 pm</span>
                                                </Button>
                                            </Col>
                                            <Col lg="4" md="12" sm="12" xs="12" className="text-center">
                                                <Button
                                                    className={timeSlot === "2" ? "btn btn-time-slot active" : "btn btn-time-slot"}
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        setTimeSlot("2");
                                                    }}>
                                                    <b className="mb-10">Afternoon Slot</b>
                                                    <span>2:30 pm - 6:30 pm</span>
                                                </Button>
                                            </Col>
                                            <Col lg="4" md="12" sm="12" xs="12" className="text-center">
                                                <Button
                                                    className={timeSlot === "3" ? "btn btn-time-slot active" : "btn btn-time-slot"}
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        setTimeSlot("3");
                                                    }}>
                                                    <b className="mb-10">Evening Slot</b>
                                                    <span>6:30 pm - 10:30 pm </span>
                                                </Button>
                                            </Col> */}
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className="card-footer text-center">
                            <Button className={"btn btn-round btn-info"}
                                onClick={e => {
                                    e.preventDefault();
                                    bdContext.BDDispatch({ type: 'CLOSE_MODAL' });
                                }}>
                                CANCEL
                                     </Button>
                            <Button className={"btn btn-round btn-info"}
                                onClick={e => {
                                    e.preventDefault();
                                    onSubmit();
                                }}>
                                SUBMIT CHANGES
                             </Button>
                        </div>
                    </div>
                </ModalBody>
            </div>
        </>
    )
}