//MyBooking Listing
export const MyBookingListInitialState = {
    activeData: [],
    activeFilterResult: [],
    historyData: [],
    historyFilterResult: [],
    searchString: '',
    activeTab: 'active',
}

export const MyBookingListReducer = (state, action) => {
    switch (action.type) {
        case 'FIELD': {
            return {
                ...state,
                //bookingData: { ...state.bookingData, [action.field]: action.value }
                [action.field]: action.value
            }
        }
        case 'DATEFROM_FIELD': {
            return {
                ...state,
                datefrom: action.value,
                dateto: action.value
            }
        }

        case 'ACTIVE_SUCCESS': {
            return {
                ...state,
                activeData: action.payload,
                activeFilterResult: action.payload,
            }
        }
        case 'HISTORY_SUCCESS': {
            return {
                ...state,
                historyData: action.payload,
                historyFilterResult: action.payload,
            }
        }

        case 'FILTER_RESULT': {
            return {
                ...state,
                [action.field]: action.value,
                activeFilterResult: action.value ? (state.activeData.filter((item) =>
                    item.ticket.toLowerCase().includes(action.value.toLowerCase()) && item
                )) : state.activeData,
                historyFilterResult: action.value ? (state.historyData.filter((item) =>
                    item.ticket.toLowerCase().includes(action.value.toLowerCase()) && item
                )) : state.historyData
            }
        }
        case 'SET_ACTIVE': {
            return {
                ...state,
                activeTab: action.payload,
            }
        }
        case 'ERROR': {
            return {
                ...state,
                activeData: [],
                activeFilterResult: [],
                historyData: [],
                historyFilterResult: []
            }
        }
        default: {
            return {
                ...state,
                activeData: [],
                activeFilterResult: [],
                historyData: [],
                historyFilterResult: []
            }
        }
    }
}



//MyBookingDetails 
export const MyBookingDetailsInitialState = {
    modal: false,
    modalSize: 'xl',
    visibleComponentType: '',
    visibleModalContentType: '',
    BookingDetails: [],
    timeslots: [],
    changeDate: {
        refnum: '',
        requestDate: '',
        shiftnum: ''
    },
    refund: {
        refnum: '',
        reason: '',
        remark: ''
    },
    feedback: {
        items: [],
        rating: 0,
        refnum: '',
        remark: '',
        tipamt: 0
    }
}

export const MyBookingDetailsReducer = (state, action) => {
    switch (action.type) {

        case 'COMPONENT': {
            const abc = {
                ...state,
                modal: false,
                visibleComponentType: action.ComponentType,
                visibleModalContentType: '',
            };
            // console.log(abc)
            return abc;
        }
        case 'MODAL': {
            const abc = {
                ...state,
                modal: true,
                modalSize: action.modalSize,
                visibleModalContentType: action.modalType,
            };
            // console.log(abc)
            return abc;
        }
        case 'CLOSE_MODAL':
            return {
                ...state,
                modal: false,
            }
        case 'SUCCESS': {
            return {
                ...state,
                BookingDetails: action.payload,
            }
        }
        case 'TIME_SLOT_SUCCESS': {
            return {
                ...state,
                timeslots: action.payload
            }
        }
        case 'CHANGE_DATE_FIELD': {
            return {
                ...state,
                changeDate: { ...state.changeDate, [action.field]: action.value }
            }
        }
        case 'CHANGE_DATE_SUCCESS': {
            return {
                ...state,
                changeDate: MyBookingDetailsInitialState.changeDate
            }
        }
        case 'REFUND_FIELD': {
            return {
                ...state,
                refund: { ...state.refund, [action.field]: action.value }
            }
        }
        case 'REFUND_SUCCESS': {
            return {
                ...state,
                refund: MyBookingDetailsInitialState.refund
            }
        }

        case 'FEEDBACK_FIELD': {
            return {
                ...state,
                feedback: { ...state.feedback, [action.field]: action.value }
            }
        }
        case 'FEEDBACK_TIPAMT': {
            return {
                ...state,
                feedback: {
                    ...state.feedback,
                    tipamt: state.feedback.tipamt === action.value ? 0 : action.value

                }
            }
        }
        case 'FEEDBACK_ITEMS': {
            //unselect items
            var ExistResult = state.feedback.items.filter(item => {
                return item === action.value
            });

            return {
                ...state,
                feedback: {
                    ...state.feedback,
                    items: ExistResult.length > 0 ?
                        state.feedback.items.filter((item) =>
                            (item !== action.value) && item
                        ) :
                        [...state.feedback.items, action.value]
                }
            }
        }
        case 'FEEDBACK_SUCCESS': {
            return {
                ...state,
                feedback: MyBookingDetailsInitialState.feedback
            }
        }

        // case 'FIELD': {
        //     return {
        //         ...state,
        //         //bookingData: { ...state.bookingData, [action.field]: action.value }
        //         [action.field]: action.value
        //     }
        // }
        // case 'ACTIVE_SUCCESS': {
        //     return {
        //         ...state,
        //         activeData: action.payload,
        //         activeFilterResult: action.payload,
        //     }
        // }
        // case 'HISTORY_SUCCESS': {
        //     return {
        //         ...state,
        //         historyData: action.payload,
        //         historyFilterResult: action.payload,
        //     }
        // }

        // case 'FILTER_RESULT': {
        //     return {
        //         ...state,
        //         [action.field]: action.value,
        //         activeFilterResult: action.value ? (state.activeData.filter((item) =>
        //             item.ticket.toLowerCase().includes(action.value.toLowerCase()) && item
        //         )) : state.activeData,
        //         historyFilterResult: action.value ? (state.historyData.filter((item) =>
        //             item.ticket.toLowerCase().includes(action.value.toLowerCase()) && item
        //         )) : state.historyData
        //     }
        // }
        // case 'SET_ACTIVE': {
        //     return {
        //         ...state,
        //         activeTab: action.payload,
        //     }
        // }
        // case 'ERROR': {
        //     return {
        //         ...state,
        //         activeData: [],
        //         activeFilterResult: [],
        //         historyData: [],
        //         historyFilterResult: []
        //     }
        // }
        default: {
            return {
                ...state,
            }
        }
    }
}




//My Particulars
export const MyParticularsInitialState = {
    myparticulars: {
        arnum: '',
        firstname: '',
        lastname: '',
        email: '',
        mobile: ''
    }
}

export const MyParticularsReducer = (state, action) => {
    switch (action.type) {
        case 'FIELD': {
            return {
                ...state,
                myparticulars: { ...state.myparticulars, [action.field]: action.value }
            }
        }
        case 'GET_SUCCESS': {
            return {
                ...state,
                myparticulars: action.payload,
            }
        }
        // case 'HISTORY_SUCCESS': {
        //     return {
        //         ...state,
        //         historyData: action.payload,
        //         historyFilterResult: action.payload,
        //     }
        // }

        // case 'FILTER_RESULT': {
        //     return {
        //         ...state,
        //         [action.field]: action.value,
        //         activeFilterResult: action.value ? (state.activeData.filter((item) =>
        //             item.ticket.toLowerCase().includes(action.value.toLowerCase()) && item
        //         )) : state.activeData,
        //         historyFilterResult: action.value ? (state.historyData.filter((item) =>
        //             item.ticket.toLowerCase().includes(action.value.toLowerCase()) && item
        //         )) : state.historyData
        //     }
        // }
        // case 'SET_ACTIVE': {
        //     return {
        //         ...state,
        //         activeTab: action.payload,
        //     }
        // }
        // case 'ERROR': {
        //     return {
        //         ...state,
        //         activeData: [],
        //         activeFilterResult: [],
        //         historyData: [],
        //         historyFilterResult: []
        //     }
        // }
        default: {
            return {
                ...state,
            }
        }
    }
}



//My contacts
export const MyConatctListInitialState = {
    searchString: '',
    Contacts: [],
    FilterContacts: []
}

export const MyConatctListReducer = (state, action) => {
    switch (action.type) {
        case 'GET_SUCCESS': {
            return {
                ...state,
                Contacts: action.payload,
                FilterContacts: action.payload,
            }
        }

        case 'FILTER_RESULT': {
            return {
                ...state,
                [action.field]: action.value,
                FilterContacts: action.value ? (state.Contacts.filter((item) =>
                    (item.firstname.toLowerCase().includes(action.value.toLowerCase()) ||
                        item.lastname.toLowerCase().includes(action.value.toLowerCase()) ||
                        item.email.toLowerCase().includes(action.value.toLowerCase()) ||
                        item.mobile.toLowerCase().includes(action.value.toLowerCase())) && item
                )) : state.Contacts
            }
        }
        case 'ERROR': {
            return {
                ...state,
                Contacts: [],
                FilterContacts: [],
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}



//My my Location
export const MyLocationInitialState = {
    searchString: '',
    LocationList: [],
    FilterLocationResult: [],
    isEdit: false,
    LocationData: {},
    ContactList: []
}

export const MyLocationReducer = (state, action) => {
    switch (action.type) {
        case 'GET_SUCCESS': {
            return {
                ...state,
                LocationList: action.payload,
                FilterLocationResult: action.payload,
            }
        }
        case 'CONTACT_SUCCESS': {
            return {
                ...state,
                ContactList: action.payload,
            }
        }
        case 'FILTER_RESULT': {
            return {
                ...state,
                [action.field]: action.value,
                FilterLocationResult: action.value ? (state.LocationList.filter((item) =>
                    (item.fulladdr.toLowerCase().includes(action.value.toLowerCase()) ||
                        item.housetype.toLowerCase().includes(action.value.toLowerCase()) ||
                        item.locationname.toLowerCase().includes(action.value.toLowerCase()) ||
                        item.unitno.toLowerCase().includes(action.value.toLowerCase())) && item
                )) : state.LocationList
            }
        }
        case 'EDIT': {
            return {
                ...state,
                isEdit: true,
                LocationData: action.payload
                // LocationData: state.LocationList.filter((item) =>
                //     item.guid === action.guid && item
                // )
            }
        }

        case 'FIELD': {
            return {
                ...state,
                LocationData: { ...state.LocationData, [action.field]: action.value }
            }
        }
        case 'LIST': {
            return {
                ...state,
                isEdit: false
            }
        }
        case 'ERROR': {
            return {
                ...state,
                LocationList: [],
                FilterLocationResult: [],
                LocationData: {}
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}



//My Request
export const MyRequestInitialState = {
    // searchString: '',
    RequestList: [],
    FilterRequestResult: [],
    RequestTypeDdl: [],
}

export const MyRequestReducer = (state, action) => {
    switch (action.type) {
        case 'GET_SUCCESS': {
            let ddl = action.payload.map(item => {
                return item.reqtype;
            });

            return {
                ...state,
                RequestList: action.payload,
                FilterRequestResult: action.payload,
                RequestTypeDdl: [...new Set(ddl)]
            }
        }
        case 'FILTER_RESULT': {
            return {
                ...state,
                // [action.field]: action.value,
                FilterRequestResult: action.value ? (state.RequestList.filter((item) =>
                    (item.reqtype.toLowerCase().includes(action.value.toLowerCase())) && item
                )) : state.RequestList
            }
        }
        case 'ERROR': {
            return {
                ...state,
                RequestList: [],
                FilterRequestResult: [],
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}



//My Inbox
export const MyInboxInitialState = {
    searchString: '',
    pageSize: 5,
    pageIndex: 0,
    InboxItems: [],
    FullMessage: {},
}

export const MyInboxReducer = (state, action) => {
    switch (action.type) {
        case 'GET_ITEM_SUCCESS': {
            return {
                ...state,
                InboxItems: action.payload,
                FilterInboxItems: action.payload.slice((state.pageIndex * state.pageSize), ((state.pageIndex * state.pageSize) + state.pageSize))
            }
        }

        case 'GET_MESSAGE_SUCCESS': {
            return {
                ...state,
                FullMessage: action.payload
            }
        }
        case 'FILTER_RESULT': {
            return {
                ...state,
                [action.field]: action.value,
                FilterInboxItems: action.value ? (state.InboxItems.filter((item) =>
                    (item.sender.toLowerCase().includes(action.value.toLowerCase())) && item
                )) : state.InboxItems
            }
        }
        case 'PAGINATION_RESULT': {
            return {
                ...state,
                FilterInboxItems: state.InboxItems.slice((state.pageIndex * state.pageSize), ((state.pageIndex * state.pageSize) + state.pageSize))
            }
        }
        case 'PREVIOUS': {
            return {
                ...state,
                pageIndex: state.pageIndex === 0 ? 0 : state.pageIndex - 1
            }
        }
        case 'NEXT': {
            return {
                ...state,
                pageIndex: (state.InboxItems.length / state.pageSize) > (state.pageIndex + 1) ? (state.pageIndex + 1) : state.pageIndex
            }
        }
        case 'ERROR': {
            return {
                ...state,
                InboxItems: []
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}