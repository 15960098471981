import React, { useState, useContext, useEffect, useReducer } from 'react';
import { Redirect, useHistory } from "react-router-dom";
import { Label, ModalBody, Row, Col, Container, Input } from 'reactstrap';
import { GlobalContext } from '../../App.js';
import { getData } from '../../utils/localStorage'
import api from '../../utils/api';
import Loader from './Loader';
import { locationInitialState, locationReducer } from '../../reducer/bookingReducer';
import { storeData } from '../../utils/localStorage';
import { GetAPI } from '../../API/APICalling';
//https://codesandbox.io/s/q555kp8jj?fontsize=14&file=/src/index.js:779-784 for dynamic binding

function ModalChooseLocation() {
    const globalContext = useContext(GlobalContext);
    const [locationState, locationDispatch] = useReducer(locationReducer, locationInitialState);

    const [propertyId, setPropertyId] = useState("");
    const [redirectUrl, setRedirectUrl] = useState("");
    const [viewMorelocation, setViewMoreLocation] = useState(false);
    const transferData = [];
    // let arnum, email, catnum;
    const history = useHistory();

    useEffect(() => {
        document.querySelector('.modal').scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        const session = getData('session');        
        if (!globalContext.headerState.matcatnum) {
            globalContext.notifications('error', 'Oops ! Something went wrong, Please try again.');
            history.push("/");
        }
        if(!session)
        {
            //globalContext.notifications('error', 'Oops ! No session recorded, Please try again.');
            FnGeQuetions("", "", globalContext.headerState.matcatnum);
        }
        else {
            // arnum = session.arnum;
            // email = session.email;
            // catnum = globalContext.headerState.matcatnum;
            FnGeQuetions(session.arnum, session.email, globalContext.headerState.matcatnum);
        }
    }, []);

    const FnGetLocation = async (arnum, email) => {
        globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
        var result = await GetAPI(`booking/locations/${arnum}?email=${email}`);
        if (result) {
            if (result.error) {
                locationDispatch({ type: 'ERROR' });
                globalContext.notifications('error', result.error);
            }
            else if (result.data) {

                locationDispatch({ type: 'LOCATION_SUCCESS', payload: result.data });
                let defaultLocation = result.data.filter(function (item) {
                    return item.isDefault === true
                })[0];
                if (defaultLocation != null) {
                    setPropertyId(defaultLocation.locationid);
                }
            }
            globalContext.loaderDispatch({ type: 'STOP_LOADING' });
        }


        // locationDispatch({ type: 'LOCATION_START' });
        // api.get(`booking/locations/${arnum}?email=${email}`)
        //     .then(res => {
        //         const response = res.data;
        //         if (response != null) {
        //             if (response.meta.responseCode === 'S') {
        //                 locationDispatch({ type: 'LOCATION_SUCCESS', payload: response.data });
        //                 let defaultLocation = response.data.filter(function (item) {
        //                     return item.isDefault === true
        //                 })[0];
        //                 if (defaultLocation != null) {
        //                     setPropertyId(defaultLocation.locationid);
        //                 }
        //             }
        //             else {
        //                 locationDispatch({ type: 'ERROR' });
        //                 globalContext.notifications('error', response.meta.message);
        //             }
        //         }
        //         else {
        //             locationDispatch({ type: 'ERROR' });
        //             globalContext.notifications('error', 'Oops ! Something went wrong.');
        //         }
        //     })
        //     .catch(error => {
        //         locationDispatch({ type: 'ERROR' });
        //         globalContext.notifications('error', 'Oops ! Something went wrong.');
        //         console.log(error);
        //     });

    };

    const FnGeQuetions = async (arnum, email, catnum) => {

        globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
        var result = await GetAPI(`booking/questions/${catnum}`);
        if (result) {
            if (result.error) {
                locationDispatch({ type: 'ERROR' });
                globalContext.notifications('error', result.error);
            }
            else if (result.data) {
                console.log(result.data)
                if (globalContext.authState.isLoggedIn)
                {
                    FnGetLocation(arnum, email);                    
                }
                locationDispatch({ type: 'QUESTION_SUCCESS', payload: result.data });
            }
            globalContext.loaderDispatch({ type: 'STOP_LOADING' });
        }

        // locationDispatch({ type: 'LOCATION_SUCCESS' });
        // api.get(`booking/questions/${catnum}`)
        //     .then(res => {
        //         const response = res.data;
        //         if (response != null) {
        //             if (response.meta.responseCode === 'S') {
        //                 locationDispatch({ type: 'QUESTION_SUCCESS', payload: response.data });
        //             }
        //             else {
        //                 locationDispatch({ type: 'ERROR' });
        //                 globalContext.notifications('error', response.meta.message);
        //             }
        //         }
        //         else {
        //             locationDispatch({ type: 'ERROR' });
        //             globalContext.notifications('error', 'Oops ! Something went wrong.');
        //         }
        //     })
        //     .catch(error => {
        //         locationDispatch({ type: 'ERROR' });
        //         globalContext.notifications('error', 'Oops ! Something went wrong.');
        //         console.log(error);
        //     });
    };

    const FnSetExistingPropertyId = async (e) => {
        setPropertyId(propertyId === e ? '' : e);
        locationDispatch({ type: 'DYNAMIC_FIELD', field: 'locationid', value: propertyId === e ? '' : e });
    }

    useEffect(() => {
        if (propertyId) {
            //debugger;
            //getting selected address.
            if (locationState.LocationData) {
                let Location = locationState.LocationData.filter(function (item) {
                    return item.locationid === propertyId
                })[0];
                //binding all question based on selected address.
                // if (Location === undefined) {
                //     if (locationState.QuestionaireData) {
                //         for (var propt in locationState.QuestionaireData) {
                //             locationDispatch({ type: 'DYNAMIC_FIELD', field: propt, value: '' });
                //         }
                //     }
                // }
                // else {
                for (var propt in Location) {
                    // console.log(propt)
                    locationDispatch({ type: 'DYNAMIC_FIELD', field: propt, value: (Location[propt]).toString() });
                    if (propt === "housetype")
                        FnSetRedirectUrl(Location[propt]);
                }
                // }
            }
        }
    }, [propertyId]);


    const FnSetNewPropertyId = async (e) => {
        // if (locationState.QuestionaireData) {
        //     locationState.QuestionaireData['housetype'] = locationState.QuestionaireData['housetype'] === e ? '' : e;
        // }
        //setNewPropertyId(newPropertyId === e ? '' : e);
        // locationInitialState.QuestionaireData.housetype = newPropertyId.replace("{btn=assessment}", "");

        //claer add data.
        for (var propt in locationState.QuestionaireData) {
            locationDispatch({ type: 'DYNAMIC_FIELD', field: propt, value: '' });
        }

        if (locationState.QuestionaireData) {
            // locationState.QuestionaireData['housetype'] = locationState.QuestionaireData['housetype'] === e ? '' : e;
            locationDispatch({ type: 'DYNAMIC_FIELD', field: 'housetype', value: (locationState.QuestionaireData['housetype'] === e ? '' : e) });
            FnSetRedirectUrl(locationState.QuestionaireData['housetype'] === e ? '' : e);
        }

        // locationDispatch({ type: 'DYNAMIC_FIELD', field: 'housetype', value: e });
        setPropertyId('');
    }


    //set redirect url based on new propery type
    const FnSetRedirectUrl = async (housetype) => {
        // console.log("FnSetRedirectUrl:" + housetype)
        // console.log(locationState.QuestionData)
        if (housetype) {
            if (locationState.QuestionData) {
                locationState.QuestionData.map((item, index) => {
                    item.winputtype === 'button' && (
                        item.wvalues.split(';').forEach(element => {
                            if (housetype.replace("{btn=assessment}", "") === element.replace("{btn=assessment}", "")) {
                                if (element.includes('{btn=assessment}')) {
                                    //console.log('/schedule-assessment')
                                    setRedirectUrl('/schedule-assessment')
                                }
                                else {
                                    setRedirectUrl('/package-listing')
                                    //console.log('/package-listing')
                                }
                            }
                        }))
                });
            }
        }
    }
    // useEffect(() => {
    //     if (locationState.QuestionaireData['housetype']) {
    //         debugger;
    //         if (locationState.QuestionData) {
    //             locationState.QuestionData.map((item, index) => {
    //                 item.winputtype === 'button' && (
    //                     item.wvalues.split(';').forEach(element => {
    //                         if (locationState.QuestionaireData['housetype'].replace("{btn=assessment}", "") === element.replace("{btn=assessment}", "")) {
    //                             if (element.includes('{btn=assessment}')) {
    //                                 console.log('/schedule-assessment')
    //                                 setRedirectUrl('/schedule-assessment')
    //                             }
    //                             else {
    //                                 setRedirectUrl('/package-listing')
    //                                 console.log('/package-listing')
    //                             }
    //                         }
    //                     }))
    //             });
    //         }
    //     }

    // }, [locationState.QuestionaireData['housetype']]);


    const onSubmit = (e) => {
        e.preventDefault();
        let isValid = true;
        console.log('onSubmit')
        console.log(redirectUrl)
        if (locationState.QuestionData) {
            if (locationState.QuestionaireData) {
                const questionnaire = [];
                locationState.QuestionData.map(function (item) {
                    if (locationState.QuestionaireData[item.qnnum] === '' || locationState.QuestionaireData[item.qnnum] === '0') {
                        isValid = false;
                    }
                    else {


                        if (item.qnnum === "housetype") {
                            questionnaire.push({ 'key': item.qnnum, 'value': (locationState.QuestionaireData[item.qnnum].replace("{btn=assessment}", "")).toString() });
                        }
                        else {
                            questionnaire.push({ 'key': item.qnnum, 'value': (locationState.QuestionaireData[item.qnnum]).toString() });
                        }
                    }

                });
                if (isValid) {
                    for (var propt in locationState.QuestionaireData) {
                        if (propt === "housetype") {
                            transferData.push({ 'key': propt, 'value': (locationState.QuestionaireData[propt].replace("{btn=assessment}", "")).toString() });
                        }
                        else {
                            transferData.push({ 'key': propt, 'value': (locationState.QuestionaireData[propt]).toString() });
                        }
                    }
                    // console.log(questionnaire);
                    // console.log(transferData);
                    storeData('questionnaire', questionnaire)
                    storeData('transferredData', transferData)
                    // if (locationState.QuestionaireData['housetype'].includes('{btn=assessment}')) {
                    //     history.push({
                    //         pathname: '/schedule-assessment',
                    //         state: { 'matcatnum': globalContext.headerState.matcatnum }
                    //     });
                    // }
                    // else {
                    //     history.push({
                    //         pathname: '/package-listing',
                    //         state: { 'matcatnum': globalContext.headerState.matcatnum }
                    //     });

                    // }
                    history.push({
                        pathname: redirectUrl,
                        state: { 'matcatnum': globalContext.headerState.matcatnum }
                    });

                    // setRedirectUrl('/package-listing') :
                    // setRedirectUrl('/schedule-assessment');
                }
                else {
                    globalContext.notifications('error', 'Please fill all the details.');
                    return false
                }
            }
        }
    }

    return (
        <>
            {/* {
                redirectUrl ?
                    <Redirect to={{
                        pathname: redirectUrl,
                        state: { 'matcatnum': globalContext.headerState.matcatnum }
                    }} />
                    : null

                // redirectUrl ?
                //     <Redirect to={redirectUrl} />
                //     : null
            } */}
            {locationState.isLoading ? <Loader /> :
                <div className="choose-location-modal pt-80">
                    <ModalBody>
                        <Container className="first-half">
                            <Row>
                                <div className="left-side">
                                    <div className="text-left">
                                        <b className="title-2">Splendid!!!</b>
                                        <p className="description">You have selected us to guide you find the best service that suits you. Please help us in answering a couple of questions more
                                                     in order for us to assess your needs properly.</p>
                                    </div>
                                </div>
                                <div className="right-side">
                                    <img
                                        alt="..."
                                        src={require("../../assets/img/avatar-butler.png")}
                                        className="butler"
                                    ></img>
                                </div>
                            </Row>
                        </Container>

                        <Container className="second-half">
                            {locationState.LocationData &&
                                <React.Fragment>
                                    {locationState.LocationData.length === 0 ? (
                                        ''
                                    ) : (
                                            <React.Fragment>
                                                <Row>
                                                    <hr style={{ width: "45%" }} className="mb-20"></hr>
                                                </Row>
                                                <Row>
                                                    <Col md="12" className="ml-auto mr-auto text-center">
                                                        <p className="description">Select your property that requires Our service.</p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12" className="pr-30 pl-30 ml-auto mr-auto text-center">
                                                        <Row>
                                                            {
                                                                locationState.LocationData.map((item, index) => (
                                                                    index < 2 ? (
                                                                        <Col key={index} xl="6" lg="6" md="12" sm="12" className="text-center pl-5 pr-5">
                                                                            <button
                                                                                className={propertyId === item.locationid ? "btn btn-location active" : "btn btn-location"}
                                                                                onClick={e => {
                                                                                    e.preventDefault();
                                                                                    FnSetExistingPropertyId(item.locationid);
                                                                                }}>
                                                                                <b className="pb-5">{item.housetype}({item.area} sqf)</b>
                                                                                <span>{item.fulladdr}</span>
                                                                                <b>No. of bedroom: {item.bedroomqty} | No. of bathroom: {item.bathroomqty}</b>
                                                                            </button>
                                                                        </Col>)
                                                                        : (
                                                                            viewMorelocation &&
                                                                            <Col key={index} xl="6" lg="6" md="12" sm="12" className="text-center pl-5 pr-5">
                                                                                <button
                                                                                    className={propertyId === item.locationid ? "btn btn-location active" : "btn btn-location"}
                                                                                    onClick={e => {
                                                                                        e.preventDefault();
                                                                                        FnSetExistingPropertyId(item.locationid);
                                                                                    }}>
                                                                                    <b className="pb-5">{item.housetype}({item.area} sqft)</b>
                                                                                    <span>{item.fulladdr}</span>
                                                                                    <b>No. of badroom: {item.bedroomqty} | No. of bathroom: {item.bathroomqty}</b>
                                                                                </button>
                                                                            </Col>
                                                                        )
                                                                ))
                                                            }
                                                        </Row>
                                                        {
                                                            locationState.LocationData.length >= 2 &&
                                                            <Row>
                                                                <Col md="12" className="text-right">
                                                                    <button className="btn-link btn btn-info p-0" onClick={e => {
                                                                        e.preventDefault();
                                                                        setViewMoreLocation(!viewMorelocation);
                                                                    }
                                                                    }> {viewMorelocation ? "hide location" : "View More"}</button>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )
                                    }
                                </React.Fragment>
                            }

                            <Row>
                                <hr style={{ width: "100%" }} className="mb-20"></hr>
                                <Col md="12" className="ml-auto mr-auto text-center">
                                    <p className="description">Property not listed? Fill up information for your new property below.</p>
                                </Col>
                            </Row>

                            {locationState.QuestionData &&
                                <React.Fragment>
                                    {locationState.QuestionData.length === 0 ? (
                                        ''
                                    ) : (
                                            <React.Fragment>
                                                {
                                                    locationState.QuestionData.map((item, index) => (
                                                        item.winputtype === 'button' ?
                                                            (
                                                                <Row key={index}>
                                                                    {
                                                                        item.wvalues.split(';').map((item2, index2) => (
                                                                            <Col key={index2} xl="4" lg="4" md="4" sm="4" xs="4" className="p-0 text-center">
                                                                                <button
                                                                                    //className={newPropertyId === item ? "btn btn btn-newlocation active" : "btn btn btn-newlocation"}
                                                                                    className={locationState.QuestionaireData[item.qnnum].replace("{btn=assessment}", "") === item2.replace("{btn=assessment}", "") ? "btn btn btn-newlocation active" : "btn btn btn-newlocation"}
                                                                                    onClick={e => {
                                                                                        e.preventDefault();
                                                                                        FnSetNewPropertyId(item2);
                                                                                    }}>
                                                                                    <img
                                                                                        alt="..."
                                                                                        src={require("../../assets/img/icon-property-landed.png")}
                                                                                    ></img>
                                                                                    <b>{item2.replace("{btn=assessment}", "")}</b>
                                                                                </button>
                                                                            </Col>
                                                                        ))
                                                                    }


                                                                    {/* <Col xl="4" lg="4" md="4" sm="4" xs="4" className="p-0 text-center">
                                                <Button
                                                    className={newPropertyId === "2" ? "btn btn btn-newlocation active" : "btn btn btn-newlocation"}
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        setNewProperty("2");
                                                    }}>
                                                    <img
                                                        alt="..."
                                                        src={require("../../assets/img/icon-property-landed.png")}
                                                    ></img>
                                                    <b>Apartment</b>
                                                </Button>
                                            </Col>
                                            <Col xl="4" lg="4" md="4" sm="4" xs="4" className="p-0 text-center">
                                                <Button
                                                    className={newPropertyId === "3" ? "btn btn btn-newlocation active" : "btn btn btn-newlocation"}
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        setNewProperty("3");
                                                    }}>
                                                    <img
                                                        alt="..."
                                                        src={require("../../assets/img/icon-property-landed.png")}
                                                    ></img>
                                                    <b>Others</b>
                                                </Button>
                                            </Col> */}
                                                                </Row>
                                                            ) : item.winputtype === 'string' ?
                                                                (
                                                                    <Row key={index}>
                                                                        <Col md="6" className="ml-auto mr-auto ">
                                                                            <div className="form-group text-center">
                                                                                <Label>{item.wqn}</Label>
                                                                                <Input type="text"
                                                                                    className="form-control text-center"
                                                                                    placeholder="Enter Property Size"
                                                                                    value={locationState.QuestionaireData[item.qnnum]}
                                                                                    onChange={e => {
                                                                                        locationDispatch({
                                                                                            type: 'DYNAMIC_FIELD',
                                                                                            field: item.qnnum,
                                                                                            value: e.currentTarget.value
                                                                                        });
                                                                                        FnSetExistingPropertyId('')
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                ) : item.winputtype === 'combobox' ?
                                                                    (
                                                                        <Row key={index}>
                                                                            <Col md="6" className="ml-auto mr-auto ">
                                                                                <div className="form-group  text-center">
                                                                                    <Label>{item.wqn}</Label>
                                                                                    <select className="form-control" placeholder="Select bedroom"
                                                                                        style={{
                                                                                            textAlignLast: "center"
                                                                                        }}
                                                                                        value={locationState.QuestionaireData[item.qnnum]}
                                                                                        onChange={e => {
                                                                                            locationDispatch({
                                                                                                type: 'DYNAMIC_FIELD',
                                                                                                field: item.qnnum,
                                                                                                value: e.currentTarget.value
                                                                                            });
                                                                                            FnSetExistingPropertyId('');
                                                                                        }
                                                                                        }
                                                                                    >
                                                                                        <option key={index} value='0'>Select {item.wqn}</option>
                                                                                        {
                                                                                            item.wvalues.split(';').map((item, index) => (
                                                                                                <option key={index} value={item} >{item}</option>
                                                                                            ))
                                                                                        }
                                                                                    </select>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>) : null
                                                    ))
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                </React.Fragment>
                            }



                            {/* 
                                <Row key={index}>
                                            <hr style={{ width: "100%" }} className="mb-20"></hr>
                                            <Col md="12" className="ml-auto mr-auto text-center">
                                                <p className="description">Property not listed? Fill up information for your new property below.</p>
                                            </Col>
                                            <Col xl="4" lg="4" md="4" sm="4" xs="4" className="p-0 text-center  ">
                                                <Button
                                                    className={newPropertyId === "1" ? "btn btn btn-newlocation active" : "btn btn btn-newlocation"}
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        setNewProperty("1");
                                                    }}>
                                                    <img
                                                        alt="..."
                                                        src={require("../../assets/img/icon-property-landed.png")}
                                                    ></img>
                                                    <b>Landed</b>
                                                </Button>
                                            </Col>
                                            <Col xl="4" lg="4" md="4" sm="4" xs="4" className="p-0 text-center">
                                                <Button
                                                    className={newPropertyId === "2" ? "btn btn btn-newlocation active" : "btn btn btn-newlocation"}
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        setNewProperty("2");
                                                    }}>
                                                    <img
                                                        alt="..."
                                                        src={require("../../assets/img/icon-property-landed.png")}
                                                    ></img>
                                                    <b>Apartment</b>
                                                </Button>
                                            </Col>
                                            <Col xl="4" lg="4" md="4" sm="4" xs="4" className="p-0 text-center">
                                                <Button
                                                    className={newPropertyId === "3" ? "btn btn btn-newlocation active" : "btn btn btn-newlocation"}
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        setNewProperty("3");
                                                    }}>
                                                    <img
                                                        alt="..."
                                                        src={require("../../assets/img/icon-property-landed.png")}
                                                    ></img>
                                                    <b>Others</b>
                                                </Button>
                                            </Col>
                                        </Row>
                                         */}

                            {/* <Row key={index}>
                                                <Col md="6" className="ml-auto mr-auto ">
                                                    <div className="form-group text-center">
                                                        <Label>What is the property size in sqm?</Label>
                                                        <input type="text" className="form-control text-center" placeholder="Enter Property Size" />
                                                    </div>
                                                </Col>
                                            </Row> */}

                            {/* <Row>
                                                <Col md="6" className="ml-auto mr-auto ">
                                                    <div className="form-group  text-center">
                                                        <Label>No. of bedroom</Label>
                                                        <select className="form-control" placeholder="Select bedroom"
                                                            style={{
                                                                textAlignLast: "center"
                                                            }}>
                                                            <option>1</option>
                                                            <option>2</option>
                                                            <option>3</option>
                                                        </select>
                                                    </div>
                                                </Col>
                                            </Row> */}


                            {/* <Row>
                            <Col md="6" className="ml-auto mr-auto ">
                                <div className="form-group  text-center">
                                    <Label>No. of bathroom</Label>
                                    <select className="form-control" placeholder="Select bathroom"
                                        style={{
                                            textAlignLast: "center"
                                        }}>
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                    </select>
                                </div>
                            </Col>
                        </Row> */}
                            <Row>
                                <hr style={{ width: "100%" }}></hr>
                                {locationState.QuestionaireData &&
                                    <Col md="12" className="ml-auto mr-auto text-center">
                                        <button type="button" className="btn btn-round btn-default"
                                            onClick={e => {
                                                e.preventDefault();
                                                globalContext.headerDispatch({ type: 'CLOSE' })
                                            }}
                                        >CANCEL</button>
                                        <button type="button" className="btn btn-round btn-info"
                                            // onClick={() => globalContext.headerDispatch({ type: 'CLOSE' })}
                                            onClick={e => onSubmit(e)}
                                        >{redirectUrl === '/schedule-assessment' ? 'Schedule Assessment' : 'Find Best Packages'}</button>

                                    </Col>
                                }
                            </Row>
                        </Container >
                    </ModalBody >
                </div >
            }
        </>
    );
}

export default ModalChooseLocation;