import React, { useContext } from "react";
// reactstrap components
import { Container, Row, Col, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { GlobalContext } from '../../App.js';

function Footer() {
    const globalContext = useContext(GlobalContext);
    return (
        <>
            <div className="separator" />
            {!globalContext.loaderState.isLoading &&
                <footer className="footer footer-blue">
                    <Container>
                        <Row>
                            <Col md="12" sm="12" xs="12">
                                <div className="pull-center">
                                    <div className="copyright">
                                        <div>© {new Date().getFullYear()} Whissh(1 Plus (Europe) Ltd). All Rights Reserved.
                                        <Link to="/privacy-policy" tag={Link}>
                                                {" "}Privacy Policy
                                        </Link>

                                        </div>
                                    </div>
                                </div>
                            </Col>

                            {/* <Col md="5" sm="12" xs="12">
                            <div className="text-center">
                                <div className="social-area">
                                    <li className="btn">
                                        <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
                                            <img
                                                alt="facebook"
                                                className="rounded"
                                                src={require("../../assets/img/icon-sm-white-fb.png")}
                                            ></img>
                                        </a>
                                    </li>
                                    <li className="btn">
                                        <a href="https://www.google.com/" target="_blank" rel="noopener noreferrer">
                                            <img
                                                alt="google plus"
                                                className="rounded"
                                                src={require("../../assets/img/icon-sm-white-go.png")}
                                            ></img>
                                        </a>
                                    </li>
                                    <li className="btn">
                                        <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
                                            <img
                                                alt="instagram"
                                                className="rounded"
                                                src={require("../../assets/img/icon-sm-white-ig.png")}
                                            ></img>
                                        </a>
                                    </li>
                                </div>
                            </div>
                        </Col>
                    */}
                        </Row>
                    </Container>
                </footer >
            }
        </>
    );
}

export default Footer;
