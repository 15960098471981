import React, { useContext, useEffect, useState, useReducer } from "react";
import { Container, Row, Col, Button, FormGroup, Input, Label } from "reactstrap";

import { useHistory } from "react-router-dom";
import { GlobalContext } from '../App.js';
import { getData } from "../utils/localStorage";
import { GetAPI } from "../API/APICalling";
import { storeData } from "../utils/localStorage";

export default function AddOnList(props) {
    const history = useHistory();
    const globalContext = useContext(GlobalContext);
    const [laundry, setLaundry] = useState([]);
    const [showBookNow, setShowBookNow] = useState(false);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        const session = getData('session')
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }
        else if (!history.location.state) {
            globalContext.notifications('error', 'Sorry, You can not access this page directly, Please try again.');
            history.push("/");
        }
        else {
            FnGetAddOns(history.location.state.matnum);
        }
    }, []);



    const FnGetAddOns = async (matnum) => {
        globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
        var result = await GetAPI(`booking/addons/${matnum}`);
        if (result) {
            if (result.error) {
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                globalContext.notifications('error', result.error);
            }
            else if (result.data) {
                console.log(result.data)
                setLaundry(result.data);
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
            }
        }
    };

    const UpdateQty = async (optionnum, qty) => {
        setLaundry(laundry.map(item => {
            return (item.optionnum === optionnum && parseInt(qty || 0) >= 0 && parseInt(qty || 0) <= 100) ?
                {
                    ...item,
                    qty: parseInt(qty || 0)
                }
                :
                item
        }))
        const TotalQty = laundry.reduce((tl, item) => {
            return tl + ((item.optionnum === optionnum && parseInt(qty || 0) >= 0 && parseInt(qty || 0) <= 100) ? parseInt(qty || 0) : item.qty)
        }, 0)
        TotalQty > 0 ? setShowBookNow(true) : setShowBookNow(false);
        console.log(laundry);
    }

    const onSubmit = async () => {
        const TotalQty = laundry.reduce((tl, item) => {
            return tl + (item.qty)
        }, 0)
        if (TotalQty > 0) {
            storeData('transferredAddon', laundry);
            history.push({
                pathname: '/service-booking',
                state: {
                    'jobnum': history.location.state.jobnum,
                    'jobnum2': history.location.state.jobnum2,
                    'matname': history.location.state.matname,
                    'matnum': history.location.state.matnum,
                    'baseamt': history.location.state.baseamt
                }
            })
        }

    }

    return (
        <>
            <div id="PackageList" className="adjust-navbar">
                <Container>

                    <Row>
                        <Col md="12">
                            <Row className="pb-10">
                                <Col xl="8" lg="10" md="12" className="ml-auto mr-auto ">
                                    <div className="text-center">
                                        <h2 className="big-heading">{history.location.state.matname}</h2>
                                    </div>
                                    <hr className="hr-large"></hr>
                                </Col>
                            </Row>
                            <Row className="mb-15">
                                <Col xl="8" lg="10" md="12" className="ml-auto mr-auto">
                                    <div className="card">
                                        <div className="card-header text-left title-2">
                                            Fill Items Quantity
								</div>
                                        <div className="card-body">
                                            {
                                                laundry.length > 0 && laundry.map((item, index) => (
                                                    <React.Fragment key={index}>
                                                        <Row>
                                                            <Col xs="3" md="6">
                                                                <img
                                                                    alt="..."
                                                                    className="laundry-img"
                                                                    src={item.directory || require("../assets/img/img-sample-service2.jpg")}
                                                                ></img>
                                                            </Col>
                                                            <Col xs="9" md="6" className="pl-0">
                                                                {/* <div className="text-left">
                                                                    Qty:  <Input type="number" className="laundry-qty"
                                                                        value={item.qty}
                                                                        onChange={e => {
                                                                            e.preventDefault();
                                                                            UpdateQty(item.optionnum, e.currentTarget.value)
                                                                        }}
                                                                    ></Input>
                                                                </div> */}
                                                                <Row className="mt-5">
                                                                    <Col xs="12">
                                                                        <div className="text-left title-3 pb-10">{item.optiondesc} ( ${parseFloat(item.price).toFixed(2)})</div>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="mt-5">
                                                                    <Col xs="5">
                                                                        <FormGroup>
                                                                            <Row>
                                                                                <Col xs="4"> <Label >Qty:</Label></Col>
                                                                                <Col xs="8">
                                                                                    <Input type="number" className="laundry-qty"
                                                                                        value={item.qty}
                                                                                        onChange={e => {
                                                                                            e.preventDefault();
                                                                                            UpdateQty(item.optionnum, e.currentTarget.value)
                                                                                        }}
                                                                                    ></Input>
                                                                                </Col>
                                                                            </Row>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col xs="7" className="text-right">
                                                                        <div className="title-3 pb-10">${(parseFloat(item.price || 0) * (parseFloat(item.qty || 0))).toFixed(2)}</div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <hr></hr>
                                                    </React.Fragment>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </Col>
                                {/* <Col md="4" className="ml-auto mr-auto text-left d-none d-sm-block">
                                    <img
                                        alt="..."
                                        style={{
                                            maxHeight: "810px",
                                            objectFit: "cover"
                                        }}
                                        src={require("../assets/img/img-joinus.jpg")}
                                    ></img>
                                </Col> */}
                            </Row>
                        </Col>
                    </Row>
                    <div className="text-center">
                        {showBookNow &&
                            <FormGroup>
                                <Button className="btn btn-round btn-info"
                                    onClick={e => {
                                        e.preventDefault();
                                        onSubmit();
                                    }}
                                >BOOK NOW</Button>

                                {/* <Link
                                    to={{
                                        pathname: '/service-booking',
                                        state: { 'jobnum': item.jobnum, 'matname': item.matname, 'matnum': item.matnum, 'baseamt': item.price }
                                    }}>
                                    <button
                                        type="button"
                                        className="btn-round btn btn-info"
                                    >
                                        Book Now
                                        </button>
                                </Link> */}
                            </FormGroup>
                        }

                    </div>
                </Container>
            </div >
        </>
    );
}
