import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { storeData, getData } from "../../utils/localStorage";

export default React.memo(function AcceptCookies() {
    console.log('AcceptCookies');

    const [isAccepted, setIsAccepted] = useState(getData('cookies'));
    const [isDecline, setIsDecline] = useState(getData('cookies'));

    const acceptCookies = async () => {
        storeData('cookies', true);
        setIsAccepted(true);
        console.log("You pressed OK!");
    }

    const declineCookies = async () => {
        console.log("You pressed Cancel!")
        storeData('cookies', false);
        setIsAccepted(false);
        setIsDecline(true)
    }

    return (
        <>
            {!isAccepted && !isDecline &&
                <div className="cookies-footer">
                    <Row>
                        <Col md="12">
                            <div>
                            <div className="text-center">
                                <div className="cookie-content">
                                    <div className="">
                                    <i className="fas fa-info pr-10"></i>
                                    <span className="pr-10">
                                    This website uses cookies for ads personalisation to ensure you get the best experience for your needs. <a href="https://uktestsite.whissh.co.uk/privacy-policy">Learn more</a>
                                    </span>
                                    <a to="#" className="btn-round btn btn-default"
                                        onClick={e => {
                                            e.preventDefault();
                                            acceptCookies();
                                        }}>
                                        Accept Cookies
                                </a>
                                    </div>
                                </div>
                                <a to="#"
                                onClick={e=>{
                                  e.preventDefault();
                                  declineCookies();
                                        }} ><div className="cancel-icon">X</div></a>
                            </div>
                            
                        </div>
                        </Col>
                    </Row>
                </div>
            
            }

        </>
    );
});
