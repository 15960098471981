import React, { useContext, useState, useEffect } from "react";
import ReCAPTCHA from "react-recaptcha";
// reactstrap components
import { GlobalContext } from '../../App.js';
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Input
} from "reactstrap";
import TextareaAutosize from 'react-textarea-autosize';
import { api } from "../../constant";
import { PostAPI } from "../../API/APICalling";
import { ValidateEmail } from "../../utils/common";
import { Link, useHistory } from "react-router-dom";
import { GOOGLE_RECAPTCHA_SITE_KEY } from "../../constant";

export default function ContactUs() {
  const globalContext = useContext(GlobalContext);
  const [isFetching, setIsFetching] = useState(true);
  const [formData, setFormData] = useState({});
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const history = useHistory();
  const [data, setData] = useState({
    page_meta: "",
    tag_facebook: "",
    tag_twitter: "",
    content: {
      generaloffice: "",
      generalofficelist: "",
      office1: "",
      office2: "",
      map_long: "-0.12348",
      map_lat: " 51.51504"
    }
  });
  const mapurl = `https://maps.google.com/maps?q=${data.content.map_lat},${data.content.map_long}&t=&z=11&ie=UTF8&iwloc=&output=embed`;
  //const mapurl = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7397663883676!2d103.8899119502396!3d1.332268599023549!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da190a9bbec0d7%3A0xffd15c79c62e3262!2sWhissh%20-%20Home%20%26%20Office%20Cleaning%20%26%20Disinfection%20Services%20Singapore!5e0!3m2!1sen!2ssg!4v1588297637211!5m2!1sen!2ssg`;

  useEffect(() => {
    const fetchData = async e => {
      api.get(`cms/contactuscontent`)
        .then(res => {
          const response = res.data;
          if (response != null) {
            if (response.meta.responseCode === 'S') {
              setData(response.data);
              setIsFetching(false);
            }
            else {
              globalContext.notifications('error', response.meta.message);
              setIsFetching(false);
            }
          }
          else {
            globalContext.notifications('error', 'Oops ! Something went wrong.');
            setIsFetching(false);
          }
        })
        .catch(error => {
          globalContext.notifications('error', 'Oops ! Something went wrong.');
          setIsFetching(false);
          console.log(error)
        });
    };

    fetchData();

  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  // specifying your onload callback function
  const reCaptchLoaded = async => {
    console.log('Done!!!!');
  };

  // specifying verify callback function
  const verifyCallback = async (response) => {
    if (response)
      setCaptchaVerified(true);
  };

  const reCaptchExpired = async (response) => {
    setCaptchaVerified(false);
  };

  const FnSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);

    if (!formData.ptc) {
      globalContext.notifications('error', 'Name is required.');
      return false;
    }
    if (!formData.ptcemail) {
      globalContext.notifications('error', 'Email address is required.');
      return false;
    }
    if (!ValidateEmail(formData.ptcemail)) {
      globalContext.notifications('error', 'You have entered an invalid email address.');
      return false
    }
     if (!formData.contactnumber) {
      globalContext.notifications('error', 'Contact Number is required.');
      return false;
    }
    if (!captchaVerified) {
      globalContext.notifications('error', 'Please verify captcha.');
      return false;
    }
    else {
      if (formData) {
        globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
        var result = await PostAPI(`home/contactus`, formData);
        if (result) {
          globalContext.loaderDispatch({ type: 'STOP_LOADING' });
          if (result.error) {
            globalContext.notifications('error', result.error);
          }
          else {
            setFormData({});
            history.push(
              {
                  pathname: "/thankyou"
              });
            globalContext.notifications('success', 'Your request has been sumitted successfully.');
          }
        }
      }
    }
  }


  return (
    <>
      <div className="adjust-navbar-breadcrumb">
        <div className="breadcrumb-outer">
          <Container className="breadcrumb-body">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/" tag={Link}>
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active">
                <i className="fa fa-angle-right pr-5" aria-hidden="true"></i>
                Contact Us
              </li>
            </ol>
          </Container>
        </div>
        <Container fluid>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <Row className="pt-20 pb-10">
                <Col md="8" className="ml-auto mr-auto ">
                  <div className="text-center">
                    <h1 className="big-heading">Contact Us</h1>
                  </div>
                  <hr className="hr-small"></hr>
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="12">
                  <div className="mapouter">
                    <div className="gmap_canvas">
                      {
                        <iframe
                          id="gmap_canvas"
                          src={mapurl}
                          style={{
                            border: "0",
                            width: "100%",
                            height: "350px",
                          }}
                        ></iframe>
                      }
                      {/* <a href="https://www.crocothemes.net"></a> */}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Container>
                  <Row>
                    <Col md="10" className="ml-auto mr-auto">
                      <Row className="mb-30">
                        {!isFetching ? (
                          <Col
                            md="4"
                            className="ml-auto mr-auto text-left"
                            dangerouslySetInnerHTML={{
                              __html: data.content.generaloffice,
                            }}
                          ></Col>
                        ) : (
                            ""
                          )}

                        {/* {!isFetching ? dangerouslySetInnerHTML=data.content.generaloffice  : null} */}

                        {/* <Col md="4" className="ml-auto mr-auto text-left">
                           <div>
                             <p className="title-1">General Office</p>
                           </div>
                           <div>
                             <b className="title-3">
                               <span className="location-icon"></span>
                                 Address
                             </b>

                             <p className="pl-25 description">
                               62 Ubi Road 1, #10-17,<br />
                               Oxly Bizhub 2, Singapure 408734
                             </p>
                           </div>
                           <div>
                             <b className="title-3">
                               <span className="phone-icon"></span>
                                 Phone
                             </b>

                             <p className="pl-25 description">
                               +65 6221 8626
                             </p>
                           </div>
                           <div>
                             <b className="title-3">
                               <span className="email-icon"></span>
                                 Email
                             </b>

                             <p className="pl-25 description">
                               Ccontact@whisshcom.sg
                             </p>
                           </div>
                           <div>
                             <b className="title-3">
                               <span className="time-icon"></span>
                                 Hotline Operation Hours
                             </b>
                             <p className="pl-25 description">
                               9:00 am - 9:00 pm<br />
                               Daily
                             </p>
                           </div>
                         </Col> */}

                        <Col md="8" className="ml-auto mr-auto">
                          <FormGroup>
                            <div className="text-center">
                              <h2 className="big-heading">Get in Touch </h2>
                            </div>
                            <hr className="hr-large"></hr>
                          </FormGroup>
                          <FormGroup>
                            <Input
                              type="text"
                              placeholder="Your Name*"
                              maxLength={20}
                              value={formData.ptc || ""}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  ptc: e.currentTarget.value || "",
                                });
                              }}
                            />
                          </FormGroup>

                          <FormGroup>
                            <Input
                              type="text"
                              placeholder="E-mail*"
                              maxLength={50}
                              value={formData.ptcemail || ""}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  ptcemail: e.currentTarget.value || "",
                                });
                              }}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Input
                              type="text"
                              placeholder="Contact Number*"
                              maxLength={15}
                              value={formData.contactnumber || ""}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  contactnumber: e.currentTarget.value || "",
                                });
                              }}
                            />
                          </FormGroup>

                          {/* <FormGroup className="form-control">
                            <input type="file" name="file " onChange={(e) => e.target.files} />
                          </FormGroup> */}
                          <FormGroup>
                            <TextareaAutosize
                              minRows={3}
                              maxRows={10}
                              className="form-control"
                              placeholder="Message"
                              maxLength={250}
                              value={formData.message || ""}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  message: e.currentTarget.value || "",
                                });
                              }}
                            />
                          </FormGroup>
                          <FormGroup>
                            <ReCAPTCHA
                              sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
                              render="explicit"
                              verifyCallback={verifyCallback}
                              onloadCallback={reCaptchLoaded}
                              expiredCallback={reCaptchExpired}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Button
                              className="btn btn-round btn-info"
                              onClick={(e) => {
                                e.preventDefault();
                                FnSubmit(e);
                              }}
                            >
                              SEND MESSAGE
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        {!isFetching ? (
                          <Col
                            md="4"
                            className="ml-auto mr-auto text-left pb-10"
                            dangerouslySetInnerHTML={{
                              __html: data.content.generalofficelist,
                            }}
                          ></Col>
                        ) : (
                            ""
                          )}

                        {!isFetching ? (
                          <Col
                            md="4"
                            className="ml-auto mr-auto text-left"
                            dangerouslySetInnerHTML={{
                              __html: data.content.office1,
                            }}
                          ></Col>
                        ) : (
                            ""
                          )}

                        {!isFetching ? (
                          <Col
                            md="4"
                            className="ml-auto mr-auto text-left"
                            dangerouslySetInnerHTML={{
                              __html: data.content.office2,
                            }}
                          ></Col>
                        ) : (
                            ""
                          )}

                        {/* <Col md="4" className="ml-auto mr-auto text-left">

                          <div>
                            <p className="title-3">General Office</p>
                          </div>
                          <div>
                            <p className="description">
                              <span className="right-icon"></span>
                              No Contracts and no obligation
                            </p>
                          </div>
                          <div>
                            <p className="description">
                              <span className="right-icon"></span>
                             Competitive Rates
                            </p>
                          </div>
                          <div>
                            <p className="description">
                              <span className="right-icon"></span>
                              Top Quality Work
                            </p>
                          </div>
                          <div>
                            <p className="description">
                              <span className="right-icon"></span>
                              A Service Schedule designed to meet your needs
                            </p>
                          </div>
                          <div>
                            <p className="description">
                              <span className="right-icon"></span>
                             We provide all our own supplies.
                            </p>
                          </div>
                        </Col>
                        <Col md="4" className="ml-auto mr-auto text-left">
                          <div className="address-card">
                            <div>
                              <p className="title-3">Affiliate Office #1</p>
                            </div>
                            <div className="description">
                              <span className="location-icon"></span>
                             Londan<br />
                              <span className="pl-25">Phone: +44 (0)20 8168 9818</span><br />
                              <span className="pl-25">Email: Contact@whishh.co.uk</span><br />
                              <span className="pl-25">9 Fishers Lane,</span><br />
                              <span className="pl-25">London W4 1RX</span><br /><br />
                              <div className="pl-25">
                                <span className="google-icon"></span>
                                <span className="facebook-icon"></span>
                                <span className="twiter-icon"></span>
                                <span className="instagram-icon"></span>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col md="4" className="ml-auto mr-auto text-left">
                          <div className="address-card">
                            <div>
                              <p className="title-3">Affiliate Office #2</p>
                            </div>
                            <div className="description">
                              <span className="location-icon"></span>
                             Londan<br />
                              <span className="pl-25">Phone: +44 (0)20 8168 9818</span><br />
                              <span className="pl-25">Email: Contact@whishh.co.uk</span><br />
                              <span className="pl-25">9 Fishers Lane,</span><br />
                              <span className="pl-25">London W4 1RX</span><br /><br />
                              <div className="pl-25">
                                <span className="google-icon"></span>
                                <span className="facebook-icon"></span>
                                <span className="twiter-icon"></span>
                                <span className="instagram-icon"></span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      
                       */}
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
