import React, { useEffect, useContext, useReducer } from "react";
import { Link, useHistory } from 'react-router-dom';
import { Container, Row, Col, FormGroup, Input, Pagination, PaginationItem, PaginationLink } from "reactstrap";
//import TextareaAutosize from 'react-textarea-autosize';
import { GlobalContext } from '../../App';
import { MyInboxReducer, MyInboxInitialState } from "../../reducer/MyAccountReducer";
import { getData } from "../../utils/localStorage";
import { GetAPI } from "../../API/APICalling";
import { DateFormat } from "../../utils/common";
//import DataTable from 'react-data-table-component';

//const Original = ["Banana", "Orange", "Lemon", "Apple", "Mango", "Banana6", "Orange7", "Lemon8", "Apple9", "Mango10", "Banana11", "Orange12", "Lemon13", "Apple14", "Mango15"];

export default function MyInbox() {
    const globalContext = useContext(GlobalContext);
    const [state, dispatch] = useReducer(MyInboxReducer, MyInboxInitialState)
    const history = useHistory();
    //const [data, setData] = useState([]);

    useEffect(() => {
        FnGetInboxItems();
    }, []);


    useEffect(() => {
        //console.log(((state.pageIndex * state.pageSize) + state.pageSize))
        //console.log((state.pageIndex * state.pageSize))
        //setData(Original.slice((state.pageIndex * state.pageSize), ((state.pageIndex * state.pageSize) + state.pageSize)))

        dispatch({ type: 'PAGINATION_RESULT' })
    }, [state.pageIndex]);

    const FnGetInboxItems = async () => {
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }
        else {
            globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
            var result = await GetAPI(`myinbox/${session.arnum}?email=${session.email}`);
            if (result) {
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                if (result.error) {
                    globalContext.notifications('error', result.error);
                }
                else if (result.data) {
                    //console.log(result.data)
                    dispatch({ type: 'GET_ITEM_SUCCESS', payload: result.data });
                }
            }
        }
    };

    const FnGetFullMessage = async (guid) => {
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }
        else {
            globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
            var result = await GetAPI(`myinbox/message/${guid}`);
            if (result) {
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                if (result.error) {
                    globalContext.notifications('error', result.error);
                }
                else if (result.data) {
                    // console.log(result.data)
                    dispatch({ type: 'GET_MESSAGE_SUCCESS', payload: result.data });
                }
            }
        }
    };
    return (
        <>
            <div className="adjust-navbar-breadcrumb">
                <div className="breadcrumb-outer">
                    <Container className="breadcrumb-body">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">
                                    Home
                                    </Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/my-account/my-profile">
                                    <i className="fa fa-angle-right pr-5" aria-hidden="true"></i>
                                    My Account
                                    </Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <i className="fa fa-angle-right pr-5" aria-hidden="true"></i>My Inbox
                            </li>
                        </ol>
                    </Container>
                </div>
                <Container fluid id="myAccount">
                    <Row>
                        <Col className="ml-auto mr-auto text-left" xl="10" lg="12" md="12">
                            <Row>
                                <Col xl="10" lg="12" md="12" className="ml-auto mr-auto">
                                    <Row className="pt-10">
                                        <Col md="12" sm="12" xs="12" className="ml-auto mr-auto text-left">
                                            <h2 className="big-heading">My Inbox</h2>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl="10" lg="12" md="12" className="ml-auto mr-auto">
                                    <Row className="mt-15">
                                        <Col xl="4" lg="4" md="4" sm="12" xs="12" className="ml-auto mr-auto text-left">
                                            <div className="card">
                                                <div className="card-header">
                                                    <FormGroup>
                                                        <Input type="search" placeholder="Search.."
                                                            value={state.searchString}
                                                            onChange={e =>
                                                                dispatch({
                                                                    type: 'FILTER_RESULT',
                                                                    field: 'searchString',
                                                                    value: e.currentTarget.value,
                                                                })
                                                            }
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="card-body">


                                                    {/* <DataTable
                                                        // title="Arnold Movies"
                                                        columns={columns}
                                                        noTableHead={true}
                                                        data={data}
                                                        pagination
                                                        paginationPerPage={1}
                                                        paginationComponentOptions={{ rangeSeparatorText: 'of', noRowsPerPage: true, selectAllRowsItem: true }}
                                                    /> */}


                                                    {/* {
                                                        data.map((item, index) => (
                                                            <React.Fragment key={index}>
                                                                <Row>
                                                                    <Col lg="12" md="12" sm="12" xs="12">
                                                                        <div>
                                                                            <p className="contactName">{item}</p>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <hr></hr>
                                                            </React.Fragment>
                                                        ))
                                                    } */}

                                                    {state.FilterInboxItems &&
                                                        <React.Fragment>
                                                            {
                                                                state.FilterInboxItems.length < 1 ?
                                                                    <div className="text-center empty-screen">No Records Found</div>
                                                                    :
                                                                    <React.Fragment>
                                                                        {
                                                                            state.FilterInboxItems.map((item, index) => (
                                                                                <React.Fragment key={index}>
                                                                                    <Row
                                                                                        onClick={e => {
                                                                                            e.preventDefault();
                                                                                            FnGetFullMessage(item.guid);
                                                                                        }}
                                                                                    >
                                                                                        <Col lg="12" md="12" sm="12" xs="12">
                                                                                            <div>
                                                                                                <span className="contactName">{item.sender}</span>
                                                                                                <span className="description pull-right">{DateFormat(item.sendtime, 'DD MMM YYYY')}</span>
                                                                                            </div>
                                                                                            {item.unread ?
                                                                                                <div>
                                                                                                    New {item.message}
                                                                                                </div>
                                                                                                :
                                                                                                <div className="description">
                                                                                                    {item.message}
                                                                                                </div>
                                                                                            }
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <hr></hr>
                                                                                </React.Fragment>
                                                                            ))
                                                                        }
                                                                    </React.Fragment>
                                                            }
                                                        </React.Fragment>
                                                    }
                                                    {/* <Row>
                                                        <Col lg="12" md="12" sm="12" xs="12">
                                                            <div>
                                                                <span className="contactName">Sender Name  </span>
                                                                <span className="description pull-right">8 Feb 2020</span>
                                                            </div>
                                                            <div>
                                                                New - Hi, We
                                                                would like to confirm your booking!
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <hr></hr>
                                                    <Row>
                                                        <Col lg="12" md="12" sm="12" xs="12">
                                                            <div>
                                                                <span className="contactName">Sender Name  </span>
                                                                <span className="description pull-right">8 Feb 2020</span>
                                                            </div>
                                                            <div>
                                                                New - Hi, We
                                                                would like to confirm your booking!
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <hr></hr>
                                                    <Row>
                                                        <Col lg="12" md="12" sm="12" xs="12">
                                                            <div>
                                                                <span className="contactName">Sender Name  </span>
                                                                <span className="description pull-right">8 Feb 2020</span>
                                                            </div>
                                                            <div className="description">
                                                                Sample message here
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <hr></hr>
                                                    <Row>
                                                        <Col lg="12" md="12" sm="12" xs="12">
                                                            <div>
                                                                <span className="contactName">Sender Name  </span>
                                                                <span className="description pull-right">8 Feb 2020</span>
                                                            </div>
                                                            <div className="description">
                                                                Sample message here
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <hr></hr>
                                                    <Row>
                                                        <Col lg="12" md="12" sm="12" xs="12">
                                                            <div>
                                                                <span className="contactName">Sender Name  </span>
                                                                <span className="description pull-right">8 Feb 2020</span>
                                                            </div>
                                                            <div className="description">
                                                                Sample message here
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <hr></hr> */}
                                                </div>
                                                <div className="card-footer">
                                                    <Row>
                                                        <Col lg="10" md="10" sm="10" xs="10" className="ml-auto mr-auto">
                                                            <div className="pull-left">
                                                                {(state.pageIndex * state.pageSize) + 1}-{((state.pageIndex * state.pageSize) + state.pageSize)} of {state.InboxItems.length}
                                                            </div>
                                                            <div className="pull-right">
                                                                <Pagination>
                                                                    <PaginationItem>
                                                                        <PaginationLink
                                                                            aria-label="Previous"
                                                                            href="#"
                                                                            value={state.pageIndex}
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                dispatch({
                                                                                    type: 'PREVIOUS'
                                                                                })
                                                                            }}
                                                                        >
                                                                            <span aria-hidden={true}>
                                                                                <i aria-hidden={true} className="fa fa-angle-double-left"></i>
                                                                            </span>
                                                                        </PaginationLink>
                                                                    </PaginationItem>
                                                                    <PaginationItem>
                                                                        <PaginationLink
                                                                            aria-label="Next"
                                                                            href="#"
                                                                            value={state.pageIndex}
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                dispatch({
                                                                                    type: 'NEXT'
                                                                                })
                                                                            }}
                                                                        >
                                                                            <span aria-hidden={true}>
                                                                                <i aria-hidden={true} className="fa fa-angle-double-right"></i>
                                                                            </span>
                                                                        </PaginationLink>
                                                                    </PaginationItem>
                                                                </Pagination>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl="8" lg="8" md="8" sm="12" xs="12" className="ml-auto mr-auto">
                                            <div className="card">
                                                {state.FullMessage &&
                                                    Object.entries(state.FullMessage).length === 0 ?
                                                    <div className="text-center empty-screen">No Message here..</div>
                                                    :
                                                    <React.Fragment>
                                                        <div className="card-header">
                                                            <Container>
                                                                <Row>
                                                                    <Col lg="11" md="11" sm="11" xs="11" className="ml-auto mr-auto">
                                                                        <div className="pull-left"><img
                                                                            alt="..."
                                                                            className="rounded-circle"
                                                                            src={state.FullMessage.profileimageurl || require("../../assets/img/user-default.png")}
                                                                            style={{ maxHeight: "40px" }}
                                                                        ></img>
                                                                            <span className="contactName pl-15"> {state.FullMessage.sender}</span>
                                                                        </div>
                                                                        <div className="description pull-right">
                                                                            {DateFormat(state.FullMessage.sendtime, 'DD MMM YYYY')}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                        </div>
                                                        <div className="card-body">
                                                            <Container>
                                                                <Row>
                                                                    <Col lg="11" md="11" sm="11" xs="11" className="ml-auto mr-auto">
                                                                        <div className="description text-left">
                                                                            {state.FullMessage.message}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                        </div>
                                                        {/* <div className="card-footer text-center">
                                                            <Container>
                                                                <Row>
                                                                    <Col md="12" className="ml-auto mr-auto">
                                                                        <div className="description text-left">
                                                                            
                                                                            <FormGroup>
                                                                                <TextareaAutosize
                                                                                    minRows={5}
                                                                                    maxRows={25}
                                                                                    className="form-control" placeholder="Message" />
                                                                            </FormGroup>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md="12" className="ml-auto mr-auto">
                                                                        <Button
                                                                            className={"btn btn-round btn-info"}
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                            }}
                                                                        >
                                                                            SEND MESSAGE
                                                                          </Button>
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                        </div> */}

                                                    </React.Fragment>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}
