import React, { useState, useEffect, useReducer, useContext } from 'react';
import { Button, ModalBody, ModalHeader, FormGroup, Form, Row, Col, Container, Label } from 'reactstrap';
import { GlobalContext } from '../../App.js';
import { ServiceBookingContext } from '../../views/ServiceBooking';
import { GetAPI } from '../../API/APICalling';
import { AddOnsReducer, AddOnsInitialState } from '../../reducer/bookingReducer';
import { PostAPI } from '../../API/APICalling';
import { useHistory } from "react-router-dom"


export default function ModalPackageAddOns(props) {
    const sbContext = useContext(ServiceBookingContext);
    const globalContext = useContext(GlobalContext);
    const [state, dispatch] = useReducer(AddOnsReducer, AddOnsInitialState);
    const [isSelected, setIsSelected] = useState(false);
    const history = useHistory();

    useEffect(() => {
        document.querySelector('.modal').scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        if (sbContext.ServiceState.PackageDetails) {
            FnGetAddOns(sbContext.ServiceState.PackageDetails.refnum, sbContext.ServiceState.PackageDetails.guid)
        }
    }, [])

    const FnGetAddOns = async (refnum, guid) => {
        globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
        var result = await GetAPI(`booking/cart/addons/${refnum}?guid=${guid}`);
        globalContext.loaderDispatch({ type: 'STOP_LOADING' });

        if (result) {
            if (result.error) {
                globalContext.notifications('error', result.error);
            }
            else if (result.data) {
                dispatch({ type: 'SUCCESS', payload: result.data });

                // //filter selected items
                const selected = result.data.filter(item => {
                    return item.selected === true ? item : null
                })
                if (selected.length > 0) {
                    setIsSelected(true);
                }

                //group creating
                let abc = result.data.map(item => {
                    return item.groupnum + '||' + item.choicetype
                });

                if (abc) {
                    dispatch({ type: 'SET_GROUP', payload: [...new Set(abc)] })
                }

                //var cars = [{ make: 'audi', model: 'r8', year: '2012' }, { make: 'audi', model: 'rs5', year: '2013' }, { make: 'ford', model: 'mustang', year: '2012' }, { make: 'ford', model: 'fusion', year: '2015' }, { make: 'kia', model: 'optima', year: '2012' }],
                // var result = result.data.reduce(function (r, a) {
                //     r[a.groupnum] = r[a.groupnum] || [];
                //     r[a.groupnum].push(a);
                //     return r;
                // }, Object.create(null));

            }
        }
    };

    const FnSelectAddOns = async (el) => {
        //select items if setisify condition.
        var result = state.addOnsList.filter(item => {
            return item.groupnum === el.groupnum && item.choicetype === el.choicetype && item.selected === true
        });
        // dispatch({ type: 'SELECTED_ITEM', value: groupnum + '||' + choicetype + '||' + optionnum })
        if (result.length < el.maxchoice) {
            dispatch({ type: 'SELECT_ITEM', payload: el });
        }

        if (result.length > 0 || result.length < el.maxchoice) {
            setIsSelected(true);
        }

        //unselect items
        var ExistResult = state.addOnsList.filter(item => {
            return item.groupnum === el.groupnum && item.choicetype === el.choicetype && item.optionnum === el.optionnum && item.selected === true
        });
        if (ExistResult.length > 0) {
            dispatch({ type: 'UNSELECT_ITEM', groupnum: el.groupnum, choicetype: el.choicetype, optionnum: el.optionnum });
        }
    };

    const IsSelected = (groupnum, choicetype, optionnum) => {
        var result = state.addOnsList.filter(item => {
            return item.groupnum === groupnum && item.choicetype === choicetype && item.optionnum === optionnum && item.selected === true
        });
        if (result.length > 0) {
            return true;
        }
        else {
            return false;
        }
    };

    const FnSaveAddOns = async () => {
        if (!sbContext.ServiceState.PackageDetails.refnum || !sbContext.ServiceState.PackageDetails.guid) {
            globalContext.notifications('error', 'Oops ! Something went wrong., Please try again');
            return false;
        }
        else {
            if (state.addOnsList.length > 0) {
                globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
                var result = await PostAPI(`booking/cart/addons/${sbContext.ServiceState.PackageDetails.refnum}?guid=${sbContext.ServiceState.PackageDetails.guid}`, state.addOnsList);
                if (result) {
                    globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                    if (result.error) {
                        globalContext.notifications('error', result.error);
                    }
                    else {
                        // var addcost = state.addOnsList.reduce((tl, item) => {
                        //     return tl + ((item.selected === true) ?
                        //         (parseFloat(item.price || 0)) : 0.00)
                        // }, 0);

                        // var addondesc = state.addOnsList.filter(item => {
                        //     return item.selected === true ? item : null
                        // }).map(item => {
                        //     return item.groupdesc
                        // }).join('||');

                        // sbContext.BookingDispatch({ type: 'ADD_ONS', guid: sbContext.ServiceState.PackageDetails.guid, addon: addondesc, optamt: parseFloat(addcost || 0.00) });
                        sbContext.ServiceDispatch({ type: 'CLOSE_MODAL' });
                        //trigger callback to reload finalize screen.
                        sbContext.ServiceState.callback();
                    }
                }
            }
        }
    };

    return (
        <>
            <div className="package-add-ons-modal">
                <ModalHeader toggle={() => sbContext.ServiceDispatch({ type: 'CLOSE_MODAL' })}>
                </ModalHeader>
                <ModalBody className="p-0">
                    <div className="card">
                        <div className="card-body">
                            <Container>
                                <Row>
                                    <Col md="12" className="ml-auto mr-auto ">
                                        <Form>
                                            <FormGroup className="text-center">
                                                <img
                                                    alt="..."
                                                    src={require("../../assets/img/icon-service.png")}
                                                    style={{ height: "60px" }}
                                                >
                                                </img>
                                            </FormGroup>
                                            <FormGroup className="text-center">
                                                <h2 className="title-2">
                                                    Package Add-ons
                                                </h2>
                                            </FormGroup>
                                            <hr className="hr-large"></hr>
                                            <FormGroup className="text-center">
                                                <Label> Select add-ons for your package
                                                </Label>
                                            </FormGroup>

                                            {
                                                state.addOnsGroupList &&
                                                <React.Fragment>
                                                    {state.addOnsGroupList.length > 0 &&
                                                        <React.Fragment>
                                                            {
                                                                state.addOnsGroupList.map((item1, index1) => (
                                                                    // console.log(item1),
                                                                    <React.Fragment key={index1}>
                                                                        <Row>
                                                                            {
                                                                                state.addOnsList.map((item2, index2) => (
                                                                                    (item2.groupnum + '||' + item2.choicetype) === item1 ? (
                                                                                        // console.log(item2),
                                                                                        <Col key={index2} xl="4" lg="4" md="12" sm="12" className="text-center p-5">
                                                                                            <button
                                                                                                className={IsSelected(item2.groupnum, item2.choicetype, item2.optionnum) ? "btn btn-time-slot active" : "btn btn-time-slot"}
                                                                                                onClick={e => {
                                                                                                    e.preventDefault();
                                                                                                    FnSelectAddOns(item2);
                                                                                                    //dispatch({ type: 'SELECT_ITEM', field: 'selectedAddOns', value: item.groupnum + '||' + item.choicetype + '||' + item.optionnum })
                                                                                                }}>
                                                                                                <b className="mb-10">{item2.groupdesc} (£ {parseFloat(item2.price).toFixed(2)})</b>
                                                                                                <span>{item2.optiondesc}</span>
                                                                                            </button>
                                                                                        </Col>
                                                                                    ) : null
                                                                                ))
                                                                            }
                                                                        </Row>
                                                                        <hr></hr>
                                                                    </React.Fragment>
                                                                ))
                                                            }
                                                        </React.Fragment>
                                                    }
                                                </React.Fragment>
                                            }

                                            <FormGroup>
                                                <Row>
                                                    {/* {state.addOnsList &&
                                                        <React.Fragment>
                                                            {state.addOnsList.length === 0 ? (
                                                                ''
                                                            ) : (
                                                                    <React.Fragment>
                                                                        {
                                                                            state.addOnsList.map((item, index) => (
                                                                                <Col key={index} xl="4" lg="4" md="12" sm="12" className="text-center p-5">
                                                                                    <button
                                                                                        className={state.selectedAddOns === item.groupnum + '||' + item.choicetype ? "btn btn-time-slot active" : "btn btn-time-slot"}
                                                                                        onClick={e => {
                                                                                            e.preventDefault();
                                                                                            dispatch({ type: 'SELECT_ITEM', field: 'selectedAddOns', value: item.groupnum + '||' + item.choicetype })
                                                                                        }}>
                                                                                        <b className="mb-10">{item.groupdesc} ($.{item.price})</b>
                                                                                        <span>{item.optiondesc}</span>
                                                                                    </button>
                                                                                </Col>
                                                                            ))
                                                                        }
                                                                    </React.Fragment>)
                                                            }
                                                        </React.Fragment>
                                                    } */}

                                                    {/* <Col md="4" sm="12" className="text-center p-5">
                                                        <Button
                                                            className={timeSlot === "1" ? "btn btn-time-slot active" : "btn btn-time-slot"}
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                setTimeSlot("1");
                                                            }}>
                                                            <b className="mb-10">Cleaning Equipment ($.00)</b>
                                                            <span>Cleaning Set(Mop, Vacuum,etc.)</span>
                                                        </Button>
                                                    </Col>
                                                    <Col md="4" sm="12" className="text-center p-5">
                                                        <Button
                                                            className={timeSlot === "2" ? "btn btn-time-slot active" : "btn btn-time-slot"}
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                setTimeSlot("2");
                                                            }}>
                                                            <b className="mb-10">Experienced Crew ($.00)</b>
                                                            <span>Short Description</span>
                                                        </Button>
                                                    </Col>
                                                    <Col md="4" sm="12" className="text-center p-5">
                                                        <Button
                                                            className={timeSlot === "3" ? "btn btn-time-slot active" : "btn btn-time-slot"}
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                setTimeSlot("3");
                                                            }}>
                                                            <b className="mb-10">Skilled Crew ($.00)</b>
                                                            <span>Short Description</span>
                                                        </Button>
                                                    </Col> */}
                                                </Row>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className="card-footer">
                            <FormGroup className="text-center">
                                <Button
                                    className={"btn-lg btn-round btn-default"}
                                    onClick={e => {
                                        e.preventDefault();
                                        // props.Function.FnCloseModal();
                                        sbContext.ServiceDispatch({ type: 'CLOSE_MODAL' });
                                    }}>
                                    CANCEL
                                     </Button>
                                {isSelected &&
                                    <Button
                                        className={"btn-lg btn-round btn-info"}
                                        onClick={e => {
                                            e.preventDefault();
                                            // props.Function.FnCloseModal();
                                            // sbContext.ServiceDispatch({ type: 'CLOSE_MODAL' });
                                            FnSaveAddOns();
                                        }}>
                                        SELECT
                                </Button>
                                }
                            </FormGroup>
                        </div>
                    </div>
                </ModalBody>
            </div>
        </>
    );

}
