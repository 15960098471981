export const HeaderInitialState = {
    modal: false,
    menuTitle: '',
    subsvcnum: '',
    visibleComponentType: '',
    modalTransparent: '',
    loginFromHeader: false,
    matcatnum: '',
    displayaddon: false,
    skipqn: false
}

export const HeaderReducer = (state, action) => {
    // console.log('action.type:' + action.type);
    switch (action.type) {
        case 'SUB_MENU': {
            const abc = {
                ...state,
                modal: true,
                menuTitle: action.title,
                subsvcnum: action.subsvcnum,
                visibleComponentType: 'SUB_MENU',
                modalTransparent: ''
            };
            console.log(abc);
            return abc;
        }
        case 'CHOOSE_SERVICE': {
            const abc2 = {
                ...state,
                modal: true,
                visibleComponentType: 'CHOOSE_SERVICE',
                //modalTransparent: 'transparent-modal',
                matcatnum: action.matcatnum,
                displayaddon: action.displayaddon,
                skipqn: action.skipqn
            };
            console.log(abc2);
            return abc2;
        }
        case 'LOGIN':
            return {
                ...state,
                modal: true,
                visibleComponentType: 'LOGIN',
                modalTransparent: '',
                loginFromHeader: false
            }
        case 'HEADERLOGIN':
            return {
                ...state,
                modal: true,
                menuTitle: '',
                visibleComponentType: 'LOGIN',
                modalTransparent: '',
                loginFromHeader: true
            }
        case 'OTP':
            return {
                ...state,
                modal: true,
                visibleComponentType: 'OTP',
                modalTransparent: ''
            }
        case 'CHOOSELOCATION_OLDUSER':
            return {
                ...state,
                modal: true,
                visibleComponentType: 'CHOOSELOCATION_OLDUSER',
                modalTransparent: 'transparent-modal',
                matcatnum: action.matcatnum
            }
        case 'SIGNUP':
            return {
                ...state,
                modal: true,
                visibleComponentType: 'SIGNUP',
                modalTransparent: ''
            }
        case 'CHOOSELOCATION_NEWUSER':
            return {
                ...state,
                modal: true,
                visibleComponentType: 'CHOOSELOCATION_NEWUSER',
                modalTransparent: 'transparent-modal',
                matcatnum: action.matcatnum
            }
        case 'FORGOT_PASSWORD':
            return {
                ...state,
                modal: true,
                visibleComponentType: 'FORGOT_PASSWORD',
                modalTransparent: ''
            }
        case 'LOADING':
            return {
                ...state,
                modal: true,
                visibleComponentType: 'LOADING',
                modalTransparent: ''
            }
        default:
            return {
                ...state,
                modal: false,
                visibleComponentType: '',
                modalTransparent: ''
            }
    }
}

export const AuthInitialState = {
    isLoggedIn: false,
    userdata: {}
}
export const AuthReducer = (state, action) => {
    //console.log('AuthReducer action.type:' + action.type);
    switch (action.type) {
        case 'RESET': {
            const sc = {
                ...state,
                isLoggedIn: false,
                userdata: {}
            }
            //console.log(sc)
            return sc;
        }
        case 'SET': {
            const up = {
                ...state,
                isLoggedIn: true,
                userdata: action.payload
            }
            //console.log(up)
            return up;
        }

        default:
            return {
                ...state,
                isLoggedIn: false,
                userdata: {}
            }
    }
}


export const CommonInitialState = {
    menuitems: [],
    footerdata: {},
    megamenuitems: [],
    cartrefnum: '',
    carttotalitem: 0,
    homeheader: {},
    metadata: []
}
export const CommonReducer = (state, action) => {
    //console.log('AuthReducer action.type:' + action.type);
    switch (action.type) {
        case 'SET_CART': {
            const up = {
                ...state,
                cartrefnum: action.payload.refnum,
                carttotalitem: action.payload.totalitem
            }
            // console.log(up)
            return up;
        }
        case 'SET_MENUITEMS': {
            return {
                ...state,
                menuitems: action.payload,
            }
        }
        case 'SET_FOOTERDATA': {
            return {
                ...state,
                footerdata: action.payload,
            }
        }
        case 'SET_MEGAMENU': {
            return {
                ...state,
                megamenuitems: action.payload,
            }
        }
        case 'SET_HOMEHEADER': {
            return {
                ...state,
                homeheader: action.payload,
            }
        }
        case 'SET_METADATA': {
            return {
                ...state,
                metadata: action.payload,
            }
        }
        default:
            return {
                ...state,
                menuitems: [],
                footerdata: {},
                megamenuitems: [],
                cartrefnum: '',
                carttotalitem: 0,
                homeheader: {},
                metadata: [],
            }
    }
}

export const LoaderInitialState = {
    isLoading: false,
    showLoader: false,
}
export const LoaderReducer = (state, action) => {
    switch (action.type) {
        case 'START_LOADING': {
            return {
                ...state,
                isLoading: true,
                showLoader: action.payload
            }
        }
        case 'STOP_LOADING': {
            return {
                ...state,
                isLoading: false,
                showLoader: false
            }
        }
        default: {
            return {
                ...state,
                isLoading: false,
                showLoader: false
            }
        }
    }
}
