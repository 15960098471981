import React, { useContext, useEffect, useState, useReducer } from "react";

// reactstrap components
import { useHistory } from "react-router-dom";

import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    InputGroup
} from "reactstrap";

// react plugins that creates an input with a date picker
import Datetime from "react-datetime";

import { GlobalContext } from '../App.js';
import { getData, removeData } from "../utils/localStorage";
import { api } from "../constant";
import { AssessmentInitialState, AssessmentReducer } from "../reducer/bookingReducer";
import { validDate } from "../utils/common";
import Loader from "../components/Login/Loader";
import { ReadRate } from "../utils/common";


export default function ScheduleAssessment(props) {
    const history = useHistory();
    const globalContext = useContext(GlobalContext);
    const [state, dispatch] = useReducer(AssessmentReducer, AssessmentInitialState)
    // let questionnaire = [];
    let arnum, email, catnum;
    const [estimated, SetEstimated] = useState([]);

    useEffect(() => {
        globalContext.headerDispatch({ type: 'CLOSE' });
        const questionnaire = getData('questionnaire');
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }
        else if (!props.location.state || !questionnaire) {
            globalContext.notifications('error', 'Sorry, You can not access this page directly, Please try again.');
            history.push("/");
        }
        else {
            FnGetPackages(session.arnum, session.email, props.location.state.matcatnum, questionnaire);
        }
    }, []);

    const FnGetPackages = async (arnum, email, catnum, questionnaire) => {
        dispatch({ type: 'START_LOADING' });
        api.post(`booking/${arnum}/packages?email=${email}&catnum=${catnum}`, questionnaire)
            .then(res => {
                const response = res.data;
                if (response != null) {
                    if (response.meta.responseCode === 'S') {
                        const tempEst = response.data.filter(item => {
                            if (item.pType.toLowerCase().trim() === 'Estimated'.toLowerCase())
                                return item;
                        });
                        tempEst.length > 0 && SetEstimated(tempEst[0]);
                        dispatch({ type: 'SUCCESS' });
                    }
                    else {
                        globalContext.notifications('error', response.meta.message);
                        dispatch({ type: 'ERROR' });
                    }
                }
                else {
                    dispatch({ type: 'ERROR' });
                    globalContext.notifications('error', 'Oops ! Something went wrong.');
                }
            })
            .catch(error => {
                dispatch({ type: 'ERROR' });
                globalContext.notifications('error', 'Oops ! Something went wrong.');
                console.log(error);
            });
    };

    const onSubmit = () => {
        const questionnaire = getData('questionnaire');
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }
        else if (!props.location.state || !questionnaire) {
            globalContext.notifications('error', 'Sorry, You can not access this page directly, Please try again.');
            history.push("/");
        }
        else {
            arnum = session.arnum;
            email = session.email;
            catnum = props.location.state.matcatnum;
        }

        if (!state.reqtimefrom || !state.reqtimeto || !state.shipaddr || !state.shipemail || !state.shipname || !state.shipphone) {
            globalContext.notifications('error', 'Please fill all the details.');
            return false
        }
        dispatch({ type: 'START_LOADING' });
        api.post(`booking/${arnum}/assessment`, {
            arnum: arnum,
            catnum: catnum,
            email: email,
            parameters: questionnaire,
            reqdate: state.reqdate,
            reqtimefrom: state.reqtimefrom,
            reqtimeto: state.reqtimeto,
            shipaddr: state.shipaddr,
            shipemail: state.shipemail,
            shipname: state.shipname,
            shipphone: state.shipphone
        }).then(res => {
            const response = res.data;
            if (response != null) {
                if (response.meta.responseCode === 'S') {
                    dispatch({ type: 'SUCCESS' });
                    removeData('questionnaire')
                    globalContext.notifications('success', 'Assessment scheduled successfully.');
                    setTimeout(() => {
                        history.push('/');
                    }, 5000);
                }
                else {
                    dispatch({ type: 'ERROR' });
                    globalContext.notifications('error', response.meta.message);
                    // dispatch({ type: 'ERROR' });
                }
            }
            else {
                globalContext.notifications('error', 'Oops ! Something went wrong.');
                dispatch({ type: 'ERROR' });
            }
        }).catch(error => {
            dispatch({ type: 'ERROR' });
            globalContext.notifications('error', 'Oops ! Something went wrong.');
            console.log(error);
        });
    }

    return (
        <>
            <div id="scheduleAssessment" className="adjust-navbar">
                {state.isLoading ? <Loader /> :
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="11">
                                <Row className="pb-30">
                                    <Col md="8" className="ml-auto mr-auto ">
                                        <div className="text-center">
                                            <h2 className="big-heading">Schedule Assessment</h2>
                                        </div>
                                        <hr className="hr-large"></hr>
                                    </Col>
                                </Row>
                                <Row className="mb-30">
                                    <Col md="8" className="ml-auto mr-auto ">
                                        <div className="card">
                                            <div className="card-body">
                                                <Row>
                                                    <Col md="12" className="text-center">
                                                        <div className="title-2">
                                                            Estimated Package Price
                                                </div>
                                                        <p className="m-0 p-20">
                                                            {/* From
                                                                            <span className="dollor">£</span>
                                                                             <span className="price">23</span>
                                                                            &nbsp;Per Hour */}
                                                            {estimated.length !== 0 &&
                                                                <React.Fragment>
                                                                    {ReadRate(estimated.rate).beforerate}<br></br>
                                                                    <span className="dollor">£</span>
                                                                    <span className="price">{ReadRate(estimated.rate).rate}</span><br></br>
                                                                    {ReadRate(estimated.rate).afterrate}
                                                                </React.Fragment>
                                                            }
                                                        </p>
                                                        <hr className="hr-small"></hr>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12" className="text-left">
                                                        <Label className="title-3">Service Requested</Label>

                                                    </Col>
                                                    <Col md="12">
                                                        <FormGroup>
                                                            <select className="form-control">
                                                                {estimated.length !== 0 &&
                                                                    <option value="1">{estimated.description}</option>
                                                                }
                                                            </select>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <hr
                                                    style={{
                                                        marginLeft: "-20px",
                                                        marginRight: "-20px"
                                                    }}
                                                ></hr>
                                                <Row>
                                                    <Col md="12" className="text-left">
                                                        <Label className="title-3">Preferred Assessment Date/Time</Label><br />
                                                        <p className="description">When would you like us to come?</p>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            {/* <select className="form-control">
                                                            <option value="0">-Please Select Country-</option>
                                                            <option value="1">General/Weekly Cleaning</option>
                                                        </select> */}
                                                            <Datetime
                                                                timeFormat={false}
                                                                dateFormat="DD/MM/YYYY"
                                                                inputProps={{ placeholder: "DD/MM/YYYY" }}
                                                                closeOnSelect={true}
                                                                value={state.reqdate}
                                                                isValidDate={e => validDate(e, undefined, 1)}
                                                                onChange={e =>
                                                                    dispatch({
                                                                        type: 'FIELD',
                                                                        field: 'reqdate',
                                                                        value: e._d,
                                                                    })
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="8">
                                                        <FormGroup>
                                                            <InputGroup>
                                                                <Label className="col-md-5 pt-5">Any Time Between</Label>
                                                                <select className="form-control col-md-3 p-5"
                                                                    value={state.reqtimefrom}
                                                                    onChange={e =>
                                                                        dispatch({
                                                                            type: 'FIELD',
                                                                            field: 'reqtimefrom',
                                                                            value: e.currentTarget.value,
                                                                        })
                                                                    }>
                                                                    <option value="">HH:MM TT</option>
                                                                    <option value="00:00">12.00 AM</option>
                                                                    <option value="00:30">12.30 AM</option>
                                                                    <option value="01:00">01.00 AM</option>
                                                                    <option value="01:30">01.30 AM</option>
                                                                    <option value="02:00">02.00 AM</option>
                                                                    <option value="02:30">02.30 AM</option>
                                                                    <option value="03:00">03.00 AM</option>
                                                                    <option value="03:30">03.30 AM</option>
                                                                    <option value="04:00">04.00 AM</option>
                                                                    <option value="04:30">04.30 AM</option>
                                                                    <option value="05:00">05.00 AM</option>
                                                                    <option value="05:30">05.30 AM</option>
                                                                    <option value="06:00">06.00 AM</option>
                                                                    <option value="06:30">06.30 AM</option>
                                                                    <option value="07:00">07.00 AM</option>
                                                                    <option value="07:30">07.30 AM</option>
                                                                    <option value="08:00">08.00 AM</option>
                                                                    <option value="08:30">08.30 AM</option>
                                                                    <option value="09:00">09.00 AM</option>
                                                                    <option value="09:30">09.30 AM</option>
                                                                    <option value="10:00">10.00 AM</option>
                                                                    <option value="10:30">10.30 AM</option>
                                                                    <option value="11:00">11.00 AM</option>
                                                                    <option value="11:30">11.30 AM</option>
                                                                    <option value="12:00">12.00 PM</option>
                                                                    <option value="12:30">12.30 PM</option>
                                                                    <option value="13:00">01.00 PM</option>
                                                                    <option value="13:30">01.30 PM</option>
                                                                    <option value="14:00">02.00 PM</option>
                                                                    <option value="14:30">02.30 PM</option>
                                                                    <option value="15:00">03.00 PM</option>
                                                                    <option value="15:30">03.30 PM</option>
                                                                    <option value="16:00">04.00 PM</option>
                                                                    <option value="16:30">04.30 PM</option>
                                                                    <option value="17:00">05.00 PM</option>
                                                                    <option value="17:30">05.30 PM</option>
                                                                    <option value="18:00">06.00 PM</option>
                                                                    <option value="18:30">06.30 PM</option>
                                                                    <option value="19:00">07.00 PM</option>
                                                                    <option value="19:30">07.30 PM</option>
                                                                    <option value="20:00">08.00 PM</option>
                                                                    <option value="20:30">08.30 PM</option>
                                                                    <option value="21:00">09.00 PM</option>
                                                                    <option value="21:30">09.30 PM</option>
                                                                    <option value="22:00">10.00 PM</option>
                                                                    <option value="22:30">10.30 PM</option>
                                                                    <option value="23:00">11.00 PM</option>
                                                                    <option value="23:30">11.30 PM</option>
                                                                </select>
                                                                <span className="pt-5 pb-5"> - </span>
                                                                <select className="form-control col-md-3 p-5"

                                                                    value={state.reqtimeto}
                                                                    onChange={e =>
                                                                        dispatch({
                                                                            type: 'FIELD',
                                                                            field: 'reqtimeto',
                                                                            value: e.currentTarget.value,
                                                                        })
                                                                    }>
                                                                    <option value="">HH:MM TT</option>
                                                                    <option value="00:00">12.00 AM</option>
                                                                    <option value="00:30">12.30 AM</option>
                                                                    <option value="01:00">01.00 AM</option>
                                                                    <option value="01:30">01.30 AM</option>
                                                                    <option value="02:00">02.00 AM</option>
                                                                    <option value="02:30">02.30 AM</option>
                                                                    <option value="03:00">03.00 AM</option>
                                                                    <option value="03:30">03.30 AM</option>
                                                                    <option value="04:00">04.00 AM</option>
                                                                    <option value="04:30">04.30 AM</option>
                                                                    <option value="05:00">05.00 AM</option>
                                                                    <option value="05:30">05.30 AM</option>
                                                                    <option value="06:00">06.00 AM</option>
                                                                    <option value="06:30">06.30 AM</option>
                                                                    <option value="07:00">07.00 AM</option>
                                                                    <option value="07:30">07.30 AM</option>
                                                                    <option value="08:00">08.00 AM</option>
                                                                    <option value="08:30">08.30 AM</option>
                                                                    <option value="09:00">09.00 AM</option>
                                                                    <option value="09:30">09.30 AM</option>
                                                                    <option value="10:00">10.00 AM</option>
                                                                    <option value="10:30">10.30 AM</option>
                                                                    <option value="11:00">11.00 AM</option>
                                                                    <option value="11:30">11.30 AM</option>
                                                                    <option value="12:00">12.00 PM</option>
                                                                    <option value="12:30">12.30 PM</option>
                                                                    <option value="13:00">01.00 PM</option>
                                                                    <option value="13:30">01.30 PM</option>
                                                                    <option value="14:00">02.00 PM</option>
                                                                    <option value="14:30">02.30 PM</option>
                                                                    <option value="15:00">03.00 PM</option>
                                                                    <option value="15:30">03.30 PM</option>
                                                                    <option value="16:00">04.00 PM</option>
                                                                    <option value="16:30">04.30 PM</option>
                                                                    <option value="17:00">05.00 PM</option>
                                                                    <option value="17:30">05.30 PM</option>
                                                                    <option value="18:00">06.00 PM</option>
                                                                    <option value="18:30">06.30 PM</option>
                                                                    <option value="19:00">07.00 PM</option>
                                                                    <option value="19:30">07.30 PM</option>
                                                                    <option value="20:00">08.00 PM</option>
                                                                    <option value="20:30">08.30 PM</option>
                                                                    <option value="21:00">09.00 PM</option>
                                                                    <option value="21:30">09.30 PM</option>
                                                                    <option value="22:00">10.00 PM</option>
                                                                    <option value="22:30">10.30 PM</option>
                                                                    <option value="23:00">11.00 PM</option>
                                                                    <option value="23:30">11.30 PM</option>
                                                                </select>
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <hr
                                                    style={{
                                                        marginLeft: "-20px",
                                                        marginRight: "-20px"
                                                    }}
                                                ></hr>
                                                <Row>
                                                    <Col md="12" className="text-left">
                                                        <Label className="title-3">Contact Information</Label><br />
                                                        <p className="description">We use this information for communication about your service.</p>
                                                    </Col>
                                                    <Col md="12">
                                                        <Row>
                                                            <Col md="6">
                                                                <FormGroup>
                                                                    <Input type="text" className="form-control" placeholder="Your Name*"
                                                                        value={state.shipname}
                                                                        maxLength={30}
                                                                        onChange={e =>
                                                                            dispatch({
                                                                                type: 'FIELD',
                                                                                field: 'shipname',
                                                                                value: e.currentTarget.value,
                                                                            })
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="6">
                                                                <FormGroup>
                                                                    <Input type="text" className="form-control" placeholder="Your Address*"
                                                                        value={state.shipaddr}
                                                                        maxLength={50}
                                                                        onChange={e =>
                                                                            dispatch({
                                                                                type: 'FIELD',
                                                                                field: 'shipaddr',
                                                                                value: e.currentTarget.value,
                                                                            })
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md="6">
                                                                <FormGroup>
                                                                    <Input type="number" className="form-control" placeholder="Phone Number*"
                                                                        value={state.shipphone}
                                                                        maxLength={15}
                                                                        onChange={e =>
                                                                            dispatch({
                                                                                type: 'FIELD',
                                                                                field: 'shipphone',
                                                                                value: e.currentTarget.value,
                                                                            })
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="6">
                                                                <FormGroup>
                                                                    <Input type="email" className="form-control" placeholder="E-mail*"
                                                                        value={state.shipemail}
                                                                        maxLength={30}
                                                                        onChange={e =>
                                                                            dispatch({
                                                                                type: 'FIELD',
                                                                                field: 'shipemail',
                                                                                value: e.currentTarget.value,
                                                                            })
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <hr
                                                    style={{
                                                        marginLeft: "-20px",
                                                        marginRight: "-20px"
                                                    }}
                                                ></hr><br />
                                                <hr
                                                    style={{
                                                        marginLeft: "-20px",
                                                        marginRight: "-20px"
                                                    }}
                                                ></hr>
                                                <Row>
                                                    <Col md="12">
                                                        <FormGroup check>
                                                            <Label check className="description text-left">
                                                                <Input defaultChecked type="checkbox" ></Input>
                                                                <span className="form-check-sign"></span>
                                                            I consent to having this website store my submitted information so Whissh can respond to my enquiry. I have read the Terms and Conditions and Privacy Policy at the bottom of this page. In addition to receiving an email reply with its quotes, I agree to Whissh keeping me informed with personalised news, offers, products and promotions it believes would interest me by email.
                                                    </Label>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <hr
                                                    style={{
                                                        marginLeft: "-20px",
                                                        marginRight: "-20px"
                                                    }}
                                                ></hr>
                                                <Row>
                                                    <Col md="12" className="text-cenetr">
                                                        <FormGroup>
                                                            <p className="description">
                                                                We will confirm your service request within 24 hours. Thank you very much!
                                                         </p>
                                                            <Button className="btn btn-round btn-info" onClick={() => onSubmit()} >SCHEDULE NOW</Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                            </div>
                                        </div>
                                    </Col>
                                    <Col md="4" className="ml-auto mr-auto text-left d-none d-sm-block">
                                        <img
                                            alt="..."
                                            style={{
                                                maxHeight: "973px",
                                                objectFit: "cover"
                                            }}
                                            src={require("../assets/img/img-form.jpg")}
                                        ></img>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                }
            </div>
        </>
    );
}

