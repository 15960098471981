import React, { useContext, useEffect, forwardRef, useImperativeHandle } from 'react';
import { ServiceBookingContext } from '../../views/ServiceBooking';
import CreditCardInput from 'react-credit-card-input';

const CheckoutForm2c2p = forwardRef((props, ref) => {

    
    const sbContext = useContext(ServiceBookingContext);
    const handleCardNumberChange = (e)=>{
        sbContext.CheckoutDispatch({
            type: 'FIELD',
            field: 'twoc2pCardNumber',
            value: e.currentTarget.value,
        })
    }
    const handleCardExpiryChange = (e)=>{
        sbContext.CheckoutDispatch({
            type: 'FIELD',
            field: 'twoc2pExpiry',
            value: e.currentTarget.value,
        })
    }
    const handleCardCVCChange = (e)=>{
        sbContext.CheckoutDispatch({
            type: 'FIELD',
            field: 'twoc2pCvc',
            value: e.currentTarget.value,
        })
    }
    return (
        <>
            <CreditCardInput
                cardNumberInputProps={{ value: sbContext.CheckoutDispatch.twoc2pCardNumber,onChange: handleCardNumberChange }}
                cardExpiryInputProps={{ value: sbContext.CheckoutDispatch.twoc2pExpiry,onChange: handleCardExpiryChange  }}
                cardCVCInputProps={{ value: sbContext.CheckoutDispatch.twoc2pCvc,onChange: handleCardCVCChange }}
                fieldClassName="input"
            />

        </>
    )

})


export default CheckoutForm2c2p;