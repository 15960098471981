import React, { useContext, useEffect } from 'react';
import { Button, ModalBody, Form, Row, Col, Container } from 'reactstrap';
import { GlobalContext } from '../../App.js';
import { api } from "../../constant";
import { getData } from '../../utils/localStorage';

function ModalOtp(props) {
    const globalContext = useContext(GlobalContext);
    // const [otp, setOTP] = useState('000000');
    // const [error, setError] = useState('');

    useEffect(() => {
        console.log('ready to call API');
        const email = getData('email');
        console.log(email);
        if (email !== undefined && email !== '') {
            FnSentOTP(email);
        }
    });

    useEffect(() => {
        document.querySelector('.modal').scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    const FnSentOTP = (email) => {
        // console.log(e);
        api.post(`user/validate?email=${email}`)
            .then(res => {
                const response = res.data;
                if (response != null) {
                    if (response.meta.responseCode === 'S') {
                        //dispatch({ type: 'VALIDATE_USER_SUCCESS', otp: response.meta.message });
                        globalContext.notifications('success', 'Please check your email.');
                    }
                    else {
                        //dispatch({ type: 'ERROR' });
                        globalContext.notifications('error', response.meta.message);
                    }
                }
                else {
                    //dispatch({ type: 'ERROR' });
                    globalContext.notifications('error', 'Oops ! Something went wrong.');
                }
            })
            .catch(error => {
                //dispatch({ type: 'ERROR' });
                globalContext.notifications('error', 'Oops ! Something went wrong.');
                console.log(error);
            });
    };

    return (
        <>
            <div className="otp-modal">
                <ModalBody>
                    <Container>
                        <Row>
                            <Col md="7" className="ml-auto mr-auto ">
                                <Form>
                                    <div className="form-group text-center">
                                        <img
                                            alt="..."
                                            src={require("../../assets/img/icon-login.png")}
                                            style={{ height: "50px" }}
                                        >
                                        </img>
                                    </div>
                                    <div className="form-group text-center">
                                        <b className="title-3">
                                            SMS OTP
                                          </b>
                                    </div>
                                    <hr></hr>
                                    <div className="form-group">
                                        <p className="text-center description">
                                            Enter the 6 digit One Time Password (OTP) sent to your mobile number(****4525)
                                            </p>
                                    </div>
                                    <div className="form-group">
                                        <input type="otp" className="form-control text-center" placeholder="OTP" />
                                    </div>
                                    <div className="form-group text-center">
                                        <Button type="button" className="btn-round btn btn-info" onClick={() => globalContext.headerDispatch({ type: 'CHOOSELOCATION_OLDUSER' })}>
                                            SUBMIT</Button>
                                        <Button type="button" className="btn-round btn btn-default">
                                            RESEND</Button>
                                    </div>
                                </Form>

                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
            </div>
        </>
    );

}

export default ModalOtp;