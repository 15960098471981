import React from "react";
import {
    Row,
    Col,
    Button,
    FormGroup,
    Input,
} from "reactstrap";
// core components

export default function MPMyCreditCards() {
    return (
        <>
            <div className="card">
                <div className="card-header text-left title-2">
                    My Credit Cards
                 </div>
                <div className="card-body">
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Input type="text" className="form-control" placeholder="First Name*" />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Input type="text" className="form-control" placeholder="Last Name*" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Input type="text" className="form-control" placeholder="Phone Number*" />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Input type="text" className="form-control" placeholder="E-mail*" />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                <div className="card-footer">
                    <Row>
                        <Col md="12" className="text-cenetr">
                            <FormGroup>
                                <Button className="btn btn-round btn-info">UPDATE DETAILS</Button>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}
